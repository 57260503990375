// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../ui/src/group/components/Ui__Group.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as App__Report from "../utils/App__Report.bs.js";
import * as AuthContext from "../auth/context/AuthContext.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as ReactNative from "react-native";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as Ui__ErrorState from "../../../ui/src/errorState/components/Ui__ErrorState.bs.js";
import * as Ui__MarkotText from "../../../ui/src/text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../../ui/src/responsive/Ui__Responsive.bs.js";
import * as Ui__PressableText from "../../../ui/src/pressableText/components/Ui__PressableText.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as Ui__ProfilePageMenu from "../../../ui/src/profilePageMenu/components/Ui__ProfilePageMenu.bs.js";
import * as Ui__LoadingIndicator from "../../../ui/src/loading/components/Ui__LoadingIndicator.bs.js";
import * as App__FeatureFlagsPage from "../featureFlagsPage/App__FeatureFlagsPage.bs.js";
import * as Ignored$DecidrIoUtils from "@decidr-io/utils/src/functions/Ignored.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as Ui__MarkotErrorBoundary from "../../../ui/src/error/markotErrorBoundary/Ui__MarkotErrorBoundary.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as Ui__TestModePageIndicator from "../../../ui/src/testMode/pageIndicator/Ui__TestModePageIndicator.bs.js";
import * as AppProfilePageQuery_graphql from "../../../relay-codegen/generated/AppProfilePageQuery_graphql.bs.js";
import * as Auth$DecidrIoRescriptAwsAmplify from "@decidr-io/rescript-aws-amplify/src/Auth.bs.js";
import * as FeatureFlags__LocalFeatureFlags from "../../../feature-flags/src/local/FeatureFlags__LocalFeatureFlags.bs.js";
import * as AppProfilePage_markotUser_graphql from "../../../relay-codegen/generated/AppProfilePage_markotUser_graphql.bs.js";
import * as GroupFactory$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/group/components/GroupFactory.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppProfilePage_markotUser_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppProfilePage_markotUser_graphql.Internal.convertFragment, data);
}

var match = Ui__Styling.describe({
      row: {
        TAG: /* Static */0,
        _0: {
          flexDirection: "row",
          justifyContent: "space-between"
        }
      },
      wallet: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "700",
          textDecorationLine: "underline",
          textTransform: "uppercase"
        }
      },
      text: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "500"
        }
      },
      mainContainer: {
        TAG: /* Dynamic */1,
        _0: (function (props) {
            return {
                    backgroundColor: props.theme.colors.background,
                    borderRadius: props.theme.roundness._7,
                    padding: props.padding
                  };
          })
      },
      walletContainer: {
        TAG: /* Static */0,
        _0: {
          flexDirection: "row",
          justifyContent: "center"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function App__ProfilePage$Balance(Props) {
  var userData = Props.userData;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = use(userData);
  var wallet = match$1.wallet;
  var match$2 = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        {
          mainSpacing: theme.spacing._10,
          spacing: theme.spacing._4,
          padding: theme.spacing._8
        },
        undefined,
        undefined,
        {
          mainSpacing: theme.spacing._12,
          spacing: theme.spacing._6,
          padding: theme.spacing._10
        },
        undefined,
        {
          mainSpacing: theme.spacing._14,
          spacing: theme.spacing._8,
          padding: theme.spacing._10
        },
        undefined,
        {
          mainSpacing: theme.spacing._10,
          spacing: theme.spacing._4,
          padding: theme.spacing._8
        },
        undefined
      ]);
  var styled = Curry._1(stylesheet, {
        padding: match$2.padding,
        theme: theme
      });
  var tmp;
  if (wallet !== undefined) {
    var unsupportedCurrency = wallet.currency;
    if (unsupportedCurrency === "AUD") {
      tmp = React.createElement(Ui__MarkotText.make, {
            size: "b1",
            children: "$" + wallet.availableToWithdraw + "",
            style: Curry._1(styled, styles.text)
          });
    } else {
      App__Report.unknownEnumMember(unsupportedCurrency);
      tmp = React.createElement(Ui__MarkotText.make, {
            color: theme.colors.error,
            children: "Error!"
          });
    }
  } else {
    tmp = React.createElement(Ui__MarkotText.make, {
          size: "b1",
          children: "-",
          style: Curry._1(styled, styles.text)
        });
  }
  var tmp$1;
  if (wallet !== undefined) {
    var unsupportedCurrency$1 = wallet.currency;
    if (unsupportedCurrency$1 === "AUD") {
      tmp$1 = React.createElement(Ui__MarkotText.make, {
            size: "b1",
            children: "$" + wallet.lifetimeEarnings + "",
            style: Curry._1(styled, styles.text)
          });
    } else {
      App__Report.unknownEnumMember(unsupportedCurrency$1);
      tmp$1 = React.createElement(Ui__MarkotText.make, {
            color: theme.colors.error,
            children: "Error!"
          });
    }
  } else {
    tmp$1 = React.createElement(Ui__MarkotText.make, {
          size: "b1",
          children: "-",
          style: Curry._1(styled, styles.text)
        });
  }
  return React.createElement(Ui__Group.make, {
              spacing: match$2.mainSpacing,
              children: null,
              containerStyle: Curry._1(styled, styles.mainContainer)
            }, React.createElement(Ui__Group.make, {
                  spacing: match$2.spacing,
                  children: null
                }, React.createElement(ReactNative.View, {
                      style: Curry._1(styled, styles.row),
                      children: null
                    }, React.createElement(Ui__MarkotText.make, {
                          size: "b1",
                          children: "Available to withdraw:"
                        }), tmp), React.createElement(ReactNative.View, {
                      style: Curry._1(styled, styles.row),
                      children: null
                    }, React.createElement(Ui__MarkotText.make, {
                          size: "b2",
                          color: theme.colors.onPrimaryShade4,
                          children: "Lifetime earnings:"
                        }), React.createElement(Ui__MarkotText.make, {
                          size: "b2",
                          children: tmp$1,
                          style: Curry._1(styled, styles.text)
                        }))), React.createElement(ReactNative.View, {
                  style: Curry._1(styled, styles.walletContainer),
                  children: React.createElement(Ui__PressableText.make, {
                        title: "Wallet",
                        behavior: {
                          NAME: "Link",
                          VAL: {
                            route: /* Wallet */19,
                            a11yLabel: "Go see your wallet"
                          }
                        },
                        color: theme.colors.onSurface,
                        titleStyle: [
                          Curry._1(styled, styles.text),
                          Curry._1(styled, styles.wallet)
                        ],
                        textSize: "button1"
                      })
                }));
}

function use$1(variables, fetchPolicy, fetchKey, networkCacheConfig, param) {
  var data = ReactRelay.useLazyLoadQuery(AppProfilePageQuery_graphql.node, RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(AppProfilePageQuery_graphql.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return RescriptRelay_Internal.internal_useConvertedValue(AppProfilePageQuery_graphql.Internal.convertResponse, data);
}

var match$1 = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          flexDirection: "row",
          flexGrow: 1,
          justifyContent: "center"
        }
      },
      wrapper: {
        TAG: /* Dual */2,
        _0: {
          flex: 1
        },
        _1: (function (maxWidth) {
            var tmp = {};
            var tmp$1 = Belt_Option.map(maxWidth, (function (prim) {
                    return prim;
                  }));
            if (tmp$1 !== undefined) {
              tmp.maxWidth = Caml_option.valFromOption(tmp$1);
            }
            return tmp;
          })
      }
    });

var stylesheet$1 = match$1[1];

var styles$1 = match$1[0];

function App__ProfilePage$Wrapper(Props) {
  var maxWidth = Props.maxWidth;
  var spacing = Props.spacing;
  var children = Props.children;
  var styled = Curry._1(stylesheet$1, maxWidth);
  var tmp = {
    children: children,
    containerStyle: Curry._1(styled, styles$1.wrapper)
  };
  if (spacing !== undefined) {
    tmp.spacing = spacing;
  }
  return React.createElement(ReactNative.View, {
              style: Curry._1(styled, styles$1.container),
              children: React.createElement(Ui__Group.make, tmp)
            });
}

var match$2 = Ui__Styling.describe({
      logOut: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "700"
        }
      },
      scrollView: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      scrollViewContent: {
        TAG: /* Dual */2,
        _0: {
          justifyContent: "space-between"
        },
        _1: (function (props) {
            return {
                    paddingBottom: props.theme.sectionSpacing._2
                  };
          })
      },
      container: {
        TAG: /* Dual */2,
        _0: {
          flex: 1,
          maxWidth: 632
        },
        _1: (function (props) {
            return {
                    paddingHorizontal: props.padding
                  };
          })
      },
      footerContainer: {
        TAG: /* Dual */2,
        _0: {
          maxWidth: 632
        },
        _1: (function (props) {
            return {
                    paddingHorizontal: props.padding
                  };
          })
      },
      userInfoContainer: {
        TAG: /* Dual */2,
        _0: {
          flexDirection: "row",
          justifyContent: "space-between"
        },
        _1: (function (props) {
            return {
                    paddingHorizontal: props.theme.spacing._8
                  };
          })
      },
      copyright: {
        TAG: /* Static */0,
        _0: {
          alignSelf: "center"
        }
      }
    });

var stylesheet$2 = match$2[1];

var styles$2 = match$2[0];

var authorizedMenuItems = [
  {
    title: "My bids",
    routeConfiguration: {
      route: /* Bids */8,
      a11yLabel: "Go to your bids"
    },
    hasCaretIcon: true
  },
  {
    title: "Account",
    routeConfiguration: {
      route: /* AccountInfo */2,
      a11yLabel: "Navigates to Account info page."
    },
    hasCaretIcon: true
  },
  {
    title: "Addresses and payment settings",
    routeConfiguration: {
      route: /* PaymentAndAddressSettings */22,
      a11yLabel: "You will go to address and payment settings page"
    },
    hasCaretIcon: true
  },
  {
    title: "Orders",
    routeConfiguration: {
      route: {
        TAG: /* Orders */17,
        checkout: undefined
      },
      a11yLabel: "You will be taken to the orders page"
    },
    hasCaretIcon: true
  },
  {
    title: "Notification preferences",
    routeConfiguration: {
      route: /* NotificationPreferences */23,
      a11yLabel: "You will go to notification preferences page"
    },
    hasCaretIcon: true
  }
];

var menuItems = [
  {
    title: "Blog",
    routeConfiguration: {
      route: {
        TAG: /* ExternalRoute */12,
        url: "https://blog.markot.com",
        target: undefined
      },
      a11yLabel: "Go check out blog"
    },
    hasCaretIcon: false
  },
  {
    title: "Guides",
    routeConfiguration: {
      route: /* Guides */26,
      a11yLabel: "Check out Markot guides"
    },
    hasCaretIcon: true
  },
  {
    title: "Help",
    routeConfiguration: {
      route: /* Help */27,
      a11yLabel: "Check out the pages which can help you out"
    },
    hasCaretIcon: true
  },
  {
    title: "Terms",
    routeConfiguration: {
      route: /* Terms */28,
      a11yLabel: "Check out our terms"
    },
    hasCaretIcon: true
  },
  {
    title: "Contact us",
    routeConfiguration: {
      route: /* Contact */24,
      a11yLabel: "Navigates to the contact page"
    },
    hasCaretIcon: false
  }
];

function App__ProfilePage(Props) {
  var onLogoutOpt = Props.onLogout;
  var onLogout = onLogoutOpt !== undefined ? onLogoutOpt : Auth$DecidrIoRescriptAwsAmplify.signOut;
  var match = Curry._1(AuthContext.useAuth, undefined);
  var cleanAfterLogout = match.cleanAfterLogout;
  var authInfo = match.authInfo;
  var isAuthenticated = Belt_Option.mapWithDefault(authInfo, false, (function (param) {
          return param.status === "authenticated";
        }));
  console.log("authInfo", authInfo);
  if (isAuthenticated) {
    Ignored$DecidrIoUtils.promise(Curry._1(onLogout, undefined).then(function (param) {
              Curry._1(cleanAfterLogout, undefined);
            }));
  }
  var match$1 = use$1({
        isAuthenticated: Belt_Option.mapWithDefault(authInfo, false, (function (param) {
                return param.status === "authenticated";
              }))
      }, undefined, undefined, undefined, undefined);
  var viewer = match$1.viewer;
  var match$2 = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match$2.theme;
  var match$3 = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        {
          spacing: theme.spacing._8,
          padding: theme.spacing._16,
          isWideScreen: false,
          maxWidth: 336
        },
        {
          spacing: theme.spacing._8,
          padding: theme.spacing._16,
          isWideScreen: false,
          maxWidth: 400
        },
        undefined,
        {
          spacing: theme.spacing._10,
          padding: theme.spacing._20,
          isWideScreen: true,
          maxWidth: undefined
        },
        undefined,
        {
          spacing: theme.spacing._6,
          padding: theme.spacing._10,
          isWideScreen: false,
          maxWidth: undefined
        },
        undefined
      ]);
  var maxWidth = match$3.maxWidth;
  var styled = Curry._1(stylesheet$2, {
        theme: theme,
        padding: match$3.padding
      });
  var placeholderFeature = FeatureFlags__LocalFeatureFlags.use("placeholder");
  var isTestUser = Belt_Option.mapWithDefault(viewer, false, (function (param) {
          return param.isTestUser;
        }));
  var notificationScreen = FeatureFlags__LocalFeatureFlags.use("notificationScreen");
  var tmp = {
    spacing: match$3.spacing,
    children: null
  };
  if (maxWidth !== undefined) {
    tmp.maxWidth = Caml_option.valFromOption(maxWidth);
  }
  var tmp$1;
  if (authInfo !== undefined) {
    var match$4 = authInfo.status;
    tmp$1 = match$4 === "notAuthenticated" ? null : (
        match$4 === "pending" ? React.createElement(Ui__LoadingIndicator.make, {
                size: "small"
              }) : React.createElement(ReactNative.View, {
                style: Curry._1(styled, styles$2.userInfoContainer),
                testID: "user-info-container",
                children: null
              }, viewer !== undefined ? SimpleReact$DecidrIoUtils.renderIfSome(viewer.firstName, (function (name) {
                        return React.createElement(Ui__MarkotText.make, {
                                    size: "b1",
                                    color: theme.colors.primary,
                                    children: "Hello, " + name + "!"
                                  });
                      })) : null, React.createElement(Ui__PressableText.make, {
                    title: "Log out",
                    behavior: {
                      NAME: "Button",
                      VAL: (function (param) {
                          Ignored$DecidrIoUtils.promise(Curry._1(onLogout, undefined).then(function (param) {
                                    Curry._1(cleanAfterLogout, undefined);
                                  }));
                        })
                    },
                    color: theme.colors.info,
                    titleStyle: Curry._1(styled, styles$2.logOut),
                    testID: "log-out",
                    textSize: "b1"
                  }))
      );
  } else {
    tmp$1 = null;
  }
  var tmp$2;
  var exit = 0;
  if (viewer !== undefined) {
    if (authInfo !== undefined && authInfo.status === "authenticated") {
      tmp$2 = React.createElement(Ui__ProfilePageMenu.make, {
            items: Belt_Array.concat(notificationScreen === "off" ? Belt_Array.keep(authorizedMenuItems, (function (menuItem) {
                          return menuItem.title !== "Notification preferences";
                        })) : authorizedMenuItems, menuItems)
          });
    } else {
      exit = 1;
    }
  } else if (authInfo !== undefined && authInfo.status === "authenticated") {
    tmp$2 = React.createElement(Ui__ErrorState.make, {
          children: null
        }, React.createElement(Ui__ErrorState.Title.make, {
              text: "An error occurred",
              color: theme.colors.error
            }), React.createElement(Ui__ErrorState.Description.make, {
              text: "Could not get your data."
            }));
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp$2 = React.createElement(Ui__ProfilePageMenu.make, {
          items: menuItems
        });
  }
  var tmp$3 = {};
  var tmp$4 = Bool$DecidrIoUtils.thenSome(placeholderFeature === "on", theme.colors.primaryShade4);
  if (tmp$4 !== undefined) {
    tmp$3.openerBackgroundColor = Caml_option.valFromOption(tmp$4);
  }
  var tmp$5 = {
    children: React.createElement(Ui__MarkotText.make, {
          size: "b1",
          color: theme.colors.onPrimaryShade4,
          children: "© Markot " + new Date().getFullYear().toString() + "",
          style: Curry._1(styled, styles$2.copyright),
          testID: "profile-page-footer-copyright"
        })
  };
  if (maxWidth !== undefined) {
    tmp$5.maxWidth = Caml_option.valFromOption(maxWidth);
  }
  return React.createElement(ReactNative.ScrollView, {
              contentContainerStyle: [
                Caml_option.some(Curry._1(styled, styles$2.scrollViewContent)),
                Bool$DecidrIoUtils.thenSome(isTestUser, {
                      backgroundColor: Ui__TestModePageIndicator.testModeBackgroundColor
                    })
              ],
              style: Curry._1(styled, styles$2.scrollView),
              testID: "profile-page-scroll-view",
              children: React.createElement(App__ProfilePage$Wrapper, {
                    maxWidth: 632,
                    children: null
                  }, React.createElement(Ui__Group.make, {
                        spacing: theme.sectionSpacing._2,
                        children: null,
                        containerStyle: Curry._1(styled, styles$2.container),
                        testID: "profile-page-container",
                        includeStartSpacing: true
                      }, React.createElement(App__ProfilePage$Wrapper, tmp, SimpleReact$DecidrIoUtils.whenTrue(isTestUser, React.createElement(Ui__TestModePageIndicator.make, {
                                    title: "test user"
                                  })), tmp$1, viewer !== undefined ? (
                              authInfo !== undefined && authInfo.status === "authenticated" ? React.createElement(Ui__MarkotErrorBoundary.make, {
                                      debugContext: "App__ProfilePage Wallet",
                                      children: React.createElement(React.Suspense, {
                                            children: React.createElement(App__ProfilePage$Balance, {
                                                  userData: viewer.fragmentRefs
                                                }),
                                            fallback: React.createElement(Ui__LoadingIndicator.make, {})
                                          })
                                    }) : null
                            ) : (
                              authInfo !== undefined && authInfo.status === "authenticated" ? React.createElement(Ui__ErrorState.make, {
                                      children: null
                                    }, React.createElement(Ui__ErrorState.Title.make, {
                                          text: "An error occurred",
                                          color: theme.colors.error
                                        }), React.createElement(Ui__ErrorState.Description.make, {
                                          text: "Could not get your data."
                                        })) : null
                            )), tmp$2), React.createElement(ReactNative.View, {
                        style: Curry._1(styled, styles$2.footerContainer),
                        children: null
                      }, React.createElement(App__FeatureFlagsPage.make, tmp$3), React.createElement(GroupFactory$DecidrIoUniversalComponents.Spacer.make, {
                            size: theme.sectionSpacing._2
                          }), React.createElement(App__ProfilePage$Wrapper, tmp$5), React.createElement(GroupFactory$DecidrIoUniversalComponents.Spacer.make, {
                            size: theme.sectionSpacing._2
                          })))
            });
}

var make = App__ProfilePage;

export {
  make ,
}
/* match Not a pure module */
