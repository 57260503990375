export const DisableScrollYStyle = () => (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  <style jsx="true" global="true">{`
    #__next {
      overflow-y: hidden;
      overflow-x: hidden;
    }
  `}</style>
);
