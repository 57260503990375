// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../group/components/Ui__Group.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "../routing/Ui__AppLink.bs.js";
import * as Ui__Styling from "../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotText from "../text/components/Ui__MarkotText.bs.js";
import * as Ui__OpenBoxTag from "../openboxTag/Ui__OpenBoxTag.bs.js";
import * as Ui__Responsive from "../responsive/Ui__Responsive.bs.js";
import * as Ui__MarkotImage from "../image/components/Ui__MarkotImage.bs.js";
import * as Ui__ImageFallback from "../image/components/imageFallback/Ui__ImageFallback.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as Ui__OverstockedTag from "../overstockedTag/Ui__OverstockedTag.bs.js";
import * as Ui__RefurbishedTag from "../refurbishedTag/Ui__RefurbishedTag.bs.js";
import * as Ui__BrandNameFallback from "../brandNameFallback/Ui__BrandNameFallback.bs.js";
import * as Ui__ProductCard__Utils from "./Ui__ProductCard__Utils.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as Box$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/box/components/Box.bs.js";
import * as Tag$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/tag/components/Tag.bs.js";

function Ui__ProductCard__Base$BrandName(Props) {
  var brandName = Props.brandName;
  var size = Props.size;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  return React.createElement(Ui__MarkotText.make, {
              size: size,
              color: match.theme.colors.primaryShade1,
              children: Belt_Option.getWithDefault(brandName, Ui__BrandNameFallback.brandNameFallback),
              ellipsizeMode: "tail",
              numberOfLines: 2,
              testID: "brand-name"
            });
}

var match = Ui__Styling.describe({
      name: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "500"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__ProductCard__Base$Name(Props) {
  var name = Props.name;
  var size = Props.size;
  var headingLevel = Props.headingLevel;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, undefined);
  var tmp = {
    size: size,
    color: match.theme.colors.primary,
    children: name,
    style: Curry._1(styled, styles.name),
    ellipsizeMode: "tail",
    numberOfLines: 3,
    testID: "product-name"
  };
  var tmp$1 = Belt_Option.map(headingLevel, (function (param) {
          return "header";
        }));
  if (tmp$1 !== undefined) {
    tmp.accessibilityRole = Caml_option.valFromOption(tmp$1);
  }
  if (headingLevel !== undefined) {
    tmp.accessibilityLevel = Caml_option.valFromOption(headingLevel);
  }
  return React.createElement(Ui__MarkotText.make, tmp);
}

var match$1 = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          alignItems: "baseline"
        }
      },
      text: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "bold"
        }
      }
    });

var stylesheet$1 = match$1[1];

var styles$1 = match$1[0];

function Ui__ProductCard__Base$LabeledText(Props) {
  var children = Props.children;
  var label = Props.label;
  var mainColor = Props.mainColor;
  var secondaryTextSize = Props.secondaryTextSize;
  var mainTextSize = Props.mainTextSize;
  var testID = Props.testID;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$1, undefined);
  var tmp = {
    size: mainTextSize,
    children: children,
    style: Curry._1(styled, styles$1.text)
  };
  if (mainColor !== undefined) {
    tmp.color = Caml_option.valFromOption(mainColor);
  }
  if (testID !== undefined) {
    tmp.testID = Caml_option.valFromOption(testID);
  }
  return React.createElement(Ui__Group.make, {
              spacing: theme.spacing._2,
              children: null,
              containerStyle: Curry._1(styled, styles$1.container),
              orientation: "horizontal"
            }, SimpleReact$DecidrIoUtils.renderIfSome(label, (function (label) {
                    return React.createElement(Ui__MarkotText.make, {
                                size: secondaryTextSize,
                                color: theme.colors.primaryShade1,
                                children: label
                              });
                  })), React.createElement(Ui__MarkotText.make, tmp));
}

var match$2 = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          alignItems: "baseline"
        }
      },
      compareAtPrice: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "500",
          textDecorationLine: "line-through"
        }
      }
    });

var stylesheet$2 = match$2[1];

var styles$2 = match$2[0];

function Ui__ProductCard__Base$NewPrice(Props) {
  var priceElement = Props.priceElement;
  var priceSize = Props.priceSize;
  var compareAtPriceElement = Props.compareAtPriceElement;
  var secondaryTextSize = Props.secondaryTextSize;
  var isPriceRangeOpt = Props.isPriceRange;
  var isPriceRange = isPriceRangeOpt !== undefined ? isPriceRangeOpt : false;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$2, undefined);
  var orientation = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        "horizontal",
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        "vertical",
        undefined
      ]);
  var tmp = {
    children: priceElement,
    mainColor: Belt_Option.isSome(compareAtPriceElement) ? theme.colors.success : theme.colors.primary,
    secondaryTextSize: secondaryTextSize,
    mainTextSize: priceSize,
    testID: "price-amount"
  };
  var tmp$1 = Bool$DecidrIoUtils.thenSome(isPriceRange, "From");
  if (tmp$1 !== undefined) {
    tmp.label = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Ui__Group.make, {
              spacing: theme.spacing._2,
              children: null,
              containerStyle: Curry._1(styled, styles$2.container),
              orientation: orientation
            }, React.createElement(Ui__ProductCard__Base$LabeledText, tmp), SimpleReact$DecidrIoUtils.renderIfSome(compareAtPriceElement, (function (compareAtPriceElement) {
                    return React.createElement(Ui__MarkotText.make, {
                                size: secondaryTextSize,
                                color: theme.colors.primary,
                                children: compareAtPriceElement,
                                style: Curry._1(styled, styles$2.compareAtPrice)
                              });
                  })));
}

function Ui__ProductCard__Base$NewAndPrelovedPrice(Props) {
  var priceElement = Props.priceElement;
  var compareAtPriceElement = Props.compareAtPriceElement;
  var prelovedFromPriceElement = Props.prelovedFromPriceElement;
  var priceSize = Props.priceSize;
  var secondaryTextSize = Props.secondaryTextSize;
  var isPriceRangeOpt = Props.isPriceRange;
  var isPriceRange = isPriceRangeOpt !== undefined ? isPriceRangeOpt : false;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var tmp = {
    priceElement: priceElement,
    priceSize: priceSize,
    secondaryTextSize: secondaryTextSize,
    isPriceRange: isPriceRange
  };
  if (compareAtPriceElement !== undefined) {
    tmp.compareAtPriceElement = Caml_option.valFromOption(compareAtPriceElement);
  }
  return React.createElement(Ui__Group.make, {
              spacing: theme.spacing._2,
              children: null
            }, React.createElement(Ui__ProductCard__Base$NewPrice, tmp), SimpleReact$DecidrIoUtils.renderIfSome(prelovedFromPriceElement, (function (prelovedFromPriceElement) {
                    return React.createElement(Ui__ProductCard__Base$LabeledText, {
                                children: prelovedFromPriceElement,
                                label: "Preloved from",
                                mainColor: isPriceRange ? theme.colors.success : theme.colors.info,
                                secondaryTextSize: secondaryTextSize,
                                mainTextSize: priceSize
                              });
                  })));
}

Ui__Styling.describe({
      mediumFontWeight: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "500"
        }
      }
    });

var match$3 = Ui__Styling.describe({
      mediumFontWeight: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "500"
        }
      }
    });

var stylesheet$3 = match$3[1];

var styles$3 = match$3[0];

function Ui__ProductCard__Base$RefurbishedOrOverstockedPrice(Props) {
  var priceElement = Props.priceElement;
  var savings = Props.savings;
  var secondaryTextSize = Props.secondaryTextSize;
  var priceSize = Props.priceSize;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$3, undefined);
  return React.createElement(Ui__Group.make, {
              spacing: theme.spacing._2,
              children: null
            }, React.createElement(Ui__ProductCard__Base$LabeledText, {
                  children: priceElement,
                  mainColor: theme.colors.primary,
                  secondaryTextSize: secondaryTextSize,
                  mainTextSize: priceSize
                }), SimpleReact$DecidrIoUtils.renderIfSome(savings, (function (savings) {
                    return React.createElement(Ui__MarkotText.make, {
                                size: secondaryTextSize,
                                color: theme.colors.success,
                                children: "Save " + savings,
                                style: Curry._1(styled, styles$3.mediumFontWeight)
                              });
                  })));
}

function Ui__ProductCard__Base$ProductTag(Props) {
  var status = Props.status;
  var size = Props.size;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var containerStyle = {
    alignSelf: "flex-start",
    justifyContent: "center",
    paddingHorizontal: theme.spacing._2,
    paddingVertical: theme.spacing._1
  };
  var textStyle;
  var exit = 0;
  if (typeof size === "object" && size.NAME === "unsafe") {
    var font = size.VAL;
    textStyle = {
      fontSize: font.size,
      fontWeight: (function () {
            switch (status === "sale" ? "bold" : "normal") {
              case "normal" :
                  return "normal";
              case "bold" :
                  return "bold";
              case "_100" :
                  return "100";
              case "_200" :
                  return "200";
              case "_300" :
                  return "300";
              case "_400" :
                  return "400";
              case "_500" :
                  return "500";
              case "_600" :
                  return "600";
              case "_700" :
                  return "700";
              case "_800" :
                  return "800";
              case "_900" :
                  return "900";
              
            }
          })(),
      letterSpacing: font.size * font.letterSpacingPct,
      lineHeight: font.lineHeight
    };
  } else {
    exit = 1;
  }
  if (exit === 1) {
    textStyle = {};
  }
  var match$1 = status === "prelovedAvailable" ? [
      theme.colors.primaryShade4,
      theme.colors.onPrimaryShade4,
      "Preloved available"
    ] : (
      status === "prelovedOnly" ? [
          theme.colors.secondaryVariant3,
          theme.colors.primary,
          "Preloved only"
        ] : [
          theme.colors.successShade4,
          theme.colors.success,
          "Sale"
        ]
    );
  return React.createElement(Tag$DecidrIoUniversalComponents.make, {
              text: match$1[2],
              backgroundColor: match$1[0],
              textColor: match$1[1],
              textStyle: textStyle,
              containerStyle: containerStyle,
              testID: "product-card-tag"
            });
}

function Ui__ProductCard__Base$PrelovedAvailableTag(Props) {
  var size = Props.size;
  return React.createElement(Ui__ProductCard__Base$ProductTag, {
              status: "prelovedAvailable",
              size: size
            });
}

function Ui__ProductCard__Base$PrelovedOnlyTag(Props) {
  var size = Props.size;
  return React.createElement(Ui__ProductCard__Base$ProductTag, {
              status: "prelovedOnly",
              size: size
            });
}

var match$4 = Ui__Styling.describe({
      placeholder: {
        TAG: /* Static */0,
        _0: {
          height: 16
        }
      }
    });

var stylesheet$4 = match$4[1];

var styles$4 = match$4[0];

function Ui__ProductCard__Base$TagPlaceholder(Props) {
  var styled = Curry._1(stylesheet$4, undefined);
  return React.createElement(ReactNative.View, {
              style: Curry._1(styled, styles$4.placeholder)
            });
}

function Ui__ProductCard__Base$SaleTag(Props) {
  var size = Props.size;
  return React.createElement(Ui__ProductCard__Base$ProductTag, {
              status: "sale",
              size: size
            });
}

function Ui__ProductCard__Base$TagWithDefault(Props) {
  var tag = Props.tag;
  if (tag !== undefined) {
    return Caml_option.valFromOption(tag);
  } else {
    return React.createElement(Ui__ProductCard__Base$TagPlaceholder, {});
  }
}

var match$5 = Ui__Styling.describe({
      image: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.surface
                  };
          })
      }
    });

var stylesheet$5 = match$5[1];

var styles$5 = match$5[0];

function Ui__ProductCard__Base$Image(Props) {
  var image = Props.image;
  var defaultAlt = Props.defaultAlt;
  var imageHeight = Props.imageHeight;
  var imageWidth = Props.imageWidth;
  var resizeModeOpt = Props.resizeMode;
  var resizeMode = resizeModeOpt !== undefined ? resizeModeOpt : "contain";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet$5, match.theme);
  if (image !== undefined) {
    return React.createElement(Ui__MarkotImage.make, {
                image: {
                  src: image.url,
                  width: image.width,
                  height: image.height
                },
                alt: Belt_Option.getWithDefault(image.alt, defaultAlt),
                size: {
                  NAME: "fixedHeightAutoWidth",
                  VAL: {
                    height: imageHeight,
                    sizes: "" + String(imageWidth) + "px"
                  }
                },
                resizeMode: resizeMode,
                style: Curry._1(styled, styles$5.image)
              });
  } else {
    return React.createElement(Ui__ImageFallback.make, {
                height: imageHeight
              });
  }
}

function Ui__ProductCard__Base$Container(Props) {
  var children = Props.children;
  var width = Props.width;
  var usedForTesting = Props.usedForTesting;
  var onPress = Props.onPress;
  var testID = Props.testID;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var tmp = {
    spacing: match.theme.spacing._4,
    children: children,
    containerStyle: {
      width: width
    },
    accessibilityRole: "article",
    testMode: usedForTesting
  };
  var tmp$1 = Belt_Option.map(onPress, (function (onPress) {
          return {
                  NAME: "Pressable",
                  VAL: Box$DecidrIoUniversalComponents.makePressableProps(undefined, undefined, undefined, (function (param) {
                          Curry._1(onPress, undefined);
                        }), undefined, undefined, undefined)
                };
        }));
  if (tmp$1 !== undefined) {
    tmp.kind = Caml_option.valFromOption(tmp$1);
  }
  if (testID !== undefined) {
    tmp.testID = testID;
  }
  return React.createElement(Ui__Group.make, tmp);
}

var match$6 = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          justifyContent: "center"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.surface,
                    paddingHorizontal: theme.spacing._4
                  };
          })
      }
    });

var stylesheet$6 = match$6[1];

var styles$6 = match$6[0];

function Ui__ProductCard__Base$UpperPart(Props) {
  var width = Props.width;
  var height = Props.height;
  var children = Props.children;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$6, theme);
  return React.createElement(Ui__Group.make, {
              spacing: theme.spacing._2,
              children: children,
              containerStyle: [
                {
                  height: height,
                  width: width
                },
                Curry._1(styled, styles$6.container)
              ]
            });
}

function Ui__ProductCard__Base$LowerPart(Props) {
  var children = Props.children;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  return React.createElement(Ui__Group.make, {
              spacing: match.theme.spacing._2,
              children: children,
              testID: "product-card-base-lower-part"
            });
}

var match$7 = Ui__Styling.describe({
      topRightContainer: {
        TAG: /* Dual */2,
        _0: {
          position: "absolute",
          zIndex: 100
        },
        _1: (function (theme) {
            return {
                    right: theme.spacing._4,
                    top: theme.spacing._4
                  };
          })
      }
    });

var stylesheet$7 = match$7[1];

var styles$7 = match$7[0];

function Ui__ProductCard__Base$AbsoluteTopRight(Props) {
  var children = Props.children;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet$7, match.theme);
  return React.createElement(ReactNative.View, {
              style: Curry._1(styled, styles$7.topRightContainer),
              children: children
            });
}

function Ui__ProductCard__Base$LinkWrapper(Props) {
  var configuration = Props.configuration;
  var a11yLabel = Props.a11yLabel;
  var children = Props.children;
  var topRight = Props.topRight;
  var match = Ui__ProductCard__Utils.useSizes(undefined);
  var style = {
    width: match.cardWidth
  };
  if (topRight !== undefined) {
    return React.createElement(ReactNative.View, {
                style: style,
                children: null
              }, React.createElement(Ui__ProductCard__Base$AbsoluteTopRight, {
                    children: Caml_option.valFromOption(topRight)
                  }), React.createElement(Ui__AppLink.make, {
                    configuration: configuration,
                    children: children,
                    a11yLabel: a11yLabel,
                    style: style
                  }));
  } else {
    return React.createElement(Ui__AppLink.make, {
                configuration: configuration,
                children: children,
                a11yLabel: a11yLabel,
                style: style
              });
  }
}

var LinkWrapper = {
  make: Ui__ProductCard__Base$LinkWrapper
};

function Ui__ProductCard__Base$NewOnlyCard(Props) {
  var image = Props.image;
  var name = Props.name;
  var brandName = Props.brandName;
  var priceElement = Props.priceElement;
  var compareAtPriceElement = Props.compareAtPriceElement;
  var isPriceRange = Props.isPriceRange;
  var sizes = Props.sizes;
  var usedForTesting = Props.usedForTesting;
  var onPress = Props.onPress;
  var testID = Props.testID;
  var tmp = {
    children: null,
    width: sizes.cardWidth,
    usedForTesting: usedForTesting
  };
  if (onPress !== undefined) {
    tmp.onPress = Caml_option.valFromOption(onPress);
  }
  if (testID !== undefined) {
    tmp.testID = Caml_option.valFromOption(testID);
  }
  var tmp$1 = {
    priceElement: priceElement,
    priceSize: sizes.priceSize,
    secondaryTextSize: sizes.secondaryTextSize,
    isPriceRange: isPriceRange
  };
  if (compareAtPriceElement !== undefined) {
    tmp$1.compareAtPriceElement = Caml_option.valFromOption(compareAtPriceElement);
  }
  return React.createElement(Ui__ProductCard__Base$Container, tmp, React.createElement(Ui__ProductCard__Base$UpperPart, {
                  width: sizes.cardWidth,
                  height: sizes.imageContainerHeight,
                  children: null
                }, React.createElement(Ui__ProductCard__Base$TagPlaceholder, {}), React.createElement(Ui__ProductCard__Base$Image, {
                      image: image,
                      defaultAlt: name,
                      imageHeight: sizes.imageHeight,
                      imageWidth: sizes.cardWidth
                    }), React.createElement(Ui__ProductCard__Base$TagWithDefault, {
                      tag: Belt_Option.map(compareAtPriceElement, (function (param) {
                              return React.createElement(Ui__ProductCard__Base$SaleTag, {
                                          size: sizes.secondaryTextSize
                                        });
                            }))
                    })), React.createElement(Ui__ProductCard__Base$LowerPart, {
                  children: null
                }, React.createElement(Ui__ProductCard__Base$BrandName, {
                      brandName: brandName,
                      size: sizes.brandNameSize
                    }), React.createElement(Ui__ProductCard__Base$Name, {
                      name: name,
                      size: sizes.nameSize,
                      headingLevel: 3
                    }), React.createElement(Ui__ProductCard__Base$NewPrice, tmp$1)));
}

var NewOnlyCard = {
  make: Ui__ProductCard__Base$NewOnlyCard
};

function Ui__ProductCard__Base$NewAndPrelovedCard(Props) {
  var image = Props.image;
  var name = Props.name;
  var brandName = Props.brandName;
  var priceElement = Props.priceElement;
  var compareAtPriceElement = Props.compareAtPriceElement;
  var isPriceRange = Props.isPriceRange;
  var prelovedFromPriceElement = Props.prelovedFromPriceElement;
  var sizes = Props.sizes;
  var usedForTesting = Props.usedForTesting;
  var onPress = Props.onPress;
  var testID = Props.testID;
  var tmp = {
    children: null,
    width: sizes.cardWidth,
    usedForTesting: usedForTesting
  };
  if (onPress !== undefined) {
    tmp.onPress = Caml_option.valFromOption(onPress);
  }
  if (testID !== undefined) {
    tmp.testID = Caml_option.valFromOption(testID);
  }
  var tmp$1 = {
    priceElement: priceElement,
    priceSize: sizes.priceSize,
    secondaryTextSize: sizes.secondaryTextSize,
    isPriceRange: isPriceRange
  };
  if (compareAtPriceElement !== undefined) {
    tmp$1.compareAtPriceElement = Caml_option.valFromOption(compareAtPriceElement);
  }
  if (prelovedFromPriceElement !== undefined) {
    tmp$1.prelovedFromPriceElement = Caml_option.valFromOption(prelovedFromPriceElement);
  }
  return React.createElement(Ui__ProductCard__Base$Container, tmp, React.createElement(Ui__ProductCard__Base$UpperPart, {
                  width: sizes.cardWidth,
                  height: sizes.imageContainerHeight,
                  children: null
                }, React.createElement(Ui__ProductCard__Base$TagWithDefault, {
                      tag: Belt_Option.map(prelovedFromPriceElement, (function (param) {
                              return React.createElement(Ui__ProductCard__Base$PrelovedAvailableTag, {
                                          size: sizes.secondaryTextSize
                                        });
                            }))
                    }), React.createElement(Ui__ProductCard__Base$Image, {
                      image: image,
                      defaultAlt: name,
                      imageHeight: sizes.imageHeight,
                      imageWidth: sizes.cardWidth
                    }), React.createElement(Ui__ProductCard__Base$TagWithDefault, {
                      tag: Belt_Option.map(compareAtPriceElement, (function (param) {
                              return React.createElement(Ui__ProductCard__Base$SaleTag, {
                                          size: sizes.secondaryTextSize
                                        });
                            }))
                    })), React.createElement(Ui__ProductCard__Base$LowerPart, {
                  children: null
                }, React.createElement(Ui__ProductCard__Base$BrandName, {
                      brandName: brandName,
                      size: sizes.brandNameSize
                    }), React.createElement(Ui__ProductCard__Base$Name, {
                      name: name,
                      size: sizes.nameSize,
                      headingLevel: 3
                    }), React.createElement(Ui__ProductCard__Base$NewAndPrelovedPrice, tmp$1)));
}

var NewAndPrelovedCard = {
  make: Ui__ProductCard__Base$NewAndPrelovedCard
};

function Ui__ProductCard__Base$PrelovedOnlyCard(Props) {
  var image = Props.image;
  var name = Props.name;
  var brandName = Props.brandName;
  var sizes = Props.sizes;
  var priceElement = Props.priceElement;
  var compareAtPriceElement = Props.compareAtPriceElement;
  var isPriceRange = Props.isPriceRange;
  var usedForTesting = Props.usedForTesting;
  var onPress = Props.onPress;
  var testID = Props.testID;
  var tmp = {
    children: null,
    width: sizes.cardWidth,
    usedForTesting: usedForTesting
  };
  if (onPress !== undefined) {
    tmp.onPress = Caml_option.valFromOption(onPress);
  }
  if (testID !== undefined) {
    tmp.testID = Caml_option.valFromOption(testID);
  }
  var tmp$1 = {
    priceElement: priceElement,
    priceSize: sizes.priceSize,
    secondaryTextSize: sizes.secondaryTextSize,
    isPriceRange: isPriceRange
  };
  if (compareAtPriceElement !== undefined) {
    tmp$1.compareAtPriceElement = Caml_option.valFromOption(compareAtPriceElement);
  }
  return React.createElement(Ui__ProductCard__Base$Container, tmp, React.createElement(Ui__ProductCard__Base$UpperPart, {
                  width: sizes.cardWidth,
                  height: sizes.imageContainerHeight,
                  children: null
                }, React.createElement(Ui__ProductCard__Base$PrelovedOnlyTag, {
                      size: sizes.secondaryTextSize
                    }), React.createElement(Ui__ProductCard__Base$Image, {
                      image: image,
                      defaultAlt: name,
                      imageHeight: sizes.imageHeight,
                      imageWidth: sizes.cardWidth
                    }), React.createElement(Ui__ProductCard__Base$TagPlaceholder, {})), React.createElement(Ui__ProductCard__Base$LowerPart, {
                  children: null
                }, React.createElement(Ui__ProductCard__Base$BrandName, {
                      brandName: brandName,
                      size: sizes.brandNameSize
                    }), React.createElement(Ui__ProductCard__Base$Name, {
                      name: name,
                      size: sizes.nameSize,
                      headingLevel: 3
                    }), React.createElement(Ui__ProductCard__Base$NewPrice, tmp$1)));
}

var PrelovedOnlyCard = {
  make: Ui__ProductCard__Base$PrelovedOnlyCard
};

function Ui__ProductCard__Base$RefurbishedCard(Props) {
  var image = Props.image;
  var name = Props.name;
  var brandName = Props.brandName;
  var sizes = Props.sizes;
  var priceElement = Props.priceElement;
  var savings = Props.savings;
  var usedForTesting = Props.usedForTesting;
  var onPress = Props.onPress;
  var testID = Props.testID;
  var tmp = {
    children: null,
    width: sizes.cardWidth,
    usedForTesting: usedForTesting
  };
  if (onPress !== undefined) {
    tmp.onPress = Caml_option.valFromOption(onPress);
  }
  if (testID !== undefined) {
    tmp.testID = Caml_option.valFromOption(testID);
  }
  var tmp$1 = {
    priceElement: priceElement,
    secondaryTextSize: sizes.secondaryTextSize,
    priceSize: sizes.priceSize
  };
  if (savings !== undefined) {
    tmp$1.savings = Caml_option.valFromOption(savings);
  }
  return React.createElement(Ui__ProductCard__Base$Container, tmp, React.createElement(Ui__ProductCard__Base$UpperPart, {
                  width: sizes.cardWidth,
                  height: sizes.imageContainerHeight,
                  children: null
                }, React.createElement(Ui__RefurbishedTag.make, {
                      textSize: sizes.secondaryTextSize
                    }), React.createElement(Ui__ProductCard__Base$Image, {
                      image: image,
                      defaultAlt: name,
                      imageHeight: sizes.imageHeight,
                      imageWidth: sizes.cardWidth
                    }), React.createElement(Ui__ProductCard__Base$TagPlaceholder, {})), React.createElement(Ui__ProductCard__Base$LowerPart, {
                  children: null
                }, React.createElement(Ui__ProductCard__Base$BrandName, {
                      brandName: brandName,
                      size: sizes.brandNameSize
                    }), React.createElement(Ui__ProductCard__Base$Name, {
                      name: name,
                      size: sizes.nameSize,
                      headingLevel: 3
                    }), React.createElement(Ui__ProductCard__Base$RefurbishedOrOverstockedPrice, tmp$1)));
}

var RefurbishedCard = {
  make: Ui__ProductCard__Base$RefurbishedCard
};

function Ui__ProductCard__Base$OverstockedCard(Props) {
  var image = Props.image;
  var name = Props.name;
  var brandName = Props.brandName;
  var sizes = Props.sizes;
  var priceElement = Props.priceElement;
  var savings = Props.savings;
  var usedForTesting = Props.usedForTesting;
  var onPress = Props.onPress;
  var testID = Props.testID;
  var tmp = {
    children: null,
    width: sizes.cardWidth,
    usedForTesting: usedForTesting
  };
  if (onPress !== undefined) {
    tmp.onPress = Caml_option.valFromOption(onPress);
  }
  if (testID !== undefined) {
    tmp.testID = Caml_option.valFromOption(testID);
  }
  var tmp$1 = {
    priceElement: priceElement,
    secondaryTextSize: sizes.secondaryTextSize,
    priceSize: sizes.priceSize
  };
  if (savings !== undefined) {
    tmp$1.savings = Caml_option.valFromOption(savings);
  }
  return React.createElement(Ui__ProductCard__Base$Container, tmp, React.createElement(Ui__ProductCard__Base$UpperPart, {
                  width: sizes.cardWidth,
                  height: sizes.imageContainerHeight,
                  children: null
                }, React.createElement(Ui__OverstockedTag.make, {
                      textSize: sizes.secondaryTextSize
                    }), React.createElement(Ui__ProductCard__Base$Image, {
                      image: image,
                      defaultAlt: name,
                      imageHeight: sizes.imageHeight,
                      imageWidth: sizes.cardWidth
                    }), React.createElement(Ui__ProductCard__Base$TagPlaceholder, {})), React.createElement(Ui__ProductCard__Base$LowerPart, {
                  children: null
                }, React.createElement(Ui__ProductCard__Base$BrandName, {
                      brandName: brandName,
                      size: sizes.brandNameSize
                    }), React.createElement(Ui__ProductCard__Base$Name, {
                      name: name,
                      size: sizes.nameSize,
                      headingLevel: 3
                    }), React.createElement(Ui__ProductCard__Base$RefurbishedOrOverstockedPrice, tmp$1)));
}

var OverstockedCard = {
  make: Ui__ProductCard__Base$OverstockedCard
};

function Ui__ProductCard__Base$OpenBoxCard(Props) {
  var image = Props.image;
  var name = Props.name;
  var brandName = Props.brandName;
  var sizes = Props.sizes;
  var priceElement = Props.priceElement;
  var savings = Props.savings;
  var usedForTesting = Props.usedForTesting;
  var onPress = Props.onPress;
  var testID = Props.testID;
  var tmp = {
    children: null,
    width: sizes.cardWidth,
    usedForTesting: usedForTesting
  };
  if (onPress !== undefined) {
    tmp.onPress = Caml_option.valFromOption(onPress);
  }
  if (testID !== undefined) {
    tmp.testID = Caml_option.valFromOption(testID);
  }
  var tmp$1 = {
    priceElement: priceElement,
    secondaryTextSize: sizes.secondaryTextSize,
    priceSize: sizes.priceSize
  };
  if (savings !== undefined) {
    tmp$1.savings = Caml_option.valFromOption(savings);
  }
  return React.createElement(Ui__ProductCard__Base$Container, tmp, React.createElement(Ui__ProductCard__Base$UpperPart, {
                  width: sizes.cardWidth,
                  height: sizes.imageContainerHeight,
                  children: null
                }, React.createElement(Ui__OpenBoxTag.make, {
                      textSize: sizes.secondaryTextSize
                    }), React.createElement(Ui__ProductCard__Base$Image, {
                      image: image,
                      defaultAlt: name,
                      imageHeight: sizes.imageHeight,
                      imageWidth: sizes.cardWidth
                    }), React.createElement(Ui__ProductCard__Base$TagPlaceholder, {})), React.createElement(Ui__ProductCard__Base$LowerPart, {
                  children: null
                }, React.createElement(Ui__ProductCard__Base$BrandName, {
                      brandName: brandName,
                      size: sizes.brandNameSize
                    }), React.createElement(Ui__ProductCard__Base$Name, {
                      name: name,
                      size: sizes.nameSize,
                      headingLevel: 3
                    }), React.createElement(Ui__ProductCard__Base$RefurbishedOrOverstockedPrice, tmp$1)));
}

var OpenBoxCard = {
  make: Ui__ProductCard__Base$OpenBoxCard
};

function Ui__ProductCard__Base$PrelovedCard(Props) {
  var image = Props.image;
  var name = Props.name;
  var brandName = Props.brandName;
  var conditionElement = Props.conditionElement;
  var startingPriceElement = Props.startingPriceElement;
  var sizes = Props.sizes;
  var usedForTesting = Props.usedForTesting;
  var onPress = Props.onPress;
  var testID = Props.testID;
  var tmp = {
    children: null,
    width: sizes.cardWidth,
    usedForTesting: usedForTesting
  };
  if (onPress !== undefined) {
    tmp.onPress = Caml_option.valFromOption(onPress);
  }
  if (testID !== undefined) {
    tmp.testID = Caml_option.valFromOption(testID);
  }
  return React.createElement(Ui__ProductCard__Base$Container, tmp, React.createElement(ReactNative.View, {
                  style: {
                    height: sizes.imageContainerHeight,
                    width: sizes.cardWidth
                  },
                  children: React.createElement(Ui__ProductCard__Base$Image, {
                        image: image,
                        defaultAlt: name,
                        imageHeight: sizes.imageContainerHeight,
                        imageWidth: sizes.cardWidth,
                        resizeMode: "cover"
                      })
                }), React.createElement(Ui__ProductCard__Base$LowerPart, {
                  children: null
                }, conditionElement, React.createElement(Ui__ProductCard__Base$BrandName, {
                      brandName: brandName,
                      size: sizes.brandNameSize
                    }), React.createElement(Ui__ProductCard__Base$Name, {
                      name: name,
                      size: sizes.nameSize,
                      headingLevel: 3
                    }), SimpleReact$DecidrIoUtils.renderIfSome(startingPriceElement, (function (startingPriceElement) {
                        return React.createElement(Ui__ProductCard__Base$LabeledText, {
                                    children: startingPriceElement,
                                    label: "Bid from",
                                    secondaryTextSize: sizes.secondaryTextSize,
                                    mainTextSize: sizes.priceSize,
                                    testID: "preloved-item-search-result-card-starting-price"
                                  });
                      }))));
}

var PrelovedCard = {
  make: Ui__ProductCard__Base$PrelovedCard
};

var PrelovedOnlyPrice = {};

var $$Image = {};

export {
  PrelovedOnlyPrice ,
  $$Image ,
  LinkWrapper ,
  NewOnlyCard ,
  NewAndPrelovedCard ,
  PrelovedOnlyCard ,
  RefurbishedCard ,
  OverstockedCard ,
  PrelovedCard ,
  OpenBoxCard ,
}
/* match Not a pure module */
