// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as App__Report from "../../../utils/App__Report.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactRelay from "react-relay";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as App__Search__PrelovedItemResultCard from "../prelovedItemResultCard/App__Search__PrelovedItemResultCard.bs.js";
import * as App__Search__ProductVariantResultCard from "../productVariantResultCard/App__Search__ProductVariantResultCard.bs.js";
import * as AppSearchResultCard_searchResult_graphql from "../../../../../relay-codegen/generated/AppSearchResultCard_searchResult_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppSearchResultCard_searchResult_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppSearchResultCard_searchResult_graphql.Internal.convertFragment, data);
}

function App__Search__ResultCard(Props) {
  var searchResult = Props.searchResult;
  var imageSizes = Props.imageSizes;
  var imageResizeMode = Props.imageResizeMode;
  var linkingType = Props.linkingType;
  var prelovedCardStyle = Props.prelovedCardStyle;
  var testID = Props.testID;
  var prelovedWithStatusIndicator = Props.prelovedWithStatusIndicator;
  var prelovedWithProductName = Props.prelovedWithProductName;
  var searchResult$1 = use(searchResult);
  var variant = searchResult$1.NAME;
  if (variant === "PrelovedItemSearchResult") {
    var tmp = {
      prelovedItemSearchResult: searchResult$1.VAL.fragmentRefs,
      imageSizes: imageSizes
    };
    if (prelovedCardStyle !== undefined) {
      tmp.style = Caml_option.valFromOption(prelovedCardStyle);
    }
    if (imageResizeMode !== undefined) {
      tmp.imageResizeMode = Caml_option.valFromOption(imageResizeMode);
    }
    if (prelovedWithStatusIndicator !== undefined) {
      tmp.withStatusIndicator = prelovedWithStatusIndicator;
    }
    if (prelovedWithProductName !== undefined) {
      tmp.withProductName = prelovedWithProductName;
    }
    if (testID !== undefined) {
      tmp.testID = testID;
    }
    return React.createElement(App__Search__PrelovedItemResultCard.make, tmp);
  }
  if (variant === "UnselectedUnionMember") {
    App__Report.unknownUnionMember(searchResult$1.VAL);
    return null;
  }
  var tmp$1 = {
    productVariantSearchResult: searchResult$1.VAL.fragmentRefs,
    imageSizes: imageSizes
  };
  if (imageResizeMode !== undefined) {
    tmp$1.imageResizeMode = Caml_option.valFromOption(imageResizeMode);
  }
  if (linkingType !== undefined) {
    tmp$1.linkingType = Caml_option.valFromOption(linkingType);
  }
  return React.createElement(App__Search__ProductVariantResultCard.make, tmp$1);
}

var make = App__Search__ResultCard;

export {
  make ,
}
/* react Not a pure module */
