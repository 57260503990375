// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../../ui/src/group/components/Ui__Group.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Ui__Styling from "../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as ReactNative from "react-native";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as Ui__MarkotText from "../../../../ui/src/text/components/Ui__MarkotText.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as Columns$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/columns/components/Columns.bs.js";
import * as AppCategoryCategoriesDisplayQuery_graphql from "../../../../relay-codegen/generated/AppCategoryCategoriesDisplayQuery_graphql.bs.js";
import * as AppCategoryCategoriesDisplayInfo_category_graphql from "../../../../relay-codegen/generated/AppCategoryCategoriesDisplayInfo_category_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppCategoryCategoriesDisplayInfo_category_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppCategoryCategoriesDisplayInfo_category_graphql.Internal.convertFragment, data);
}

var match = Ui__Styling.describe({
      category: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "bold"
        }
      },
      newOnlyTag: {
        TAG: /* Dual */2,
        _0: {
          alignSelf: "flex-start"
        },
        _1: (function (theme) {
            return {
                    borderRadius: theme.roundness._4
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function App__Category__CategoriesDisplay$Info(Props) {
  var category = Props.category;
  var onSubcategoryPress = Props.onSubcategoryPress;
  var category$1 = use(category);
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  return React.createElement(Ui__Group.make, {
              spacing: theme.spacing._8,
              children: null
            }, React.createElement(Ui__MarkotText.make, {
                  size: "b2",
                  children: category$1.name,
                  style: Curry._1(styled, styles.category)
                }), React.createElement(Ui__Group.make, {
                  spacing: theme.spacing._10,
                  children: Belt_Array.map(category$1.subcategories.edges, (function (param) {
                          var match = param.node;
                          var slug = match.slug;
                          var name = match.name;
                          var id = match.id;
                          return React.createElement(ReactNative.Pressable, {
                                      children: (function (param) {
                                          var tmp = {};
                                          var tmp$1 = Bool$DecidrIoUtils.thenSome(Belt_Option.getWithDefault(param.hovered, false), "underline");
                                          if (tmp$1 !== undefined) {
                                            tmp.textDecorationLine = (function () {
                                                  switch (tmp$1) {
                                                    case "none" :
                                                        return "none";
                                                    case "underline" :
                                                        return "underline";
                                                    case "lineThrough" :
                                                        return "line-through";
                                                    case "underlineLineThrough" :
                                                        return "underline line-through";
                                                    
                                                  }
                                                })();
                                          }
                                          return React.createElement(Ui__MarkotText.make, {
                                                      size: "caption1",
                                                      children: name,
                                                      style: tmp
                                                    });
                                        }),
                                      onPress: (function (param) {
                                          Curry._3(onSubcategoryPress, id, slug, name);
                                        }),
                                      testID: "categories-display-subcategory-button",
                                      key: id
                                    });
                        })),
                  testID: "" + category$1.name + " subcategories"
                }));
}

function use$1(variables, fetchPolicy, fetchKey, networkCacheConfig, param) {
  var data = ReactRelay.useLazyLoadQuery(AppCategoryCategoriesDisplayQuery_graphql.node, RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(AppCategoryCategoriesDisplayQuery_graphql.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return RescriptRelay_Internal.internal_useConvertedValue(AppCategoryCategoriesDisplayQuery_graphql.Internal.convertResponse, data);
}

var match$1 = Ui__Styling.describe({
      container: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.secondaryVariant3
                  };
          })
      }
    });

var stylesheet$1 = match$1[1];

var styles$1 = match$1[0];

function App__Category__CategoriesDisplay(Props) {
  var onSubcategoryPress = Props.onSubcategoryPress;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$1, theme);
  var result = use$1({}, undefined, undefined, undefined, undefined);
  return React.createElement(Columns$DecidrIoUniversalComponents.make, {
              children: Belt_Array.map(result.categories.edges, (function (param) {
                      var category = param.node;
                      return React.createElement(App__Category__CategoriesDisplay$Info, {
                                  category: category.fragmentRefs,
                                  onSubcategoryPress: onSubcategoryPress,
                                  key: category.id
                                });
                    })),
              spacing: theme.spacing._6,
              containerStyle: Curry._1(styled, styles$1.container)
            });
}

var Info = {
  make: App__Category__CategoriesDisplay$Info
};

var make = App__Category__CategoriesDisplay;

export {
  Info ,
  make ,
}
/* match Not a pure module */
