// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Ui__Theme from "../../theme/components/Ui__Theme.bs.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";

function useCommonStyling(param) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var size = theme.typography.caption2;
  var media = Ui__Responsive.useMedia(undefined)(undefined);
  var paddingHorizontal = Curry.app(media.mediaSelect, [
        undefined,
        theme.spacing._2,
        undefined,
        undefined,
        undefined,
        theme.spacing._4,
        theme.spacing._5,
        theme.spacing._2,
        undefined
      ]);
  var paddingVertical = Curry.app(media.mediaSelect, [
        undefined,
        theme.spacing._1,
        undefined,
        undefined,
        undefined,
        theme.spacing._2,
        undefined,
        theme.spacing._1,
        undefined
      ]);
  var minWidth = Curry.app(media.mediaSelect, [
        undefined,
        45,
        55,
        50,
        59,
        70,
        80,
        45,
        undefined
      ]);
  var iconSize = Curry.app(media.mediaSelect, [
        undefined,
        9,
        undefined,
        11,
        undefined,
        13,
        15,
        9,
        undefined
      ]);
  return {
          containerStyle: {
            justifyContent: "center",
            minWidth: minWidth,
            paddingHorizontal: paddingHorizontal,
            paddingVertical: paddingVertical
          },
          textStyle: {
            fontSize: Curry._1(Ui__Theme.Typography.Font.getSize, size),
            letterSpacing: Curry._1(Ui__Theme.Typography.Font.getSize, size) * Curry._1(Ui__Theme.Typography.Font.getLetterSpacingPct, size),
            lineHeight: Curry._1(Ui__Theme.Typography.Font.getLineHeight, size)
          },
          iconSize: iconSize
        };
}

export {
  useCommonStyling ,
}
/* Ui__Theme Not a pure module */
