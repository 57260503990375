// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "../../routing/Ui__AppLink.bs.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__IconButton from "../../iconButton/components/Ui__IconButton.bs.js";
import * as Ui__MarkotIcon from "../../icons/components/Ui__MarkotIcon.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as Ui__MarkotLogoWithTitle from "../../svg/logoWithTitle/Ui__MarkotLogoWithTitle.bs.js";
import * as Box$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/box/components/Box.bs.js";
import * as RnUtils$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/utils/RnUtils.bs.js";

function Ui__MainHeaderBar$HeaderLeftButton(Props) {
  var kind = Props.kind;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "header-left-button";
  var match = Curry._1(Ui__AppLink.useRouting, undefined);
  var routeConfiguration = Curry._3(Ui__AppLink.makeConfiguration, /* Home */5, undefined, undefined);
  if (typeof kind === "object") {
    var onPress = kind.VAL;
    return React.createElement(Box$DecidrIoUniversalComponents.make, {
                kind: {
                  NAME: "Pressable",
                  VAL: Box$DecidrIoUniversalComponents.makePressableProps(undefined, undefined, undefined, (function (param) {
                          Curry._1(onPress, undefined);
                        }), undefined, undefined, undefined)
                },
                children: React.createElement(Ui__MarkotIcon.make, {
                      name: "chevron-left",
                      size: 18,
                      testID: "back-button"
                    }),
                hitSlop: RnUtils$DecidrIoUniversalComponents.makeSymmetricHitSlop(8),
                testID: testID
              });
  }
  var tmp = {
    children: React.createElement(Ui__AppLink.make, {
          configuration: routeConfiguration,
          children: React.createElement(Ui__MarkotLogoWithTitle.make, {
                size: 24,
                id: "markot-logo-with-title"
              }),
          a11yLabel: "Go to discover page"
        }),
    hitSlop: RnUtils$DecidrIoUniversalComponents.makeSymmetricHitSlop(8),
    testID: testID
  };
  var tmp$1 = Belt_Option.map(match.nativeNavigationHandler, (function (navHandler) {
          return {
                  NAME: "Pressable",
                  VAL: Box$DecidrIoUniversalComponents.makePressableProps(undefined, undefined, undefined, (function (param) {
                          Curry._1(navHandler, routeConfiguration);
                        }), undefined, undefined, undefined)
                };
        }));
  if (tmp$1 !== undefined) {
    tmp.kind = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Box$DecidrIoUniversalComponents.make, tmp);
}

var HeaderLeftButton = {
  make: Ui__MainHeaderBar$HeaderLeftButton
};

var isNative = ReactNative.Platform.OS === "ios" || ReactNative.Platform.OS === "android";

var tmp = {
  alignItems: "center",
  justifyContent: "space-between"
};

var tmp$1 = Bool$DecidrIoUtils.thenSome(ReactNative.Platform.OS !== "android", {
      height: 2,
      width: 0
    });

if (tmp$1 !== undefined) {
  tmp.shadowOffset = Caml_option.valFromOption(tmp$1);
}

var tmp$2 = Bool$DecidrIoUtils.thenSome(ReactNative.Platform.OS !== "android", 0.05);

if (tmp$2 !== undefined) {
  tmp.shadowOpacity = tmp$2;
}

var tmp$3 = {};

var tmp$4 = Bool$DecidrIoUtils.thenSome(!isNative, 1);

if (tmp$4 !== undefined) {
  tmp$3.flex = tmp$4;
}

var tmp$5 = Bool$DecidrIoUtils.thenSome(isNative, 1);

if (tmp$5 !== undefined) {
  tmp$3.flexGrow = tmp$5;
}

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: tmp,
        _1: (function (theme) {
            var tmp = {
              backgroundColor: theme.colors.surface,
              padding: theme.spacing._8,
              paddingHorizontal: theme.spacing._6,
              paddingVertical: theme.spacing._8
            };
            var tmp$1 = Bool$DecidrIoUtils.thenSome(ReactNative.Platform.OS === "android", theme.colors.primaryShade4);
            if (tmp$1 !== undefined) {
              tmp.borderBottomColor = Caml_option.valFromOption(tmp$1);
            }
            var tmp$2 = Bool$DecidrIoUtils.thenSome(ReactNative.Platform.OS === "android", theme.lineThickness.xxs);
            if (tmp$2 !== undefined) {
              tmp.borderBottomWidth = tmp$2;
            }
            return tmp;
          })
      },
      searchBox: {
        TAG: /* Static */0,
        _0: tmp$3
      },
      searchBoxPlaceholderContainer: {
        TAG: /* Dual */2,
        _0: {
          flex: 1
        },
        _1: (function (theme) {
            return {
                    paddingVertical: theme.spacing._2
                  };
          })
      },
      searchBoxPlaceholder: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    height: 28,
                    paddingHorizontal: theme.spacing._6,
                    paddingVertical: theme.spacing._3
                  };
          })
      },
      searchButton: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.primaryShade1,
                    borderRadius: theme.roundness._2,
                    paddingHorizontal: theme.spacing._2,
                    paddingVertical: theme.spacing._2
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__MainHeaderBar(Props) {
  var containerStyle = Props.containerStyle;
  var headerLeftKind = Props.headerLeftKind;
  var activeIcon = Props.activeIcon;
  var testIDOpt = Props.testID;
  var onPressOpt = Props.onPress;
  var testID = testIDOpt !== undefined ? testIDOpt : "main-header-bar";
  var onPress = onPressOpt !== undefined ? onPressOpt : (function (param) {
        
      });
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = Curry._1(Ui__AppLink.useRouting, undefined);
  var nativeNavigationHandler = match$1.nativeNavigationHandler;
  var bagRouteConfiguration = Curry._3(Ui__AppLink.makeConfiguration, /* Bag */13, undefined, undefined);
  var wishlistRouteConfiguration = Curry._3(Ui__AppLink.makeConfiguration, /* Wishlist */10, undefined, undefined);
  var searchIcon = React.createElement(Ui__MarkotIcon.make, {
        name: "magnifying-glass-outline",
        size: 20,
        color: theme.colors.primary,
        testID: "search-box-button"
      });
  var tmp = {
    active: activeIcon === "wishlist",
    size: 20
  };
  var tmp$1 = Belt_Option.map(nativeNavigationHandler, (function (navHandler, param) {
          Curry._1(navHandler, wishlistRouteConfiguration);
        }));
  if (tmp$1 !== undefined) {
    tmp.onPress = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    active: activeIcon === "bag",
    size: 20
  };
  var tmp$3 = Belt_Option.map(nativeNavigationHandler, (function (navHandler, param) {
          Curry._1(navHandler, bagRouteConfiguration);
        }));
  if (tmp$3 !== undefined) {
    tmp$2.onPress = Caml_option.valFromOption(tmp$3);
  }
  return React.createElement(Ui__Group.make, {
              children: null,
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles.container)),
                containerStyle
              ],
              orientation: "horizontal",
              testID: testID,
              accessibilityRole: "banner"
            }, React.createElement(Ui__MainHeaderBar$HeaderLeftButton, {
                  kind: headerLeftKind
                }), React.createElement(Ui__Group.make, {
                  spacing: theme.spacing._10,
                  children: null,
                  orientation: "horizontal",
                  accessibilityRole: "navigation"
                }, React.createElement(Ui__IconButton.make, {
                      active: false,
                      activeIcon: searchIcon,
                      inactiveIcon: searchIcon,
                      onPress: onPress,
                      size: 20,
                      testID: "main-header-bar-search-icon-button"
                    }), React.createElement(Ui__AppLink.make, {
                      configuration: wishlistRouteConfiguration,
                      children: React.createElement(Ui__IconButton.Bookmark.make, tmp),
                      a11yLabel: "Go check your wishlist"
                    }), React.createElement(Ui__AppLink.make, {
                      configuration: bagRouteConfiguration,
                      children: React.createElement(Ui__IconButton.Bag.make, tmp$2),
                      a11yLabel: "Go check your bag"
                    })));
}

var iconSize = 20;

var make = Ui__MainHeaderBar;

export {
  iconSize ,
  HeaderLeftButton ,
  make ,
}
/* isNative Not a pure module */
