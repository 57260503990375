// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../../../ui/src/group/components/Ui__Group.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Reanimated from "rescript-reanimated/src/Reanimated.bs.js";
import * as Ui__Portal from "../../../../../ui/src/portal/Ui__Portal.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as Router from "next/router";
import * as ReactRelay from "react-relay";
import * as ReactNative from "react-native";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as Ui__EmptyState from "../../../../../ui/src/emptyState/components/Ui__EmptyState.bs.js";
import * as Ui__MarkotIcon from "../../../../../ui/src/icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__Responsive from "../../../../../ui/src/responsive/Ui__Responsive.bs.js";
import LodashDebounce from "lodash.debounce";
import * as Utils__Analytics from "../../../../../utils/src/analytics/Utils__Analytics.bs.js";
import * as Ui__PressableText from "../../../../../ui/src/pressableText/components/Ui__PressableText.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as Ui__GlobalSearchBox from "../../../../../ui/src/globalSearch/Ui__GlobalSearchBox.bs.js";
import * as Ui__NoSearchResults from "../../../../../ui/src/noSearchResults/components/Ui__NoSearchResults.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as App__Search__ResultCard from "../resultCard/App__Search__ResultCard.bs.js";
import * as ReactNativeReanimated from "react-native-reanimated";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as FeatureFlags__LocalFeatureFlags from "../../../../../feature-flags/src/local/FeatureFlags__LocalFeatureFlags.bs.js";
import * as AppSearchOverlayResultsQuery_graphql from "../../../../../relay-codegen/generated/AppSearchOverlayResultsQuery_graphql.bs.js";
import * as AppSearchOverlayResults_query_graphql from "../../../../../relay-codegen/generated/AppSearchOverlayResults_query_graphql.bs.js";
import * as DecidrNonScrollableList$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/list/components/DecidrNonScrollableList.bs.js";

function internal_makeRefetchableFnOpts(fetchPolicy, onComplete, param) {
  var tmp = {};
  var tmp$1 = RescriptRelay.mapFetchPolicy(fetchPolicy);
  if (tmp$1 !== undefined) {
    tmp.fetchPolicy = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = RescriptRelay_Internal.internal_nullableToOptionalExnHandler(onComplete);
  if (tmp$2 !== undefined) {
    tmp.onComplete = Caml_option.valFromOption(tmp$2);
  }
  return tmp;
}

function useRefetchable(fRef) {
  var match = ReactRelay.useRefetchableFragment(AppSearchOverlayResults_query_graphql.node, fRef);
  var refetchFn = match[1];
  var data = RescriptRelay_Internal.internal_useConvertedValue(AppSearchOverlayResults_query_graphql.Internal.convertFragment, match[0]);
  return [
          data,
          React.useMemo((function () {
                  return function (param, param$1, param$2, param$3) {
                    return Curry._2(refetchFn, RescriptRelay_Internal.internal_removeUndefinedAndConvertNullsRaw(AppSearchOverlayResultsQuery_graphql.Internal.convertVariables(param)), internal_makeRefetchableFnOpts(param$1, param$2, undefined));
                  };
                }), [refetchFn])
        ];
}

var makeRefetchVariables = AppSearchOverlayResultsQuery_graphql.Types.makeRefetchVariables;

var match = Ui__Styling.describe({
      searchBox: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      overlay: {
        TAG: /* Static */0,
        _0: {
          height: Ui__Styling.pct(150),
          position: "absolute",
          width: Ui__Styling.pct(100),
          zIndex: 1
        }
      },
      resultsContainer: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.background,
                    paddingHorizontal: theme.sectionSpacing._05,
                    paddingVertical: theme.sectionSpacing._1
                  };
          })
      },
      headerContainer: {
        TAG: /* Static */0,
        _0: {
          flexDirection: "row-reverse",
          justifyContent: "space-between"
        }
      },
      list: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      backdrop: {
        TAG: /* Static */0,
        _0: {
          backgroundColor: "black",
          flex: 1
        }
      },
      childWrapper: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

var initialSearchTerm = "";

var widthLimiterStyle = Ui__Responsive.widthLimiterStyle(undefined, undefined, undefined);

function App__Search__OverlayResults(Props) {
  var queryRef = Props.queryRef;
  var search = Props.search;
  var searching = Props.searching;
  var headerHeightOpt = Props.headerHeight;
  var onSearchBoxFocus = Props.onSearchBoxFocus;
  var searchBoxStyle = Props.searchBoxStyle;
  var onSearchBoxBlurOrOverlayClose = Props.onSearchBoxBlurOrOverlayClose;
  var onSearchSubmit = Props.onSearchSubmit;
  var onProductVariantPress = Props.onProductVariantPress;
  var disableScrollYStyleElement = Props.disableScrollYStyleElement;
  var onSearching = Props.onSearching;
  var headerHeight = headerHeightOpt !== undefined ? headerHeightOpt : 105;
  var router = Router.useRouter();
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = Utils__Analytics.useSegmentAnalytics(undefined);
  var track = match$1.track;
  var maxNumberOfResultsToDisplay = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        6,
        5,
        undefined
      ]);
  var imageSizes = "" + String(100 / maxNumberOfResultsToDisplay) + "vw";
  var match$2 = useRefetchable(queryRef);
  var refetch = match$2[1];
  var query = match$2[0];
  var match$3 = React.useState(function () {
        return initialSearchTerm;
      });
  var setSearchTerm = match$3[1];
  var searchTerm = match$3[0];
  var match$4 = React.useState(function () {
        return initialSearchTerm;
      });
  var setActiveSearchTerm = match$4[1];
  var activeSearchTerm = match$4[0];
  var overlayPosition = ReactNativeReanimated.useSharedValue(0);
  var implementRefurbishedProducts = FeatureFlags__LocalFeatureFlags.use("implementRefurbishedProducts");
  var match$5 = React.useState(function () {
        return false;
      });
  var setIsOverlayOpen = match$5[1];
  var isOverlayOpen = match$5[0];
  var openOverlay = function (param) {
    Curry._1(setIsOverlayOpen, (function (param) {
            return true;
          }));
    overlayPosition.value = Reanimated.withTiming(1, Reanimated.Timing.makeConfig(350, undefined, undefined), undefined, undefined);
  };
  var closeOverlay = function (param) {
    Curry._1(setIsOverlayOpen, (function (param) {
            return false;
          }));
    overlayPosition.value = Reanimated.withTiming(0, Reanimated.Timing.makeConfig(350, undefined, undefined), undefined, undefined);
  };
  var partial_arg = LodashDebounce((function (searchTerm) {
          var trimmedSearchTerm = searchTerm.trim();
          Curry._1(onSearching, false);
          if (ReactNative.Platform.OS === "web") {
            router.push("/search" + (
                  trimmedSearchTerm === "" ? "" : "?term=" + trimmedSearchTerm
                ) + "");
            return ;
          } else {
            return Curry._1(search, {
                        searchTerm: trimmedSearchTerm,
                        onSuccess: (function (param) {
                            Curry._4(refetch, Curry._4(makeRefetchVariables, undefined, true, Caml_option.some(trimmedSearchTerm), undefined), /* StoreOnly */0, (function (param) {
                                    Curry._1(setActiveSearchTerm, (function (param) {
                                            return trimmedSearchTerm;
                                          }));
                                    openOverlay(undefined);
                                  }), undefined);
                          }),
                        timestamp: Date.now()
                      });
          }
        }), 500);
  var performSearch = React.useCallback(Curry.__1(partial_arg), [
        search,
        refetch
      ]);
  var handleOnChange = function (searchTerm) {
    Curry._1(onSearching, true);
    Curry._1(setSearchTerm, (function (param) {
            return searchTerm;
          }));
    Curry._1(performSearch, searchTerm);
  };
  var overlayStyle = ReactNativeReanimated.useAnimatedStyle(function () {
        var translateY = ReactNativeReanimated.interpolate(overlayPosition.value, [
              0,
              1
            ], [
              -630,
              0
            ], undefined);
        return {
                transform: [{
                    translateY: translateY
                  }]
              };
      });
  var backdropStyle = ReactNativeReanimated.useAnimatedStyle(function () {
        var opacity = ReactNativeReanimated.interpolate(overlayPosition.value, [
              0,
              1
            ], [
              0,
              0.5
            ], undefined);
        return {
                opacity: opacity
              };
      });
  var handleSearchBoxClearPress = function (param) {
    handleOnChange("");
    closeOverlay(undefined);
    Curry._1(onSearchBoxBlurOrOverlayClose, undefined);
  };
  var handleSubmit = Belt_Option.map(onSearchSubmit, (function (onSearchSubmit, param) {
          Curry._1(onSearchSubmit, searchTerm.trim());
        }));
  React.useEffect((function () {
          if (activeSearchTerm !== "") {
            Belt_Option.forEach(query.search, (function (searchData) {
                    Curry._2(track, {
                          TAG: /* NewProductsSearched */1,
                          _0: {
                            query: activeSearchTerm,
                            origin: "global-search",
                            products: Belt_Array.map(searchData.edges, (function (param) {
                                    var match = param.node;
                                    var product = match.product;
                                    var price = match.price;
                                    return {
                                            variantId: match.productVariantId,
                                            name: Belt_Option.map(product, (function (param) {
                                                    return param.name;
                                                  })),
                                            brand: Belt_Option.flatMap(product, (function (param) {
                                                    return param.brandName;
                                                  })),
                                            testProduct: Belt_Option.map(product, (function (param) {
                                                    return param.usedForTesting;
                                                  })),
                                            price: Belt_Option.flatMap(price, (function (price) {
                                                    return Belt_Float.fromString(price.amount);
                                                  })),
                                            compareAtPrice: Belt_Option.flatMap(match.compareAtPrice, (function (compareAtPrice) {
                                                    return Belt_Float.fromString(compareAtPrice.amount);
                                                  })),
                                            currency: Belt_Option.map(price, (function (price) {
                                                    return price.currency;
                                                  })),
                                            imageUrl: Belt_Option.map(match.mainImage, (function (param) {
                                                    return param.url;
                                                  }))
                                          };
                                  })),
                            hits: searchData.edges.length
                          }
                        }, "App__Search__OverlayResults search");
                  }));
          }
          
        }), [query.search]);
  var search$1 = query.search;
  if (search$1 !== undefined) {
    var tmp = {
      onChange: handleOnChange,
      onClear: handleSearchBoxClearPress,
      searchTerm: searchTerm,
      searching: searching,
      containerStyle: [
        Caml_option.some(Curry._1(styled, styles.searchBox)),
        searchBoxStyle
      ],
      onBlur: (function (param) {
          if (searchTerm === "") {
            return Curry._1(onSearchBoxBlurOrOverlayClose, undefined);
          }
          
        }),
      onFocus: (function (param) {
          Curry._1(onSearchBoxFocus, undefined);
          if (activeSearchTerm !== "" && !searching) {
            return openOverlay(undefined);
          }
          
        })
    };
    if (handleSubmit !== undefined) {
      tmp.onSubmitEditing = Caml_option.valFromOption(handleSubmit);
    }
    var results = search$1.edges;
    var tmp$1;
    if (results.length !== 0) {
      var tmp$2 = {
        data: Belt_Array.slice(results, 0, maxNumberOfResultsToDisplay),
        itemSpacing: theme.spacing._14,
        renderItem: (function (param) {
            var node = param.item.node;
            return React.createElement(App__Search__ResultCard.make, {
                        searchResult: node.fragmentRefs,
                        imageSizes: imageSizes,
                        imageResizeMode: "cover",
                        linkingType: {
                          NAME: "External",
                          VAL: (function (slug, productId, productVariantId) {
                              Curry._3(onProductVariantPress, slug, productId, productVariantId);
                              Curry._1(onSearchBoxBlurOrOverlayClose, undefined);
                              closeOverlay(undefined);
                            })
                        },
                        key: Belt_Option.getWithDefault(node.id, String(param.index))
                      });
          }),
        orientation: "horizontal",
        numColumns: maxNumberOfResultsToDisplay,
        style: [
          Curry._1(styled, styles.list),
          widthLimiterStyle
        ]
      };
      var tmp$3 = Bool$DecidrIoUtils.thenSome(implementRefurbishedProducts === "on", Curry._1(styled, styles.childWrapper));
      if (tmp$3 !== undefined) {
        tmp$2.childWrapperStyle = Caml_option.valFromOption(tmp$3);
      }
      tmp$1 = React.createElement(DecidrNonScrollableList$DecidrIoUniversalComponents.make, tmp$2);
    } else {
      tmp$1 = activeSearchTerm === "" ? React.createElement(Ui__EmptyState.make, {
              children: React.createElement(Ui__Group.make, {
                    children: null
                  }, React.createElement(Ui__EmptyState.Title.make, {
                        text: "Nothing to show"
                      }), React.createElement(Ui__EmptyState.Description.make, {
                        text: "There is nothing to show."
                      }))
            }) : React.createElement(Ui__NoSearchResults.make, {
              searchTerm: activeSearchTerm,
              searchedObject: "results"
            });
    }
    return React.createElement(React.Fragment, undefined, SimpleReact$DecidrIoUtils.whenTrue(isOverlayOpen, disableScrollYStyleElement), React.createElement(Ui__GlobalSearchBox.make, tmp), React.createElement(Ui__Portal.make, {
                    children: React.createElement(Reanimated.View.make, {
                          style: [
                            Curry._1(styled, styles.overlay),
                            {
                              top: headerHeight
                            },
                            overlayStyle
                          ],
                          children: null,
                          pointerEvents: (function () {
                                switch (isOverlayOpen ? "auto" : "none") {
                                  case "auto" :
                                      return "auto";
                                  case "none" :
                                      return "none";
                                  case "boxNone" :
                                      return "box-none";
                                  case "boxOnly" :
                                      return "box-only";
                                  
                                }
                              })()
                        }, React.createElement(Ui__Group.make, {
                              spacing: theme.spacing._20,
                              children: null,
                              containerStyle: Curry._1(styled, styles.resultsContainer)
                            }, React.createElement(Ui__Group.make, {
                                  children: null,
                                  containerStyle: [
                                    Curry._1(styled, styles.headerContainer),
                                    widthLimiterStyle
                                  ]
                                }, React.createElement(ReactNative.TouchableOpacity, {
                                      onPress: (function (param) {
                                          Curry._1(onSearchBoxBlurOrOverlayClose, undefined);
                                          closeOverlay(undefined);
                                        }),
                                      children: React.createElement(Ui__MarkotIcon.make, {
                                            name: "x-mark",
                                            size: 20
                                          })
                                    }), SimpleReact$DecidrIoUtils.whenTrue(search$1.edges.length > maxNumberOfResultsToDisplay, React.createElement(Ui__PressableText.make, {
                                          title: "See all",
                                          behavior: {
                                            NAME: "Link",
                                            VAL: {
                                              route: {
                                                TAG: /* Search */18,
                                                searchTerm: activeSearchTerm,
                                                loadDataInitially: true,
                                                sellingNewItems: undefined,
                                                brand: undefined,
                                                category: undefined,
                                                productType: undefined
                                              },
                                              a11yLabel: "See all results for " + activeSearchTerm + ""
                                            }
                                          },
                                          color: theme.colors.primary,
                                          underlined: true,
                                          textSize: "b2"
                                        }))), tmp$1), React.createElement(ReactNative.TouchableWithoutFeedback, {
                              onPress: (function (param) {
                                  Curry._1(onSearchBoxBlurOrOverlayClose, undefined);
                                  closeOverlay(undefined);
                                }),
                              children: React.createElement(Reanimated.View.make, {
                                    style: [
                                      Curry._1(styled, styles.backdrop),
                                      backdropStyle
                                    ]
                                  })
                            })),
                    hostName: "SearchOverlayHost"
                  }));
  }
  var tmp$4 = {
    onChange: handleOnChange,
    onClear: handleSearchBoxClearPress,
    searchTerm: searchTerm,
    searching: searching,
    containerStyle: [
      Caml_option.some(Curry._1(styled, styles.searchBox)),
      searchBoxStyle
    ],
    onBlur: (function (param) {
        Curry._1(onSearchBoxBlurOrOverlayClose, undefined);
      }),
    onFocus: (function (param) {
        Curry._1(onSearchBoxFocus, undefined);
      })
  };
  if (handleSubmit !== undefined) {
    tmp$4.onSubmitEditing = Caml_option.valFromOption(handleSubmit);
  }
  return React.createElement(Ui__GlobalSearchBox.make, tmp$4);
}

var make = App__Search__OverlayResults;

export {
  make ,
}
/* match Not a pure module */
