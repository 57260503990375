// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__DrawerSheet from "../../../../../packages/ui/src/drawerSheet/components/Ui__DrawerSheet.bs.js";
import * as App__ProfilePage from "../../../../../packages/app/src/userProfile/App__ProfilePage.bs.js";
import * as Ui__ListsLoadingView from "../../../../../packages/ui/src/loading/components/Ui__ListsLoadingView.bs.js";

function Web__AccountDrawer(Props) {
  var isOpened = Props.isOpened;
  var onClose = Props.onClose;
  var contentStyle = Props.contentStyle;
  var containerStyle = Props.containerStyle;
  var tmp = {
    isOpened: isOpened,
    onClose: onClose,
    children: React.createElement(React.Suspense, {
          children: React.createElement(App__ProfilePage.make, {}),
          fallback: React.createElement(Ui__ListsLoadingView.make, {})
        }),
    position: "right",
    portalHostName: "NavBarDrawer"
  };
  if (contentStyle !== undefined) {
    tmp.containerStyle = Caml_option.valFromOption(contentStyle);
  }
  if (containerStyle !== undefined) {
    tmp.wrapperStyle = Caml_option.valFromOption(containerStyle);
  }
  return React.createElement(Ui__DrawerSheet.make, tmp);
}

var make = Web__AccountDrawer;

export {
  make ,
}
/* react Not a pure module */
