// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Ui__Styling from "./Ui__Styling.bs.js";
import * as Ui__Responsive from "../responsive/Ui__Responsive.bs.js";

function use(param) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = Ui__Responsive.useMedia(undefined)(undefined);
  var mediaSelect = match$1.mediaSelect;
  var extraSmall = Curry.app(mediaSelect, [
        undefined,
        undefined,
        undefined,
        theme.spacing._6,
        undefined,
        theme.spacing._8,
        undefined,
        theme.spacing._4,
        undefined
      ]);
  var small = Curry.app(mediaSelect, [
        undefined,
        undefined,
        undefined,
        theme.spacing._6,
        undefined,
        theme.spacing._8,
        undefined,
        theme.spacing._4,
        undefined
      ]);
  var medium = Curry.app(mediaSelect, [
        undefined,
        undefined,
        undefined,
        theme.spacing._8,
        undefined,
        theme.spacing._10,
        undefined,
        theme.spacing._6,
        undefined
      ]);
  var large = Curry.app(mediaSelect, [
        undefined,
        undefined,
        undefined,
        theme.spacing._10,
        undefined,
        theme.spacing._12,
        undefined,
        theme.spacing._8,
        undefined
      ]);
  return {
          xs: extraSmall,
          s: small,
          m: medium,
          l: large
        };
}

export {
  use ,
}
/* Ui__Styling Not a pure module */
