// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";

var match = Ui__Styling.describe({
      text: {
        TAG: /* Static */0,
        _0: {
          textAlign: "center"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__NoSearchResults(Props) {
  var searchTerm = Props.searchTerm;
  var searchedObject = Props.searchedObject;
  var containerStyle = Props.containerStyle;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  return React.createElement(Ui__MarkotText.make, {
              size: "b2",
              color: theme.colors.onPrimaryShade4,
              children: null,
              style: [
                Caml_option.some(Curry._1(styled, styles.text)),
                containerStyle
              ],
              testID: "no-search-results"
            }, "There are no " + searchedObject + " found under the ", React.createElement(Ui__MarkotText.make, {
                  size: "b2",
                  color: theme.colors.primaryShade1,
                  children: "\"" + searchTerm + "\""
                }), " search.");
}

var make = Ui__NoSearchResults;

export {
  make ,
}
/* match Not a pure module */
