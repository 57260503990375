// Generated by ReScript, PLEASE EDIT WITH CARE


function determineIfVisible(sellingNewItems, sellingPrelovedItems) {
  if (sellingNewItems || !sellingPrelovedItems) {
    return false;
  } else {
    return true;
  }
}

var commonStyling = {
  alignSelf: "flex-start"
};

export {
  determineIfVisible ,
  commonStyling ,
}
/* No side effect */
