// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Styling from "../styling/Ui__Styling.bs.js";
import * as Tag$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/tag/components/Tag.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          alignSelf: "flex-start"
        }
      },
      text: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "bold"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__SaleTag(Props) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  return React.createElement(Tag$DecidrIoUniversalComponents.make, {
              text: "Sale",
              backgroundColor: theme.colors.infoShade3,
              textColor: theme.colors.primary,
              textStyle: Curry._1(styled, styles.text),
              containerStyle: Curry._1(styled, styles.container)
            });
}

var make = Ui__SaleTag;

export {
  make ,
}
/* match Not a pure module */
