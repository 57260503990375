// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Card from "../../../../../ui/src/card/Ui__Card.bs.js";
import * as Ui__Group from "../../../../../ui/src/group/components/Ui__Group.bs.js";
import * as App__Money from "../../../money/components/App__Money.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "../../../../../ui/src/routing/Ui__AppLink.bs.js";
import * as Ui__SaleTag from "../../../../../ui/src/saleTag/Ui__SaleTag.bs.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as Ui__MarkotText from "../../../../../ui/src/text/components/Ui__MarkotText.bs.js";
import * as Ui__ProductTag from "../../../../../ui/src/productTag/components/Ui__ProductTag.bs.js";
import * as Ui__Responsive from "../../../../../ui/src/responsive/Ui__Responsive.bs.js";
import * as Ui__ImageFallback from "../../../../../ui/src/image/components/imageFallback/Ui__ImageFallback.bs.js";
import * as Ui__BrandNameFallback from "../../../../../ui/src/brandNameFallback/Ui__BrandNameFallback.bs.js";
import * as Ui__ProductCard__Base from "../../../../../ui/src/productCard/Ui__ProductCard__Base.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as Ui__ProductPrelovedOnlyTag from "../../../../../ui/src/productTag/components/Ui__ProductPrelovedOnlyTag.bs.js";
import * as FeatureFlags__LocalFeatureFlags from "../../../../../feature-flags/src/local/FeatureFlags__LocalFeatureFlags.bs.js";
import * as Ui__ExperimentalComponentSpacing from "../../../../../ui/src/styling/Ui__ExperimentalComponentSpacing.bs.js";
import * as App__ProductVariantSearchResult__Card from "./App__ProductVariantSearchResult__Card.bs.js";
import * as AppSearchProductVariantResultCard_productVariantSearchResult_graphql from "../../../../../relay-codegen/generated/AppSearchProductVariantResultCard_productVariantSearchResult_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppSearchProductVariantResultCard_productVariantSearchResult_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppSearchProductVariantResultCard_productVariantSearchResult_graphql.Internal.convertFragment, data);
}

var match = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          flex: 1,
          justifyContent: "space-between"
        }
      },
      productName: {
        TAG: /* Static */0,
        _0: {
          height: 50
        }
      },
      link: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      price: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "bold"
        }
      },
      discountedPrice: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "500",
          textDecorationLine: "line-through"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function App__Search__ProductVariantResultCard(Props) {
  var productVariantSearchResult = Props.productVariantSearchResult;
  var imageSizes = Props.imageSizes;
  var imageResizeModeOpt = Props.imageResizeMode;
  var linkingTypeOpt = Props.linkingType;
  var imageResizeMode = imageResizeModeOpt !== undefined ? imageResizeModeOpt : "contain";
  var linkingType = linkingTypeOpt !== undefined ? linkingTypeOpt : "Handled";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var imageHeight = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        undefined,
        200,
        undefined,
        220,
        undefined,
        160,
        undefined
      ]);
  var match$1 = Ui__ExperimentalComponentSpacing.use(undefined);
  var xs = match$1.xs;
  var styled = Curry._1(stylesheet, theme);
  var implementRefurbishedProducts = FeatureFlags__LocalFeatureFlags.use("implementRefurbishedProducts");
  var match$2 = use(productVariantSearchResult);
  var fragmentRefs = match$2.fragmentRefs;
  var productVariantId = match$2.productVariantId;
  var match$3 = match$2.product;
  var usedForTesting = match$3.usedForTesting;
  var slug = match$3.slug;
  var sellingPrelovedItems = match$3.sellingPrelovedItems;
  var sellingNewItems = match$3.sellingNewItems;
  var productId = match$3.productId;
  var productName = match$3.name;
  var brandName = match$3.brandName;
  var price = match$2.price;
  var mainImage = match$2.mainImage;
  var compareAtPrice = match$2.compareAtPrice;
  var match$4 = Curry._1(Ui__AppLink.useRouting, undefined);
  var nativeNavigationHandler = match$4.nativeNavigationHandler;
  var routeConfiguration = Curry._3(Ui__AppLink.makeConfiguration, {
        TAG: /* Product */3,
        slug: slug,
        productId: productId,
        variantId: productVariantId
      }, undefined, undefined);
  var testID = "product-variant-search-result-card";
  var element = function (onPress) {
    var tmp = {
      children: null,
      kind: "sharp",
      spacing: xs,
      paddingHorizontal: xs,
      paddingVertical: xs,
      backgroundColor: theme.colors.surface,
      style: Curry._1(styled, styles.container),
      testID: testID,
      testMode: usedForTesting
    };
    if (onPress !== undefined) {
      tmp.onPress = Caml_option.valFromOption(onPress);
    }
    var tmp$1;
    if (mainImage !== undefined) {
      var size = mainImage.size;
      tmp$1 = React.createElement(Ui__Card.$$Image.make, {
            image: {
              src: mainImage.url,
              width: size.width,
              height: size.height
            },
            size: {
              NAME: "fixedHeightAutoWidth",
              VAL: {
                height: imageHeight,
                sizes: imageSizes
              }
            },
            resizeMode: imageResizeMode,
            alt: Belt_Option.getWithDefault(mainImage.alt, productName),
            testID: "product-variant-search-result-image"
          });
    } else {
      tmp$1 = React.createElement(Ui__ImageFallback.make, {
            height: imageHeight
          });
    }
    return React.createElement(Ui__Card.make, tmp, SimpleReact$DecidrIoUtils.whenTrue(Ui__ProductPrelovedOnlyTag.determineIfVisible(sellingNewItems, sellingPrelovedItems), React.createElement(Ui__ProductTag.make, {
                        status: "prelovedOnly",
                        style: Ui__ProductPrelovedOnlyTag.commonStyling
                      })), tmp$1, React.createElement(Ui__Group.make, {
                    spacing: theme.spacing._3,
                    children: null
                  }, SimpleReact$DecidrIoUtils.renderIfSome(compareAtPrice, (function (param) {
                          return React.createElement(Ui__SaleTag.make, {});
                        })), brandName !== undefined ? React.createElement(Ui__Card.Description.make, {
                          text: brandName,
                          size: "b2",
                          fontWeight: "_500",
                          numberOfLines: 1,
                          testID: "brand-name"
                        }) : React.createElement(Ui__BrandNameFallback.make, {
                          size: "b2",
                          fontWeight: "_500"
                        }), React.createElement(Ui__Card.Description.make, {
                        text: productName,
                        size: "b2",
                        style: Curry._1(styled, styles.productName),
                        testID: "product-name"
                      }), React.createElement(Ui__Group.make, {
                        spacing: theme.spacing._2,
                        children: null,
                        orientation: "horizontal"
                      }, React.createElement(Ui__MarkotText.make, {
                            size: "price3",
                            children: React.createElement(App__Money.make, {
                                  money: price.fragmentRefs
                                }),
                            style: Curry._1(styled, styles.price),
                            testID: "price-amount"
                          }), SimpleReact$DecidrIoUtils.renderIfSome(compareAtPrice, (function (param) {
                              return React.createElement(Ui__MarkotText.make, {
                                          size: "price3",
                                          color: theme.colors.info,
                                          children: React.createElement(App__Money.make, {
                                                money: param.fragmentRefs
                                              }),
                                          style: Curry._1(styled, styles.discountedPrice)
                                        });
                            })))));
  };
  if (implementRefurbishedProducts === "off") {
    if (typeof linkingType !== "object") {
      return React.createElement(Ui__AppLink.make, {
                  configuration: routeConfiguration,
                  children: element(Belt_Option.map(nativeNavigationHandler, (function (navHandler, param) {
                              Curry._1(navHandler, routeConfiguration);
                            }))),
                  a11yLabel: "Go to " + productName + "",
                  style: Curry._1(styled, styles.link)
                });
    }
    var linkHandler = linkingType.VAL;
    return element((function (param) {
                  Curry._3(linkHandler, slug, productId, productVariantId);
                }));
  }
  if (typeof linkingType === "object") {
    var linkHandler$1 = linkingType.VAL;
    var onPress = function (param) {
      Curry._3(linkHandler$1, slug, productId, productVariantId);
    };
    return React.createElement(App__ProductVariantSearchResult__Card.make, {
                productVariantSearchResult: fragmentRefs,
                onPress: onPress,
                testID: testID
              });
  }
  var onPress$1 = Belt_Option.map(nativeNavigationHandler, (function (navHandler, param) {
          Curry._1(navHandler, routeConfiguration);
        }));
  var tmp = {
    productVariantSearchResult: fragmentRefs,
    testID: testID
  };
  if (onPress$1 !== undefined) {
    tmp.onPress = Caml_option.valFromOption(onPress$1);
  }
  return React.createElement(Ui__ProductCard__Base.LinkWrapper.make, {
              configuration: routeConfiguration,
              a11yLabel: "Go to " + productName + "",
              children: React.createElement(App__ProductVariantSearchResult__Card.make, tmp)
            });
}

var make = App__Search__ProductVariantResultCard;

export {
  make ,
}
/* match Not a pure module */
