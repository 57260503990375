// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as Ui__Responsive from "../../../../../ui/src/responsive/Ui__Responsive.bs.js";

function useProductCommonStyling(param) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var media = Ui__Responsive.useMedia(undefined)(undefined);
  var cardImageHeight = Curry.app(media.mediaSelect, [
        undefined,
        82,
        72,
        128,
        142,
        178,
        225,
        72,
        undefined
      ]);
  var cardPadding = Curry.app(media.mediaSelect, [
        undefined,
        theme.spacing._4,
        undefined,
        undefined,
        undefined,
        theme.spacing._6,
        theme.spacing._8,
        theme.spacing._4,
        undefined
      ]);
  var cardSpacing = Curry.app(media.mediaSelect, [
        undefined,
        theme.spacing._14,
        theme.spacing._14,
        theme.spacing._16,
        theme.spacing._18,
        theme.spacing._22,
        theme.spacing._28,
        theme.spacing._14,
        undefined
      ]);
  var cardImageSectionSpacing = Curry.app(media.mediaSelect, [
        undefined,
        theme.spacing._4,
        theme.spacing._4,
        theme.spacing._4,
        theme.spacing._4,
        theme.spacing._6,
        theme.spacing._8,
        theme.spacing._4,
        undefined
      ]);
  var cardTextSectionSpacing = Curry.app(media.mediaSelect, [
        undefined,
        theme.spacing._2,
        theme.spacing._4,
        theme.spacing._4,
        theme.spacing._4,
        theme.spacing._6,
        theme.spacing._6,
        theme.spacing._2,
        undefined
      ]);
  return {
          cardImageHeight: cardImageHeight,
          cardPadding: cardPadding,
          cardSpacing: cardSpacing,
          cardImageSectionSpacing: cardImageSectionSpacing,
          cardTextSectionSpacing: cardTextSectionSpacing
        };
}

export {
  useProductCommonStyling ,
}
/* Ui__Styling Not a pure module */
