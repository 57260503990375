// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Ui__LinkButton from "../../linkButton/components/Ui__LinkButton.bs.js";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center",
          flex: 1,
          justifyContent: "center"
        }
      },
      center: {
        TAG: /* Static */0,
        _0: {
          textAlign: "center"
        }
      },
      flexGrow0: {
        TAG: /* Static */0,
        _0: {
          flexGrow: 0
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__EmptyState$Title(Props) {
  var text = Props.text;
  var sizeOpt = Props.size;
  var style = Props.style;
  var size = sizeOpt !== undefined ? sizeOpt : "title1";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, match.theme);
  return React.createElement(Ui__MarkotText.make, {
              size: size,
              children: text,
              style: [
                Caml_option.some(Curry._1(styled, styles.center)),
                style
              ]
            });
}

var Title = {
  make: Ui__EmptyState$Title
};

function Ui__EmptyState$Description(Props) {
  var text = Props.text;
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : "b1";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  return React.createElement(Ui__MarkotText.make, {
              size: size,
              color: theme.colors.onPrimaryShade4,
              children: text,
              style: Curry._1(styled, styles.center)
            });
}

var Description = {
  make: Ui__EmptyState$Description
};

function Ui__EmptyState$Button(Props) {
  var title = Props.title;
  var routeConfiguration = Props.routeConfiguration;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, match.theme);
  return React.createElement(Ui__LinkButton.make, {
              kind: "secondaryOutline",
              routeConfiguration: routeConfiguration,
              title: title,
              buttonElementsWrapperStyle: Curry._1(styled, styles.flexGrow0)
            });
}

var Button = {
  make: Ui__EmptyState$Button
};

function Ui__EmptyState(Props) {
  var children = Props.children;
  var orientation = Props.orientation;
  var spacing = Props.spacing;
  var containerStyle = Props.containerStyle;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "empty-state";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var isWideScreen = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        undefined,
        false,
        undefined
      ]);
  return React.createElement(Ui__Group.make, {
              spacing: Belt_Option.getWithDefault(spacing, theme.sectionSpacing._05),
              children: children,
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles.container)),
                containerStyle
              ],
              orientation: Belt_Option.getWithDefault(orientation, isWideScreen ? "horizontal" : "vertical"),
              testID: testID
            });
}

var make = Ui__EmptyState;

export {
  Title ,
  Description ,
  Button ,
  make ,
}
/* match Not a pure module */
