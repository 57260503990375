// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../group/components/Ui__Group.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotIcon from "../icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__MarkotText from "../text/components/Ui__MarkotText.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          alignSelf: "flex-start"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.secondaryVariant3,
                    borderRadius: theme.roundness._2
                  };
          })
      },
      text: {
        TAG: /* Static */0,
        _0: {
          color: "#282828"
        }
      },
      "icon-wrapper": {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    borderRadius: theme.roundness.circle,
                    alignItems: "center",
                    justifyContent: "center"
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__OpenBoxTag(Props) {
  var textOpt = Props.text;
  var textStyle = Props.textStyle;
  var sizeOpt = Props.size;
  var style = Props.style;
  var textSize = Props.textSize;
  var testIDOpt = Props.testID;
  var text = textOpt !== undefined ? textOpt : "Open Box";
  var size = sizeOpt !== undefined ? sizeOpt : "small";
  var testID = testIDOpt !== undefined ? testIDOpt : "openbox-tag";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = size === "small" ? ({
        paddingHorizontal: theme.spacing._2,
        paddingVertical: theme.spacing._1,
        spacing: theme.spacing._2,
        iconWrapperSize: 16
      }) : ({
        paddingHorizontal: theme.spacing._4,
        paddingVertical: theme.spacing._2,
        spacing: theme.spacing._4,
        iconWrapperSize: 20
      });
  var iconWrapperSize = match$1.iconWrapperSize;
  return React.createElement(Ui__Group.make, {
              spacing: match$1.spacing,
              children: null,
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles.container)),
                {
                  paddingHorizontal: match$1.paddingHorizontal,
                  paddingVertical: match$1.paddingVertical
                },
                style
              ],
              orientation: "horizontal",
              testID: testID
            }, React.createElement(ReactNative.View, {
                  style: [
                    Curry._1(styled, styles["icon-wrapper"]),
                    {
                      height: iconWrapperSize,
                      width: iconWrapperSize
                    }
                  ],
                  children: React.createElement(Ui__MarkotIcon.make, {
                        name: "icon-bag",
                        size: size === "small" ? 10 : 16
                      })
                }), React.createElement(Ui__MarkotText.make, {
                  size: Belt_Option.getWithDefault(textSize, "caption1"),
                  children: text,
                  style: [
                    Caml_option.some(Curry._1(styled, styles.text)),
                    textStyle
                  ]
                }));
}

var make = Ui__OpenBoxTag;

export {
  make ,
}
/* match Not a pure module */
