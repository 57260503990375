// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as ReactNative from "react-native";
import * as Ui__ProfilePageMenuSnippet from "../../profilePageMenuSnippet/components/Ui__ProfilePageMenuSnippet.bs.js";

function Ui__ProfilePageMenu(Props) {
  var items = Props.items;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "profile-page-menu";
  return React.createElement(ReactNative.View, {
              testID: testID,
              children: Belt_Array.mapWithIndex(items, (function (index, item) {
                      return React.createElement(Ui__ProfilePageMenuSnippet.make, {
                                  title: item.title,
                                  hasCaretIcon: item.hasCaretIcon,
                                  routeConfiguration: item.routeConfiguration,
                                  key: String(index)
                                });
                    }))
            });
}

var make = Ui__ProfilePageMenu;

export {
  make ,
}
/* react Not a pure module */
