// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../styling/Ui__Styling.bs.js";
import * as Utils__A11y from "../../../../../utils/src/Utils__A11y.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotText from "../../../text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../../responsive/Ui__Responsive.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as ListUtils$DecidrIoUtils from "@decidr-io/utils/src/listUtils/ListUtils.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as Ui__LoopingContentDisplay from "../../../loopingContentDisplay/components/Ui__LoopingContentDisplay.bs.js";
import * as GroupFactory$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/group/components/GroupFactory.bs.js";

var match = Ui__Styling.describe({
      scrollView: {
        TAG: /* Dual */2,
        _0: {
          flex: 1
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.secondary
                  };
          })
      },
      scrollViewContent: {
        TAG: /* Dual */2,
        _0: {
          flex: 1
        },
        _1: (function (theme) {
            return {
                    paddingHorizontal: theme.spacing._4,
                    paddingVertical: theme.spacing._6
                  };
          })
      },
      container: {
        TAG: /* Static */0,
        _0: {
          flex: 1,
          flexDirection: "row",
          justifyContent: "space-between"
        }
      },
      text: {
        TAG: /* Static */0,
        _0: {
          flexShrink: 0
        }
      },
      boldedText: {
        TAG: /* Dual */2,
        _0: {
          fontWeight: "bold",
          textTransform: "uppercase"
        },
        _1: (function (theme) {
            return {
                    color: theme.colors.primary
                  };
          })
      },
      loopingContentDisplay: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.secondary
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

var elements = ListUtils$DecidrIoUtils.range(5);

function Ui__WebPromoBanner(Props) {
  var slidingPromoBanner = Props.slidingPromoBanner;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = ReactNative.useWindowDimensions();
  var match$2 = React.useState(function () {
        return 300;
      });
  var setTextWidth = match$2[1];
  var contentLoopingDisplayHeight = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        44,
        48,
        undefined,
        52,
        undefined,
        44,
        undefined
      ]);
  var content = React.createElement(ReactNative.View, {
        style: [
          Caml_option.some(Curry._1(styled, styles.container)),
          Bool$DecidrIoUtils.thenSome(slidingPromoBanner === "on", {
                alignItems: "center",
                justifyContent: "space-evenly"
              })
        ],
        testID: "web-promo-banner-content",
        children: Belt_Array.map(slidingPromoBanner === "off" ? elements : ListUtils$DecidrIoUtils.range(Math.floor(match$1.width / (match$2[0] + theme.spacing._4)) | 0), (function (i) {
                return React.createElement(React.Fragment, undefined, React.createElement(Ui__MarkotText.make, {
                                size: "b2",
                                children: "SHOP REFURBISHED AND OVERSTOCKED TODAY!",
                                style: [
                                  Curry._1(styled, styles.text),
                                  Curry._1(styled, styles.boldedText)
                                ],
                                onLayout: (function (e) {
                                    if (slidingPromoBanner === "off" || i !== 0) {
                                      return ;
                                    } else {
                                      return Curry._1(setTextWidth, (function (param) {
                                                    return e.nativeEvent.layout.width;
                                                  }));
                                    }
                                  }),
                                key: String(i)
                              }), SimpleReact$DecidrIoUtils.whenTrue(i !== (elements.length - 1 | 0), React.createElement(GroupFactory$DecidrIoUniversalComponents.Spacer.make, {
                                    size: slidingPromoBanner === "off" ? theme.spacing._8 : theme.spacing._4,
                                    orientation: "horizontal"
                                  })));
              }))
      });
  if (slidingPromoBanner === "off") {
    var tmp = {
      contentContainerStyle: Curry._1(styled, styles.scrollViewContent),
      horizontal: true,
      showsHorizontalScrollIndicator: false,
      style: Curry._1(styled, styles.scrollView),
      testID: "web-promo-banner",
      children: content
    };
    var tmp$1 = Utils__A11y.toSafeA11yRole("complementary");
    if (tmp$1 !== undefined) {
      tmp.accessibilityRole = Caml_option.valFromOption(tmp$1);
    }
    return React.createElement(ReactNative.ScrollView, tmp);
  }
  var tmp$2 = {
    height: contentLoopingDisplayHeight,
    children: content,
    style: Curry._1(styled, styles.loopingContentDisplay),
    testID: "web-promo-banner"
  };
  var tmp$3 = Utils__A11y.toSafeA11yRole("complementary");
  if (tmp$3 !== undefined) {
    tmp$2.accessibilityRole = Caml_option.valFromOption(tmp$3);
  }
  return React.createElement(Ui__LoopingContentDisplay.make, tmp$2);
}

var make = Ui__WebPromoBanner;

export {
  make ,
}
/* match Not a pure module */
