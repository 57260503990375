// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Ui__LinkButton from "../../linkButton/components/Ui__LinkButton.bs.js";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";
import * as Ui__MarkotImage from "../../image/components/Ui__MarkotImage.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center"
        },
        _1: (function (theme) {
            return {
                    padding: theme.sectionSpacing._05
                  };
          })
      },
      center: {
        TAG: /* Static */0,
        _0: {
          textAlign: "center"
        }
      },
      flexGrow0: {
        TAG: /* Static */0,
        _0: {
          flexGrow: 0
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__ErrorState$Title(Props) {
  var text = Props.text;
  var color = Props.color;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "error-state-title";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  return React.createElement(Ui__MarkotText.make, {
              size: "title2",
              color: Belt_Option.getWithDefault(color, theme.colors.primary),
              children: text,
              style: Curry._1(styled, styles.center),
              testID: testID
            });
}

var Title = {
  make: Ui__ErrorState$Title
};

function Ui__ErrorState$Description(Props) {
  var text = Props.text;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "error-state-description";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, match.theme);
  return React.createElement(Ui__MarkotText.make, {
              size: "b1",
              children: text,
              style: Curry._1(styled, styles.center),
              testID: testID
            });
}

var Description = {
  make: Ui__ErrorState$Description
};

function Ui__ErrorState$Button(Props) {
  var title = Props.title;
  var routeConfiguration = Props.routeConfiguration;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "error-state-button";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, match.theme);
  return React.createElement(Ui__LinkButton.make, {
              kind: "secondaryOutline",
              routeConfiguration: routeConfiguration,
              title: title,
              testID: testID,
              buttonElementsWrapperStyle: Curry._1(styled, styles.flexGrow0)
            });
}

var Button = {
  make: Ui__ErrorState$Button
};

function Ui__ErrorState$DisplayImage(Props) {
  var image = Props.image;
  var alt = Props.alt;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "error-state-image";
  return React.createElement(Ui__MarkotImage.make, {
              image: image,
              alt: alt,
              size: {
                NAME: "fixedHeight",
                VAL: {
                  height: 100,
                  aspectRatio: 1
                }
              },
              testID: testID
            });
}

var DisplayImage = {
  make: Ui__ErrorState$DisplayImage
};

function useCommonSpacing(param) {
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  return Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
              undefined,
              undefined,
              undefined,
              theme.spacing._6,
              undefined,
              undefined,
              undefined,
              theme.spacing._4,
              undefined
            ]);
}

function Ui__ErrorState(Props) {
  var children = Props.children;
  var orientationOpt = Props.orientation;
  var spacing = Props.spacing;
  var containerStyle = Props.containerStyle;
  var testIDOpt = Props.testID;
  var orientation = orientationOpt !== undefined ? orientationOpt : "vertical";
  var testID = testIDOpt !== undefined ? testIDOpt : "error-state";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  return React.createElement(Ui__Group.make, {
              spacing: Belt_Option.getWithDefault(spacing, theme.sectionSpacing._05),
              children: children,
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles.container)),
                containerStyle
              ],
              orientation: orientation,
              testID: testID
            });
}

var make = Ui__ErrorState;

export {
  Title ,
  Description ,
  Button ,
  DisplayImage ,
  useCommonSpacing ,
  make ,
}
/* match Not a pure module */
