// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__SearchBox from "../searchBox/components/Ui__SearchBox.bs.js";
import * as Ui__MarkotIcon from "../icons/components/Ui__MarkotIcon.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as GroupFactory$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/group/components/GroupFactory.bs.js";
import * as LoadingIndicator$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/loading/components/loadingIndicator/LoadingIndicator.bs.js";

var match = Ui__Styling.describe({
      searchBox: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.background
                  };
          })
      },
      searchingIndicator: {
        TAG: /* Dual */2,
        _0: {
          position: "absolute"
        },
        _1: (function (theme) {
            return {
                    left: theme.spacing._6
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__GlobalSearchBox(Props) {
  var onChange = Props.onChange;
  var onClear = Props.onClear;
  var searchTerm = Props.searchTerm;
  var searching = Props.searching;
  var loadingIndicatorColor = Props.loadingIndicatorColor;
  var containerStyle = Props.containerStyle;
  var onBlur = Props.onBlur;
  var onFocus = Props.onFocus;
  var onSubmitEditing = Props.onSubmitEditing;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var tmp = {
    onChange: onChange,
    onClear: onClear,
    value: searchTerm,
    containerStyle: [
      Caml_option.some(Curry._1(styled, styles.searchBox)),
      containerStyle
    ],
    right: SimpleReact$DecidrIoUtils.whenTrue(searchTerm !== "", React.createElement(React.Fragment, undefined, React.createElement(GroupFactory$DecidrIoUniversalComponents.Spacer.make, {
                  size: theme.spacing._6,
                  orientation: "horizontal"
                }), React.createElement(Ui__SearchBox.ClearButton.make, {
                  onClear: onClear
                }))),
    left: React.createElement(React.Fragment, undefined, searching ? React.createElement(React.Fragment, undefined, React.createElement(ReactNative.View, {
                    style: {
                      width: 16
                    }
                  }), React.createElement(LoadingIndicator$DecidrIoUniversalComponents.make, {
                    color: Belt_Option.getWithDefault(loadingIndicatorColor, theme.colors.primary),
                    size: "small",
                    style: Curry._1(styled, styles.searchingIndicator)
                  })) : React.createElement(Ui__MarkotIcon.make, {
                name: "magnifying-glass-outline",
                size: 16,
                color: theme.colors.onPrimaryShade4,
                testID: "search-icon"
              }), React.createElement(GroupFactory$DecidrIoUniversalComponents.Spacer.make, {
              size: theme.spacing._6,
              orientation: "horizontal"
            })),
    disabled: false
  };
  if (onBlur !== undefined) {
    tmp.onBlur = Caml_option.valFromOption(onBlur);
  }
  if (onFocus !== undefined) {
    tmp.onFocus = Caml_option.valFromOption(onFocus);
  }
  if (onSubmitEditing !== undefined) {
    tmp.onSubmitEditing = Caml_option.valFromOption(onSubmitEditing);
  }
  return React.createElement(Ui__SearchBox.make, tmp);
}

var make = Ui__GlobalSearchBox;

export {
  make ,
}
/* match Not a pure module */
