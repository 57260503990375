// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as App__Money from "../../../money/components/App__Money.bs.js";
import * as App__Report from "../../../utils/App__Report.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as Ui__MarkotText from "../../../../../ui/src/text/components/Ui__MarkotText.bs.js";
import * as Ui__ProductCard__Base from "../../../../../ui/src/productCard/Ui__ProductCard__Base.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as Ui__ProductCard__Utils from "../../../../../ui/src/productCard/Ui__ProductCard__Utils.bs.js";
import * as AppPrelovedItemSearchResultCard_prelovedItemSearchResult_graphql from "../../../../../relay-codegen/generated/AppPrelovedItemSearchResultCard_prelovedItemSearchResult_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppPrelovedItemSearchResultCard_prelovedItemSearchResult_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppPrelovedItemSearchResultCard_prelovedItemSearchResult_graphql.Internal.convertFragment, data);
}

function App__PrelovedItemSearchResult__Card(Props) {
  var prelovedItemSearchResultFragment = Props.prelovedItemSearchResultFragment;
  var onPress = Props.onPress;
  var testID = Props.testID;
  var match = use(prelovedItemSearchResultFragment);
  var minimumBid = match.minimumBid;
  var highestBid = match.highestBid;
  var condition = match.condition;
  var match$1 = Curry._1(Ui__Styling.useTheme, undefined);
  var sizes = Ui__ProductCard__Utils.useSizes(undefined);
  var image = Belt_Option.map(match.mainImage, (function (param) {
          var size = param.size;
          return {
                  url: param.url,
                  width: size.width,
                  height: size.height,
                  alt: param.alt
                };
        }));
  var moneyFragment = highestBid !== undefined ? Caml_option.some(highestBid.fragmentRefs) : (
      minimumBid !== undefined ? Caml_option.some(minimumBid.fragmentRefs) : undefined
    );
  var conditionElement = React.createElement(Ui__MarkotText.make, {
        size: sizes.secondaryTextSize,
        color: match$1.theme.colors.info,
        children: condition === "WellLoved" ? "Well loved" : (
            condition === "AsGoodAsNew" ? "As good as new" : (
                condition === "Good" ? "Good" : (
                    condition === "NeverUsed" ? "Original condition" : (App__Report.unknownEnumMember(condition), "-")
                  )
              )
          ),
        testID: "preloved-item-search-result-card-condition"
      });
  var tmp = {
    image: image,
    name: match.productName,
    brandName: match.brandName,
    conditionElement: conditionElement,
    startingPriceElement: Belt_Option.map(moneyFragment, (function (moneyFragment) {
            return React.createElement(App__Money.make, {
                        money: moneyFragment
                      });
          })),
    sizes: sizes,
    usedForTesting: match.usedForTesting
  };
  if (onPress !== undefined) {
    tmp.onPress = Caml_option.valFromOption(onPress);
  }
  if (testID !== undefined) {
    tmp.testID = testID;
  }
  return React.createElement(Ui__ProductCard__Base.PrelovedCard.make, tmp);
}

var make = App__PrelovedItemSearchResult__Card;

export {
  make ,
}
/* react Not a pure module */
