// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNativeSvg from "react-native-svg";

function Ui__MarkotLogoWithTitle(Props) {
  var sizeOpt = Props.size;
  var givenColor = Props.color;
  var style = Props.style;
  var id = Props.id;
  var size = sizeOpt !== undefined ? sizeOpt : 100;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var color = Belt_Option.getWithDefault(givenColor, match.theme.colors.primary);
  var tmp = {
    viewBox: "0 0 625 108",
    width: 5.79 * size,
    height: size,
    children: null,
    fill: "none"
  };
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  if (id !== undefined) {
    tmp.id = id;
  }
  return React.createElement(ReactNativeSvg.Svg, tmp, React.createElement(ReactNativeSvg.Path, {
                  d: "M342.41 106.67H328.16V38.3L302 106.67H289.16L263 38.3V106.67H248.71V5.91998H263.82L295.63 89.69L327.3 5.91998H342.41V106.67Z",
                  fill: color
                }), React.createElement(ReactNativeSvg.Path, {
                  d: "M411.5 62.48V106.67H397V94.29C385.48 115.29 347.77 110.84 347.77 86.23C347.73 53 397 72 397 58.45C397 49.67 388.65 46.08 380.88 46.08C373.68 46.08 365.48 47.66 363.61 59.03L350.61 56.58C353.06 39.58 366.88 34.13 380.84 34.27C396.24 34.42 411.5 40.89 411.5 62.48ZM397 75.58V69.1C387.93 75.15 363 68.67 363 86.23C363 104.37 397 103.65 397 75.58Z",
                  fill: color
                }), React.createElement(ReactNativeSvg.Path, {
                  d: "M456.25 33.55V46.22C439.7 46.22 431.64 53.42 431.64 79.75V106.67H417.25V34.67H431.64V48C436.25 37.92 444.16 33.6 455.97 33.6L456.25 33.55Z",
                  fill: color
                }), React.createElement(ReactNativeSvg.Path, {
                  d: "M522.75 34.7L493.39 65.51L524.91 106.51H508.64L484.32 75L475.68 84.06V106.66H461.29V5.91998H475.68V66.66L505.48 34.66L522.75 34.7ZM525.05 106.53V106.67L524.91 106.53H525.05Z",
                  fill: color
                }), React.createElement(ReactNativeSvg.Path, {
                  d: "M552.83 107.53C533.83 107.53 517.42 95.59 517.42 70.69C517.42 45.79 533.83 33.69 552.83 33.84C571.83 33.99 588.24 45.79 588.24 70.69C588.24 95.59 571.83 107.53 552.83 107.53ZM552.83 45.79C541.17 45.64 531.09 53.13 531.09 70.69C531.09 88.25 541.17 95.59 552.83 95.59C564.49 95.59 574.56 88.25 574.56 70.69C574.56 53.13 564.49 45.93 552.83 45.79Z",
                  fill: color
                }), React.createElement(ReactNativeSvg.Path, {
                  d: "M624.94 45.21H613.13V88.21C613.13 92.53 615.44 93.53 618.89 93.53H624.94V106.63H618.89C601.04 106.63 598.89 102.31 598.89 88.21V45.21H587.09V33.84H598.89V20.31L613.14 14.55V33.84H624.95L624.94 45.21Z",
                  fill: color
                }), React.createElement(ReactNativeSvg.Path, {
                  d: "M52.8604 18.45L93.2204 99.45H12.4903L52.8604 18.45V18.45ZM52.8604 2.29999L0.860352 106.67H104.86L52.8604 2.29999Z",
                  fill: color
                }), React.createElement(ReactNativeSvg.Path, {
                  d: "M138.68 8.00001C146.893 8.01325 154.765 11.2852 160.568 17.0974C166.37 22.9095 169.63 30.787 169.63 39V99.5H107.72V39C107.72 30.7783 110.986 22.8933 116.799 17.0797C122.613 11.2661 130.498 8.00001 138.72 8.00001H138.68ZM138.72 0.800009C133.702 0.794746 128.732 1.77922 124.095 3.69709C119.458 5.61495 115.245 8.42854 111.696 11.9768C108.148 15.525 105.334 19.7383 103.417 24.3753C101.499 29.0123 100.514 33.982 100.52 39V106.7H176.83V39C176.83 28.8811 172.811 19.1764 165.656 12.0203C158.502 4.86424 148.798 0.842654 138.68 0.840002L138.72 0.800009Z",
                  fill: color
                }));
}

var make = Ui__MarkotLogoWithTitle;

export {
  make ,
}
/* react Not a pure module */
