// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Reanimated from "rescript-reanimated/src/Reanimated.bs.js";
import * as Ui__Portal from "../../portal/Ui__Portal.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as ReactNativeReanimated from "react-native-reanimated";
import * as ReactNativeSafeAreaContext from "react-native-safe-area-context";

var match = Ui__Styling.describe({
      wrapper: {
        TAG: /* Static */0,
        _0: {
          flex: 1,
          zIndex: 2
        }
      },
      backdrop: {
        TAG: /* Static */0,
        _0: {
          backgroundColor: "black"
        }
      },
      container: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.surface,
                    padding: theme.spacing._6
                  };
          })
      },
      absoluteFillObject: {
        TAG: /* Static */0,
        _0: ReactNative.StyleSheet.absoluteFillObject
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__DrawerSheet(Props) {
  var isOpened = Props.isOpened;
  var onClose = Props.onClose;
  var children = Props.children;
  var positionOpt = Props.position;
  var containerStyle = Props.containerStyle;
  var wrapperStyle = Props.wrapperStyle;
  var includeSafeAreaInsetsOpt = Props.includeSafeAreaInsets;
  var drawerWidthOpt = Props.drawerWidth;
  var portalHostName = Props.portalHostName;
  var position = positionOpt !== undefined ? positionOpt : "left";
  var includeSafeAreaInsets = includeSafeAreaInsetsOpt !== undefined ? includeSafeAreaInsetsOpt : true;
  var drawerWidth = drawerWidthOpt !== undefined ? drawerWidthOpt : 512;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, match.theme);
  var match$1 = ReactNativeSafeAreaContext.useSafeAreaInsets();
  var match$2 = ReactNative.useWindowDimensions();
  var width = match$2.width;
  var startPosition = position === "left" ? -drawerWidth : width;
  var endPosition = position === "left" ? 0 : width - drawerWidth;
  var transitionX = ReactNativeReanimated.useSharedValue(0);
  React.useEffect((function () {
          transitionX.value = isOpened ? Reanimated.withTiming(1, Reanimated.Timing.makeConfig(300, undefined, undefined), undefined, undefined) : Reanimated.withTiming(0, Reanimated.Timing.makeConfig(300, undefined, undefined), undefined, undefined);
        }), [
        transitionX,
        isOpened
      ]);
  var drawerStyle = ReactNativeReanimated.useAnimatedStyle(function () {
        var translateX = ReactNativeReanimated.interpolate(transitionX.value, [
              0,
              1
            ], [
              startPosition,
              endPosition
            ], undefined);
        return {
                transform: [{
                    translateX: translateX
                  }]
              };
      });
  var backdropStyle = ReactNativeReanimated.useAnimatedStyle(function () {
        var opacity = ReactNativeReanimated.interpolate(transitionX.value, [
              0,
              1
            ], [
              0,
              0.7
            ], undefined);
        return {
                opacity: opacity
              };
      });
  var safeAreaPadding = {
    paddingBottom: match$1.bottom,
    paddingLeft: match$1.left,
    paddingRight: match$1.right,
    paddingTop: match$1.top
  };
  var tmp = {
    children: React.createElement(ReactNative.View, {
          pointerEvents: (function () {
                switch (isOpened ? "auto" : "none") {
                  case "auto" :
                      return "auto";
                  case "none" :
                      return "none";
                  case "boxNone" :
                      return "box-none";
                  case "boxOnly" :
                      return "box-only";
                  
                }
              })(),
          style: [
            Caml_option.some(Curry._1(styled, styles.wrapper)),
            Caml_option.some(Curry._1(styled, styles.absoluteFillObject)),
            wrapperStyle
          ],
          children: null
        }, React.createElement(ReactNative.TouchableWithoutFeedback, {
              onPress: (function (param) {
                  Curry._1(onClose, undefined);
                }),
              children: React.createElement(Reanimated.View.make, {
                    style: [
                      Curry._1(styled, styles.backdrop),
                      Curry._1(styled, styles.absoluteFillObject),
                      backdropStyle
                    ],
                    pointerEvents: (function () {
                          switch (isOpened ? "auto" : "none") {
                            case "auto" :
                                return "auto";
                            case "none" :
                                return "none";
                            case "boxNone" :
                                return "box-none";
                            case "boxOnly" :
                                return "box-only";
                            
                          }
                        })()
                  })
            }), React.createElement(Reanimated.View.make, {
              style: [
                Caml_option.some(Curry._1(styled, styles.container)),
                Caml_option.some(Curry._1(styled, styles.absoluteFillObject)),
                {
                  width: drawerWidth
                },
                includeSafeAreaInsets ? Caml_option.some(safeAreaPadding) : undefined,
                containerStyle,
                Caml_option.some(drawerStyle)
              ],
              children: children
            }))
  };
  if (portalHostName !== undefined) {
    tmp.hostName = portalHostName;
  }
  return React.createElement(Ui__Portal.make, tmp);
}

var DrawerPortal;

var make = Ui__DrawerSheet;

export {
  DrawerPortal ,
  make ,
}
/* match Not a pure module */
