// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as App__Money from "../../../money/components/App__Money.bs.js";
import * as Belt_Float from "rescript/lib/es6/belt_Float.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactRelay from "react-relay";
import * as Js_null_undefined from "rescript/lib/es6/js_null_undefined.js";
import * as Ui__ProductCard__Base from "../../../../../ui/src/productCard/Ui__ProductCard__Base.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as Ui__ProductCard__Utils from "../../../../../ui/src/productCard/Ui__ProductCard__Utils.bs.js";
import * as AppProductVariantSearchResultCard_productVariantSearchResult_graphql from "../../../../../relay-codegen/generated/AppProductVariantSearchResultCard_productVariantSearchResult_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppProductVariantSearchResultCard_productVariantSearchResult_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppProductVariantSearchResultCard_productVariantSearchResult_graphql.Internal.convertFragment, data);
}

function useOpt(opt_fRef) {
  var fr = opt_fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(opt_fRef)) : undefined;
  var nullableFragmentData = ReactRelay.useFragment(AppProductVariantSearchResultCard_productVariantSearchResult_graphql.node, fr !== undefined ? Js_null_undefined.fromOption(Caml_option.some(Caml_option.valFromOption(fr))) : null);
  var data = (nullableFragmentData == null) ? undefined : Caml_option.some(nullableFragmentData);
  return RescriptRelay_Internal.internal_useConvertedValue((function (rawFragment) {
                if (rawFragment !== undefined) {
                  return AppProductVariantSearchResultCard_productVariantSearchResult_graphql.Internal.convertFragment(rawFragment);
                }
                
              }), data);
}

var ProductVariantSearchResultFragment = {
  Types: undefined,
  Operation: undefined,
  use: use,
  useOpt: useOpt
};

function App__ProductVariantSearchResult__Card(Props) {
  var productVariantSearchResult = Props.productVariantSearchResult;
  var onPress = Props.onPress;
  var testID = Props.testID;
  var match = use(productVariantSearchResult);
  var match$1 = match.product;
  var usedForTesting = match$1.usedForTesting;
  var sellingPrelovedItems = match$1.sellingPrelovedItems;
  var refurbished = match$1.refurbished;
  var overstocked = match$1.overstocked;
  var openbox = match$1.openbox;
  var name = match$1.name;
  var brandName = match$1.brandName;
  var price = match.price;
  console.log("UKUMA", use(productVariantSearchResult));
  var sizes = Ui__ProductCard__Utils.useSizes(undefined);
  var image = Belt_Option.map(match.mainImage, (function (param) {
          var size = param.size;
          return {
                  url: param.url,
                  width: size.width,
                  height: size.height,
                  alt: param.alt
                };
        }));
  var priceElement = React.createElement(App__Money.make, {
        money: price.fragmentRefs,
        testID: "price-amount"
      });
  var compareAtPriceElement = Belt_Option.map(match.compareAtPrice, (function (param) {
          return React.createElement(App__Money.make, {
                      money: param.fragmentRefs
                    });
        }));
  if (refurbished !== undefined) {
    var tmp = {
      image: image,
      name: name,
      brandName: brandName,
      sizes: sizes,
      priceElement: priceElement,
      savings: Belt_Option.flatMap(refurbished.originalPrice, (function (param) {
              var match = Belt_Float.fromString(param.amount);
              var match$1 = Belt_Float.fromString(price.amount);
              if (match !== undefined && match$1 !== undefined) {
                return "$" + (match - match$1).toFixed(2) + "";
              }
              
            })),
      usedForTesting: usedForTesting
    };
    if (onPress !== undefined) {
      tmp.onPress = Caml_option.valFromOption(onPress);
    }
    if (testID !== undefined) {
      tmp.testID = testID;
    }
    return React.createElement(Ui__ProductCard__Base.RefurbishedCard.make, tmp);
  }
  if (overstocked !== undefined) {
    var tmp$1 = {
      image: image,
      name: name,
      brandName: brandName,
      sizes: sizes,
      priceElement: priceElement,
      savings: Belt_Option.flatMap(overstocked.originalPrice, (function (param) {
              var match = Belt_Float.fromString(param.amount);
              var match$1 = Belt_Float.fromString(price.amount);
              if (match !== undefined && match$1 !== undefined) {
                return "$" + (match - match$1).toFixed(2) + "";
              }
              
            })),
      usedForTesting: usedForTesting
    };
    if (onPress !== undefined) {
      tmp$1.onPress = Caml_option.valFromOption(onPress);
    }
    if (testID !== undefined) {
      tmp$1.testID = testID;
    }
    return React.createElement(Ui__ProductCard__Base.OverstockedCard.make, tmp$1);
  }
  if (openbox !== undefined) {
    var tmp$2 = {
      image: image,
      name: name,
      brandName: brandName,
      sizes: sizes,
      priceElement: priceElement,
      savings: Belt_Option.flatMap(openbox.originalPrice, (function (param) {
              var match = Belt_Float.fromString(param.amount);
              var match$1 = Belt_Float.fromString(price.amount);
              if (match !== undefined && match$1 !== undefined) {
                return "$" + (match - match$1).toFixed(2) + "";
              }
              
            })),
      usedForTesting: usedForTesting
    };
    if (onPress !== undefined) {
      tmp$2.onPress = Caml_option.valFromOption(onPress);
    }
    if (testID !== undefined) {
      tmp$2.testID = testID;
    }
    return React.createElement(Ui__ProductCard__Base.OpenBoxCard.make, tmp$2);
  }
  if (match$1.sellingNewItems) {
    if (sellingPrelovedItems) {
      var tmp$3 = {
        image: image,
        name: name,
        brandName: brandName,
        priceElement: priceElement,
        compareAtPriceElement: compareAtPriceElement,
        isPriceRange: false,
        prelovedFromPriceElement: undefined,
        sizes: sizes,
        usedForTesting: usedForTesting
      };
      if (onPress !== undefined) {
        tmp$3.onPress = Caml_option.valFromOption(onPress);
      }
      if (testID !== undefined) {
        tmp$3.testID = testID;
      }
      return React.createElement(Ui__ProductCard__Base.NewAndPrelovedCard.make, tmp$3);
    }
    
  } else if (sellingPrelovedItems) {
    var tmp$4 = {
      image: image,
      name: name,
      brandName: brandName,
      sizes: sizes,
      priceElement: priceElement,
      compareAtPriceElement: compareAtPriceElement,
      isPriceRange: false,
      usedForTesting: usedForTesting
    };
    if (onPress !== undefined) {
      tmp$4.onPress = Caml_option.valFromOption(onPress);
    }
    if (testID !== undefined) {
      tmp$4.testID = testID;
    }
    return React.createElement(Ui__ProductCard__Base.PrelovedOnlyCard.make, tmp$4);
  }
  var tmp$5 = {
    image: image,
    name: name,
    brandName: brandName,
    priceElement: priceElement,
    compareAtPriceElement: compareAtPriceElement,
    isPriceRange: false,
    sizes: sizes,
    usedForTesting: usedForTesting
  };
  if (onPress !== undefined) {
    tmp$5.onPress = Caml_option.valFromOption(onPress);
  }
  if (testID !== undefined) {
    tmp$5.testID = testID;
  }
  return React.createElement(Ui__ProductCard__Base.NewOnlyCard.make, tmp$5);
}

var make = App__ProductVariantSearchResult__Card;

export {
  ProductVariantSearchResultFragment ,
  make ,
}
/* react Not a pure module */
