// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Tabs from "../../../../../packages/ui/src/tabs/components/Ui__Tabs.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Ui__Portal from "../../../../../packages/ui/src/portal/Ui__Portal.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../../../packages/ui/src/styling/Ui__Styling.bs.js";
import * as Router from "next/router";
import * as ReactNative from "react-native";
import * as App__Features from "../../../../../packages/app/src/features/App__Features.bs.js";
import * as Ui__Responsive from "../../../../../packages/ui/src/responsive/Ui__Responsive.bs.js";
import * as Ui__MainHeaderBar from "../../../../../packages/ui/src/mainHeaderBar/components/Ui__MainHeaderBar.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as Ui__WebPromoBanner from "../../../../../packages/ui/src/banners/components/webPromoBanner/Ui__WebPromoBanner.bs.js";
import * as Web__MainHeaderBar from "../../mainHeaderBar/components/Web__MainHeaderBar.bs.js";
import * as Ui__DiscoverHeaderBar from "../../../../../packages/ui/src/discoverHeaderBar/Ui__DiscoverHeaderBar.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as ReactNativeSafeAreaContext from "react-native-safe-area-context";

function Web__PageShell$WithPromoBanner(Props) {
  var showPromoBanner = Props.showPromoBanner;
  var style = Props.style;
  var children = Props.children;
  if (showPromoBanner === "on") {
    return React.createElement(ReactNative.View, {
                style: style,
                children: null
              }, React.createElement(Ui__WebPromoBanner.make, {
                    slidingPromoBanner: App__Features.slidingPromoBanner
                  }), children);
  } else {
    return children;
  }
}

var match = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      mainHeaderBarWrapper: {
        TAG: /* Static */0,
        _0: {
          position: "fixed",
          top: -1,
          width: Ui__Styling.pct(100),
          zIndex: 2
        }
      },
      mainHeaderBar: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    paddingBottom: theme.spacing._12,
                    paddingTop: theme.spacing._8
                  };
          })
      },
      bottomTabBar: {
        TAG: /* Dual */2,
        _0: {
          bottom: 0,
          position: "fixed",
          width: Ui__Styling.pct(100)
        },
        _1: (function (theme) {
            return {
                    paddingBottom: theme.spacing._2,
                    paddingTop: theme.spacing._1
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Web__PageShell(Props) {
  var children = Props.children;
  var activeRouteIndexOpt = Props.activeRouteIndex;
  var style = Props.style;
  var isDiscoverPageOpt = Props.isDiscoverPage;
  var showPromoBannerOpt = Props.showPromoBanner;
  var activeRouteIndex = activeRouteIndexOpt !== undefined ? activeRouteIndexOpt : 0;
  var isDiscoverPage = isDiscoverPageOpt !== undefined ? isDiscoverPageOpt : false;
  var showPromoBanner = showPromoBannerOpt !== undefined ? showPromoBannerOpt : "off";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var isWideScreen = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        true,
        undefined,
        false,
        undefined
      ]);
  var router = Router.useRouter();
  var handleSearchSubmit = function (searchTerm) {
    router.push("/search" + (
          searchTerm === "" ? "" : "?term=" + searchTerm
        ) + "");
  };
  return React.createElement(ReactNativeSafeAreaContext.SafeAreaProvider, {
              children: React.createElement(ReactNative.View, {
                    style: [
                      Caml_option.some(Curry._1(styled, styles.container)),
                      Bool$DecidrIoUtils.thenSome(!isWideScreen, {
                            paddingBottom: theme.spacing._30,
                            paddingTop: showPromoBanner === "on" ? theme.spacing._50 : theme.spacing._30
                          }),
                      style
                    ],
                    children: null
                  }, isWideScreen ? React.createElement(React.Fragment, undefined, React.createElement(Web__MainHeaderBar.make, {
                              activeRouteIndex: activeRouteIndex,
                              onSearchSubmit: handleSearchSubmit,
                              routerPush: (function (param) {
                                  router.push(param);
                                }),
                              showPromoBanner: showPromoBanner
                            }), React.createElement(Ui__Portal.Host.make, {
                              name: "BrowseDropdownHost"
                            }), React.createElement(Ui__Portal.Host.make, {
                              name: "SearchOverlayHost"
                            })) : React.createElement(Web__PageShell$WithPromoBanner, {
                          showPromoBanner: showPromoBanner,
                          style: Curry._1(styled, styles.mainHeaderBarWrapper),
                          children: isDiscoverPage ? React.createElement(Ui__DiscoverHeaderBar.make, {
                                  containerStyle: [
                                    Bool$DecidrIoUtils.thenSome(showPromoBanner !== "on", Curry._1(styled, styles.mainHeaderBarWrapper)),
                                    Caml_option.some(Curry._1(styled, styles.mainHeaderBar))
                                  ],
                                  onSearchPress: (function (param) {
                                      router.push("/search");
                                    })
                                }) : React.createElement(Ui__MainHeaderBar.make, {
                                  containerStyle: [
                                    Bool$DecidrIoUtils.thenSome(showPromoBanner !== "on", Curry._1(styled, styles.mainHeaderBarWrapper)),
                                    Caml_option.some(Curry._1(styled, styles.mainHeaderBar))
                                  ],
                                  headerLeftKind: "homePage",
                                  activeIcon: "none",
                                  onPress: (function (param) {
                                      router.push("/search");
                                    })
                                })
                        }), children, SimpleReact$DecidrIoUtils.whenTrue(!isWideScreen, React.createElement(Ui__Tabs.BottomTabBar.make, {
                            routes: Belt_Array.keepMap([
                                  {
                                    name: "Discover",
                                    markotIconName: "markot",
                                    behavior: {
                                      NAME: "Link",
                                      VAL: {
                                        route: /* Discover */6,
                                        a11yLabel: "Go to discover page"
                                      }
                                    }
                                  },
                                  {
                                    name: "Buy",
                                    markotIconName: "shopping-cart",
                                    behavior: {
                                      NAME: "Link",
                                      VAL: {
                                        route: /* Browse */7,
                                        a11yLabel: "Go to browse page"
                                      }
                                    }
                                  },
                                  {
                                    name: "Sell",
                                    markotIconName: "tag",
                                    behavior: {
                                      NAME: "Link",
                                      VAL: {
                                        route: /* Sell */11,
                                        a11yLabel: "Go to sell page"
                                      }
                                    }
                                  },
                                  {
                                    name: "Profile",
                                    markotIconName: "user",
                                    behavior: {
                                      NAME: "Link",
                                      VAL: {
                                        route: /* User */20,
                                        a11yLabel: "Go to your profile"
                                      }
                                    }
                                  },
                                  Bool$DecidrIoUtils.thenSome(false, {
                                        name: "Bundles",
                                        markotIconName: "bundles",
                                        behavior: {
                                          NAME: "Link",
                                          VAL: {
                                            route: /* Bundles */14,
                                            a11yLabel: "Go to bundles page"
                                          }
                                        }
                                      })
                                ], (function (x) {
                                    return x;
                                  })),
                            activeRouteIndex: activeRouteIndex,
                            containerStyle: Curry._1(styled, styles.bottomTabBar)
                          })))
            });
}

var make = Web__PageShell;

export {
  make ,
}
/* match Not a pure module */
