// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Ui__MarkotIcon from "../../icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__BadgeCommonStyling from "../../badge/components/Ui__BadgeCommonStyling.bs.js";
import * as Tag$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/tag/components/Tag.bs.js";

function statusFromString(status) {
  switch (status) {
    case "new" :
        return "new";
    case "preloved" :
        return "preloved";
    case "preloved-available" :
        return "prelovedAvailable";
    case "preloved-only" :
        return "prelovedOnly";
    default:
      return ;
  }
}

function Ui__ProductTag(Props) {
  var status = Props.status;
  var subscribedOpt = Props.subscribed;
  var style = Props.style;
  var testIDOpt = Props.testID;
  var subscribed = subscribedOpt !== undefined ? subscribedOpt : false;
  var testID = testIDOpt !== undefined ? testIDOpt : "product-card-tag";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = Ui__BadgeCommonStyling.useCommonStyling(undefined);
  var iconSize = match$1.iconSize;
  var match$2 = status === "new" ? [
      theme.colors.secondary,
      theme.colors.onPrimaryShade4,
      "New"
    ] : (
      status === "prelovedAvailable" ? [
          theme.colors.primaryShade4,
          theme.colors.onPrimaryShade4,
          "Preloved available"
        ] : (
          status === "prelovedOnly" ? [
              theme.colors.secondaryVariant3,
              theme.colors.primary,
              "Preloved only"
            ] : [
              theme.colors.secondaryVariant3,
              theme.colors.onPrimaryShade4,
              "Preloved"
            ]
        )
    );
  var tmp = {
    text: match$2[2],
    backgroundColor: match$2[0],
    textColor: match$2[1],
    textStyle: match$1.textStyle,
    containerStyle: [
      Caml_option.some(match$1.containerStyle),
      style
    ],
    testID: testID
  };
  var tmp$1 = subscribed ? (function (param) {
        return React.createElement(Ui__MarkotIcon.make, {
                    name: "bell-fill",
                    size: iconSize,
                    color: theme.colors.primaryShade1,
                    testID: "" + testID + "-icon"
                  });
      }) : undefined;
  if (tmp$1 !== undefined) {
    tmp.right = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Tag$DecidrIoUniversalComponents.make, tmp);
}

var make = Ui__ProductTag;

export {
  statusFromString ,
  make ,
}
/* react Not a pure module */
