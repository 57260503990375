// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "../../routing/Ui__AppLink.bs.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotIcon from "../../icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";
import * as Ui__Expo__WebBrowser from "../../expo/webBrowser/Ui__Expo__WebBrowser.bs.js";
import * as Ignored$DecidrIoUtils from "@decidr-io/utils/src/functions/Ignored.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as Box$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/box/components/Box.bs.js";
import * as Divider$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/divider/components/Divider.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "space-between"
        },
        _1: (function (theme) {
            return {
                    paddingVertical: theme.spacing._10
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__ProfilePageMenuSnippet$Snippet(Props) {
  var title = Props.title;
  var onPress = Props.onPress;
  var hasCaretIcon = Props.hasCaretIcon;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "profile-page-menu-snippet";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, match.theme);
  var iconSize = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        14,
        16,
        18,
        undefined,
        20,
        22,
        14,
        undefined
      ]);
  var tmp = {
    children: null
  };
  var tmp$1 = Belt_Option.map(onPress, (function (onPress) {
          return {
                  NAME: "Pressable",
                  VAL: Box$DecidrIoUniversalComponents.makePressableProps(undefined, undefined, undefined, (function (param) {
                          Curry._1(onPress, undefined);
                        }), undefined, undefined, undefined)
                };
        }));
  if (tmp$1 !== undefined) {
    tmp.kind = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Box$DecidrIoUniversalComponents.make, tmp, React.createElement(ReactNative.View, {
                  style: Curry._1(styled, styles.container),
                  testID: testID,
                  children: null
                }, React.createElement(Ui__MarkotText.make, {
                      size: "b1",
                      children: title
                    }), SimpleReact$DecidrIoUtils.whenTrue(hasCaretIcon, React.createElement(Ui__MarkotIcon.make, {
                          name: "chevron-right",
                          size: iconSize,
                          testID: "" + testID + "-icon"
                        }))), React.createElement(Divider$DecidrIoUniversalComponents.make, {}));
}

function Ui__ProfilePageMenuSnippet(Props) {
  var title = Props.title;
  var hasCaretIcon = Props.hasCaretIcon;
  var routeConfiguration = Props.routeConfiguration;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "profile-page-menu-snippet";
  var match = Curry._1(Ui__AppLink.useRouting, undefined);
  var nativeNavigationHandler = match.nativeNavigationHandler;
  if (nativeNavigationHandler !== undefined) {
    return React.createElement(Ui__ProfilePageMenuSnippet$Snippet, {
                title: title,
                onPress: (function (param) {
                    var route = routeConfiguration.route;
                    if (typeof route === "number" || route.TAG !== /* ExternalRoute */12) {
                      return Curry._1(nativeNavigationHandler, Curry._3(Ui__AppLink.makeConfiguration, route, undefined, undefined));
                    } else {
                      return Ignored$DecidrIoUtils.promiseWithPayload(Ui__Expo__WebBrowser.openBrowserAsync(route.url, undefined, undefined));
                    }
                  }),
                hasCaretIcon: hasCaretIcon,
                testID: testID
              });
  } else {
    return React.createElement(Ui__AppLink.make, {
                configuration: Curry._3(Ui__AppLink.makeConfiguration, routeConfiguration.route, undefined, undefined),
                children: React.createElement(Ui__ProfilePageMenuSnippet$Snippet, {
                      title: title,
                      hasCaretIcon: hasCaretIcon,
                      testID: testID
                    }),
                a11yLabel: routeConfiguration.a11yLabel
              });
  }
}

var make = Ui__ProfilePageMenuSnippet;

export {
  make ,
}
/* match Not a pure module */
