// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../ui/src/group/components/Ui__Group.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../ui/src/styling/Ui__Styling.bs.js";
import * as Ui__Checkbox from "../../../ui/src/checkbox/Ui__Checkbox.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotIcon from "../../../ui/src/icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__MarkotText from "../../../ui/src/text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../../ui/src/responsive/Ui__Responsive.bs.js";
import * as Ui__PressableText from "../../../ui/src/pressableText/components/Ui__PressableText.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as ReactNativeSafeAreaContext from "react-native-safe-area-context";
import * as Box$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/box/components/Box.bs.js";
import * as FeatureFlags__AppSpecificConfig from "../../../feature-flags/src/local/FeatureFlags__AppSpecificConfig.bs.js";
import * as FeatureFlags__LocalFeatureFlags from "../../../feature-flags/src/local/FeatureFlags__LocalFeatureFlags.bs.js";
import * as Reloader$DecidrIoReactNativeHelpers from "@decidr-io/react-native-helpers/src/reloader/Reloader.bs.js";
import * as DecidrFlatList$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/list/components/DecidrFlatList.bs.js";

var match = Ui__Styling.describe({
      opener: {
        TAG: /* Static */0,
        _0: {
          backgroundColor: "transparent",
          height: 50,
          width: 50
        }
      },
      title: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "space-between"
        },
        _1: (function (theme) {
            return {
                    padding: theme.sectionSpacing._05
                  };
          })
      },
      list: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      listContent: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    padding: theme.sectionSpacing._05
                  };
          })
      },
      listItem: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "space-between"
        },
        _1: (function (theme) {
            return {
                    paddingLeft: theme.spacing._6,
                    paddingRight: theme.spacing._2,
                    paddingVertical: theme.spacing._6
                  };
          })
      },
      listItemSection: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      itemTitle: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "500"
        }
      },
      reloadInfo: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center"
        },
        _1: (function (theme) {
            return {
                    padding: theme.sectionSpacing._05
                  };
          })
      },
      infoText: {
        TAG: /* Static */0,
        _0: {
          textAlign: "center"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

var widthLimiterStyle = Ui__Responsive.widthLimiterStyle(true, undefined, undefined);

function App__FeatureFlagsPage(Props) {
  var openerBackgroundColorOpt = Props.openerBackgroundColor;
  var openerBackgroundColor = openerBackgroundColorOpt !== undefined ? openerBackgroundColorOpt : "transparent";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = FeatureFlags__LocalFeatureFlags.useFeatureFlagsEnvironment(undefined);
  var setFeatureFlag = match$1[1];
  var featureFlags = match$1[0];
  var match$2 = ReactNativeSafeAreaContext.useSafeAreaInsets();
  var match$3 = React.useState(function () {
        return false;
      });
  var setVisible = match$3[1];
  var match$4 = React.useState(function () {
        return 0;
      });
  var setPressCount = match$4[1];
  var pressCount = match$4[0];
  var closeModal = function (param) {
    Curry._1(setVisible, (function (param) {
            return false;
          }));
  };
  var showReloadAppButtonOnFFPage = FeatureFlags__LocalFeatureFlags.use("showReloadAppButtonOnFFPage");
  return React.createElement(React.Fragment, undefined, React.createElement(ReactNative.TouchableWithoutFeedback, {
                  onPress: (function (param) {
                      if (pressCount > 9) {
                        Curry._1(setVisible, (function (param) {
                                return true;
                              }));
                        return Curry._1(setPressCount, (function (param) {
                                      return 0;
                                    }));
                      } else {
                        return Curry._1(setPressCount, (function (prev) {
                                      return prev + 1 | 0;
                                    }));
                      }
                    }),
                  children: React.createElement(ReactNative.View, {
                        style: [
                          Caml_option.some(Curry._1(styled, styles.opener)),
                          {
                            backgroundColor: openerBackgroundColor
                          },
                          Bool$DecidrIoUtils.thenSome(ReactNative.Platform.OS === "web", {
                                outline: "none"
                              })
                        ]
                      })
                }), React.createElement(ReactNative.Modal, {
                  onDismiss: closeModal,
                  visible: match$3[0],
                  children: null
                }, React.createElement(Ui__Group.make, {
                      children: null,
                      containerStyle: [
                        Curry._1(styled, styles.title),
                        {
                          paddingTop: match$2.top
                        },
                        widthLimiterStyle
                      ],
                      orientation: "horizontal"
                    }, React.createElement(Ui__MarkotText.make, {
                          size: "title2",
                          children: "Feature flags"
                        }), React.createElement(ReactNative.TouchableOpacity, {
                          style: {
                            alignSelf: "flex-end"
                          },
                          onPress: (function (param) {
                              Curry._1(setVisible, (function (param) {
                                      return false;
                                    }));
                            }),
                          testID: "close-ff-modal-button",
                          children: React.createElement(Ui__MarkotIcon.make, {
                                name: "x-mark",
                                size: 20
                              })
                        })), SimpleReact$DecidrIoUtils.whenTrue(showReloadAppButtonOnFFPage === "on", React.createElement(Ui__Group.make, {
                          children: null,
                          containerStyle: [
                            Curry._1(styled, styles.reloadInfo),
                            widthLimiterStyle
                          ]
                        }, React.createElement(Ui__MarkotText.make, {
                              size: "b1",
                              children: "The app should be reloaded after changing feature flags configuration",
                              style: [
                                Curry._1(styled, styles.itemTitle),
                                Curry._1(styled, styles.infoText)
                              ]
                            }), React.createElement(Ui__PressableText.make, {
                              title: "RELOAD APP",
                              behavior: {
                                NAME: "Button",
                                VAL: (function (param) {
                                    Reloader$DecidrIoReactNativeHelpers.reloadApp(undefined);
                                  })
                              },
                              underlined: true,
                              fontWeight: "bold"
                            }))), React.createElement(DecidrFlatList$DecidrIoUniversalComponents.make, {
                      data: Belt_Array.map(FeatureFlags__AppSpecificConfig.displayFeatureFlags, (function (flag) {
                              return {
                                      featureFlag: flag,
                                      info: Belt_Option.getWithDefault(Curry._2(FeatureFlags__AppSpecificConfig.FeatureFlagsMap.get, featureFlags, flag.key), {
                                            status: "off",
                                            disabled: false
                                          })
                                    };
                            })),
                      itemSpacing: theme.spacing._6,
                      renderItem: (function (param) {
                          var match = param.item;
                          var match$1 = match.info;
                          var disabled = match$1.disabled;
                          var status = match$1.status;
                          var match$2 = match.featureFlag;
                          var key = match$2.key;
                          return React.createElement(Ui__Group.make, {
                                      kind: {
                                        NAME: "Pressable",
                                        VAL: Box$DecidrIoUniversalComponents.makePressableProps(disabled, undefined, undefined, (function (param) {
                                                if (!disabled) {
                                                  return Curry._2(setFeatureFlag, key, status === "off" ? "on" : "off");
                                                }
                                                
                                              }), undefined, undefined, undefined)
                                      },
                                      children: null,
                                      containerStyle: [
                                        Curry._1(styled, styles.listItem),
                                        {
                                          backgroundColor: disabled ? theme.colors.primaryShade3 : (
                                              status === "on" ? theme.colors.infoShade4 : "transparent"
                                            )
                                        }
                                      ],
                                      orientation: "horizontal",
                                      testID: key
                                    }, React.createElement(Ui__Group.make, {
                                          children: null,
                                          containerStyle: Curry._1(styled, styles.listItemSection)
                                        }, React.createElement(Ui__MarkotText.make, {
                                              size: "b1",
                                              children: match$2.title,
                                              style: Curry._1(styled, styles.itemTitle)
                                            }), React.createElement(Ui__MarkotText.make, {
                                              size: "caption1",
                                              children: match$2.description
                                            })), React.createElement(Ui__Checkbox.make, {
                                          status: status === "on" ? "checked" : "unchecked",
                                          uncheckedColor: theme.colors.primary,
                                          color: theme.colors.primary
                                        }));
                        }),
                      keyExtractor: (function (param, _index) {
                          return param.featureFlag.key;
                        }),
                      contentContainerStyle: [
                        Curry._1(styled, styles.listContent),
                        widthLimiterStyle
                      ],
                      style: Curry._1(styled, styles.list),
                      testID: "feature-flags-list"
                    })));
}

var make = App__FeatureFlagsPage;

export {
  styles ,
  stylesheet ,
  widthLimiterStyle ,
  make ,
}
/* match Not a pure module */
