// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Utils__StringUtils from "../../../../utils/src/Utils__StringUtils.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          alignItems: "baseline"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__LabeledText(Props) {
  var label = Props.label;
  var text = Props.text;
  var labelColor = Props.labelColor;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, undefined);
  return React.createElement(Ui__Group.make, {
              spacing: 0,
              children: null,
              containerStyle: Curry._1(styled, styles.container),
              orientation: "horizontal"
            }, React.createElement(Ui__MarkotText.make, {
                  size: "caption2",
                  color: Belt_Option.getWithDefault(labelColor, match.theme.colors.primaryShade2),
                  children: "" + label + ": "
                }), text.NAME === "Text" ? React.createElement(Ui__MarkotText.make, {
                    size: "price3",
                    children: text.VAL,
                    testID: "" + Utils__StringUtils.toKebabCase(label) + "-amount"
                  }) : text.VAL);
}

var make = Ui__LabeledText;

export {
  make ,
}
/* match Not a pure module */
