// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as App__Money from "../../../money/components/App__Money.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as ReactNative from "react-native";
import * as Ui__MarkotText from "../../../../../ui/src/text/components/Ui__MarkotText.bs.js";
import * as Ui__LabeledText from "../../../../../ui/src/labeledText/components/Ui__LabeledText.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as Ui__BadgeCommonStyling from "../../../../../ui/src/badge/components/Ui__BadgeCommonStyling.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as AppPrelovedItemInfoPriceTag_prelovedItemInfo_graphql from "../../../../../relay-codegen/generated/AppPrelovedItemInfoPriceTag_prelovedItemInfo_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppPrelovedItemInfoPriceTag_prelovedItemInfo_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppPrelovedItemInfoPriceTag_prelovedItemInfo_graphql.Internal.convertFragment, data);
}

var match = Ui__Styling.describe({
      buyNowBadge: {
        TAG: /* Dual */2,
        _0: {
          alignSelf: "flex-start"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.secondaryVariant3,
                    borderRadius: theme.roundness._11
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function App__PrelovedItemInfo__PriceTag$UiOnly(Props) {
  var children = Props.children;
  var style = Props.style;
  var labelOpt = Props.label;
  var label = labelOpt !== undefined ? labelOpt : "Buy now";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = Ui__BadgeCommonStyling.useCommonStyling(undefined);
  return React.createElement(ReactNative.View, {
              style: [
                Caml_option.some(match$1.containerStyle),
                Caml_option.some(Curry._1(styled, styles.buyNowBadge)),
                style
              ],
              children: React.createElement(Ui__LabeledText.make, {
                    label: label,
                    text: {
                      NAME: "Element",
                      VAL: React.createElement(Ui__MarkotText.make, {
                            size: "price3",
                            children: children,
                            testID: "preloved-item-search-result-card-price"
                          })
                    },
                    labelColor: theme.colors.primaryShade1
                  })
            });
}

function App__PrelovedItemInfo__PriceTag(Props) {
  var prelovedItemInfo = Props.prelovedItemInfo;
  var style = Props.style;
  var match = use(prelovedItemInfo);
  var pricing = match.pricing;
  var buyNowPrice;
  if (typeof pricing === "object" && pricing.NAME === "SellUsingAuction") {
    var match$1 = pricing.VAL.auction.pricing;
    buyNowPrice = typeof match$1 === "object" && match$1.NAME === "WithBuyout" ? match$1.VAL.buyNowPrice : undefined;
  } else {
    buyNowPrice = undefined;
  }
  return SimpleReact$DecidrIoUtils.renderIfSome(buyNowPrice, (function (buyNowPrice) {
                var tmp = {
                  children: React.createElement(App__Money.make, {
                        money: buyNowPrice.fragmentRefs
                      })
                };
                if (style !== undefined) {
                  tmp.style = Caml_option.valFromOption(style);
                }
                return React.createElement(App__PrelovedItemInfo__PriceTag$UiOnly, tmp);
              }));
}

var UiOnly = {
  make: App__PrelovedItemInfo__PriceTag$UiOnly
};

var make = App__PrelovedItemInfo__PriceTag;

export {
  UiOnly ,
  make ,
}
/* match Not a pure module */
