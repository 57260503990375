// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Ui__Carousel from "./Ui__Carousel";

function makeWithAnimation(config, animationType) {
  return {
          config: config,
          type: animationType
        };
}

var WithAnimation = {
  makeWithAnimation: makeWithAnimation
};

var make = Ui__Carousel.Carousel;

export {
  WithAnimation ,
  make ,
}
/* make Not a pure module */
