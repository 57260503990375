// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.errorShade1,
                    padding: theme.spacing._2
                  };
          })
      },
      text: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "bold",
          textAlign: "center"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__TestModePageIndicator(Props) {
  var titleOpt = Props.title;
  var title = titleOpt !== undefined ? titleOpt : "Test product";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  return React.createElement(ReactNative.View, {
              pointerEvents: "none",
              style: Curry._1(styled, styles.container),
              children: React.createElement(Ui__MarkotText.make, {
                    color: theme.colors.onErrorShade1,
                    children: title.toUpperCase().split("").join(" "),
                    style: Curry._1(styled, styles.text)
                  })
            });
}

var testModeBackgroundColor = "#F9EEE9";

var make = Ui__TestModePageIndicator;

export {
  testModeBackgroundColor ,
  make ,
}
/* match Not a pure module */
