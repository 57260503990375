// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Ui__Carousel from "../../carousel/components/Ui__Carousel.bs.js";
import * as ReactNative from "react-native";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__LoopingContentDisplay(Props) {
  var width = Props.width;
  var height = Props.height;
  var children = Props.children;
  var style = Props.style;
  var accessibilityRole = Props.accessibilityRole;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "content-looping-display";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, match.theme);
  var animationDuration = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        10000,
        undefined,
        undefined,
        40000,
        undefined,
        undefined,
        10000,
        undefined
      ]);
  var dimensions = ReactNative.useWindowDimensions();
  var screenWidth = dimensions.width;
  var tmp = {
    width: Belt_Option.getWithDefault(width, Belt_Option.getWithDefault(screenWidth, 300)),
    data: React.Children.toArray(children),
    renderItem: (function (param) {
        return param.item;
      }),
    autoPlay: true,
    loop: true,
    autoPlayInterval: 0,
    scrollAnimationDuration: 2000,
    withAnimation: Ui__Carousel.WithAnimation.makeWithAnimation({
          duration: animationDuration,
          easing: ReactNative.Easing.linear
        }, "timing"),
    enabled: false,
    testID: "" + testID + "-carousel"
  };
  if (height !== undefined) {
    tmp.height = height;
  }
  if (style !== undefined) {
    tmp.style = Caml_option.valFromOption(style);
  }
  var tmp$1 = {
    style: [
      Caml_option.some(Curry._1(styled, styles.container)),
      Belt_Option.map(width, (function (width) {
              return {
                      width: width
                    };
            }))
    ],
    testID: testID,
    children: React.createElement(Ui__Carousel.make, tmp)
  };
  if (accessibilityRole !== undefined) {
    tmp$1.accessibilityRole = Caml_option.valFromOption(accessibilityRole);
  }
  return React.createElement(ReactNative.View, tmp$1);
}

var make = Ui__LoopingContentDisplay;

export {
  make ,
}
/* match Not a pure module */
