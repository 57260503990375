// Generated by ReScript, PLEASE EDIT WITH CARE


var bundlesEnabled = false;

var buyerOrderReviewEnabled = false;

var slidingPromoBanner = "on";

export {
  bundlesEnabled ,
  buyerOrderReviewEnabled ,
  slidingPromoBanner ,
}
/* No side effect */
