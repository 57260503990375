// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as Ui__Responsive from "../responsive/Ui__Responsive.bs.js";

var textDefaultSize_1 = {
  size: 11,
  lineHeight: 14,
  letterSpacingPct: 0.004
};

var textDefaultSize = {
  NAME: "unsafe",
  VAL: textDefaultSize_1
};

var textSmallSize_1 = {
  size: 13,
  lineHeight: 16,
  letterSpacingPct: 0.004
};

var textSmallSize = {
  NAME: "unsafe",
  VAL: textSmallSize_1
};

var textLargeSize_1 = {
  size: 15,
  lineHeight: 18,
  letterSpacingPct: 0.004
};

var textLargeSize = {
  NAME: "unsafe",
  VAL: textLargeSize_1
};

var priceDefaultSize_1 = {
  size: 14,
  lineHeight: 18,
  letterSpacingPct: 0.004
};

var priceDefaultSize = {
  NAME: "unsafe",
  VAL: priceDefaultSize_1
};

var priceSmallSize_1 = {
  size: 14,
  lineHeight: 18,
  letterSpacingPct: 0.004
};

var priceSmallSize = {
  NAME: "unsafe",
  VAL: priceSmallSize_1
};

var priceLargeSize_1 = {
  size: 16,
  lineHeight: 20,
  letterSpacingPct: 0.006
};

var priceLargeSize = {
  NAME: "unsafe",
  VAL: priceLargeSize_1
};

var secondaryTextDefaultSize_1 = {
  size: 11,
  lineHeight: 14,
  letterSpacingPct: 0.007
};

var secondaryTextDefaultSize = {
  NAME: "unsafe",
  VAL: secondaryTextDefaultSize_1
};

var secondaryTextSmallSize_1 = {
  size: 11,
  lineHeight: 14,
  letterSpacingPct: 0.007
};

var secondaryTextSmallSize = {
  NAME: "unsafe",
  VAL: secondaryTextSmallSize_1
};

var secondaryTextLargeSize_1 = {
  size: 12,
  lineHeight: 16,
  letterSpacingPct: 0.007
};

var secondaryTextLargeSize = {
  NAME: "unsafe",
  VAL: secondaryTextLargeSize_1
};

function useSizes(param) {
  return Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
              undefined,
              {
                cardWidth: 160,
                imageHeight: 132,
                imageContainerHeight: 188,
                brandNameSize: textSmallSize,
                nameSize: textSmallSize,
                priceSize: priceSmallSize,
                secondaryTextSize: secondaryTextSmallSize
              },
              {
                cardWidth: 180,
                imageHeight: 156,
                imageContainerHeight: 224,
                brandNameSize: textSmallSize,
                nameSize: textSmallSize,
                priceSize: priceSmallSize,
                secondaryTextSize: secondaryTextSmallSize
              },
              undefined,
              undefined,
              {
                cardWidth: 226,
                imageHeight: 162,
                imageContainerHeight: 226,
                brandNameSize: textLargeSize,
                nameSize: textLargeSize,
                priceSize: priceLargeSize,
                secondaryTextSize: secondaryTextLargeSize
              },
              {
                cardWidth: 236,
                imageHeight: 172,
                imageContainerHeight: 236,
                brandNameSize: textLargeSize,
                nameSize: textLargeSize,
                priceSize: priceLargeSize,
                secondaryTextSize: secondaryTextLargeSize
              },
              {
                cardWidth: 133,
                imageHeight: 111,
                imageContainerHeight: 164,
                brandNameSize: textDefaultSize,
                nameSize: textDefaultSize,
                priceSize: priceDefaultSize,
                secondaryTextSize: secondaryTextDefaultSize
              },
              undefined
            ]);
}

export {
  useSizes ,
}
/* Ui__Responsive Not a pure module */
