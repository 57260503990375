// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";

var Types = {};

var fragmentConverter = {"__root":{"purchaseAmount":{"f":""},"minimumBid":{"f":""},"highestBid":{"f":""},"buyNowPrice":{"f":""},"":{"f":""}}};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, undefined, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: undefined,
  convertFragment: convertFragment
};

function prelovedItemCondition2_decode($$enum) {
  if ($$enum === "NeverUsed" || $$enum === "Good" || $$enum === "AsGoodAsNew" || $$enum === "WellLoved") {
    return $$enum;
  }
  
}

var prelovedItemCondition2_fromString = prelovedItemCondition2_decode;

function prelovedItemInfosStatusFilter_decode($$enum) {
  if ($$enum === "Sold" || $$enum === "Live") {
    return $$enum;
  }
  
}

var prelovedItemInfosStatusFilter_fromString = prelovedItemInfosStatusFilter_decode;

var Utils = {
  prelovedItemCondition2_decode: prelovedItemCondition2_decode,
  prelovedItemCondition2_fromString: prelovedItemCondition2_fromString,
  prelovedItemInfosStatusFilter_decode: prelovedItemInfosStatusFilter_decode,
  prelovedItemInfosStatusFilter_fromString: prelovedItemInfosStatusFilter_fromString
};

var node = ((function(){
var v0 = [
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "AppMoney_money"
  }
];
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppSearchPrelovedItemResultCard_prelovedItemSearchResult",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "prelovedItemInfoId",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "numberOfBids",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "condition",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productName",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "productSlug",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "status",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "ImageResource",
      "kind": "LinkedField",
      "name": "mainImage",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "url",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "alt",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "Size",
          "kind": "LinkedField",
          "name": "size",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "width",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "height",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "buyNowPrice",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "highestBid",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "minimumBid",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Money",
      "kind": "LinkedField",
      "name": "purchaseAmount",
      "plural": false,
      "selections": (v0/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "usedForTesting",
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "AppPrelovedItemSearchResultCard_prelovedItemSearchResult"
    }
  ],
  "type": "PrelovedItemSearchResult",
  "abstractKey": null
};
})());

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
