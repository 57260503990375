// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "../../routing/Ui__AppLink.bs.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center",
          minWidth: 56
        }
      },
      activeTitle: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "bold"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__NavBar$Tab(Props) {
  var tab = Props.tab;
  var isActive = Props.isActive;
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : "small";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, match.theme);
  var tmp = {
    size: size === "small" ? "caption2" : "b2",
    children: tab.title
  };
  var tmp$1 = Bool$DecidrIoUtils.thenSome(isActive, Curry._1(styled, styles.activeTitle));
  if (tmp$1 !== undefined) {
    tmp.style = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(ReactNative.View, {
              style: Curry._1(styled, styles.container),
              children: React.createElement(Ui__MarkotText.make, tmp)
            });
}

var match$1 = Ui__Styling.describe({
      container: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.surface,
                    paddingHorizontal: theme.spacing._1
                  };
          })
      },
      tabIconContainer: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "center"
        },
        _1: (function (theme) {
            return {
                    borderBottomWidth: theme.lineThickness.m
                  };
          })
      },
      tabWrapper: {
        TAG: /* Dual */2,
        _0: {
          alignSelf: "center"
        },
        _1: (function (theme) {
            return {
                    paddingVertical: theme.spacing._10
                  };
          })
      }
    });

var stylesheet$1 = match$1[1];

var styles$1 = match$1[0];

function Ui__NavBar(Props) {
  var routes = Props.routes;
  var activeRouteIndex = Props.activeRouteIndex;
  var containerStyle = Props.containerStyle;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$1, theme);
  var tabSize = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        "large",
        "large",
        "small",
        undefined
      ]);
  return React.createElement(Ui__Group.make, {
              spacing: theme.spacing._20,
              children: Belt_Array.mapWithIndex(routes, (function (index, route) {
                      var config = route.config;
                      var tab = route.tab;
                      var isActive = activeRouteIndex === index;
                      var tmp;
                      if (config.NAME === "Route") {
                        var routeConfig = config.VAL;
                        tmp = React.createElement(Ui__AppLink.make, {
                              configuration: Curry._3(Ui__AppLink.makeConfiguration, routeConfig.route, undefined, undefined),
                              children: React.createElement(ReactNative.View, {
                                    style: Curry._1(styled, styles$1.tabWrapper),
                                    children: React.createElement(Ui__NavBar$Tab, {
                                          tab: tab,
                                          isActive: isActive,
                                          size: tabSize
                                        })
                                  }),
                              a11yLabel: routeConfig.a11yLabel,
                              key: tab.title
                            });
                      } else {
                        var onPress = config.VAL;
                        tmp = React.createElement(ReactNative.TouchableOpacity, {
                              style: Curry._1(styled, styles$1.tabWrapper),
                              onPress: (function (param) {
                                  Curry._1(onPress, undefined);
                                }),
                              children: React.createElement(Ui__NavBar$Tab, {
                                    tab: tab,
                                    isActive: isActive,
                                    size: tabSize
                                  })
                            });
                      }
                      return React.createElement(Ui__Group.make, {
                                  spacing: theme.spacing._5,
                                  children: null,
                                  containerStyle: [
                                    Curry._1(styled, styles$1.tabIconContainer),
                                    {
                                      borderBottomColor: isActive ? theme.colors.primary : theme.colors.surface
                                    }
                                  ],
                                  orientation: "horizontal",
                                  testID: "" + (
                                    isActive ? "active" : "inactive"
                                  ) + "-navbar-tab"
                                }, tmp, SimpleReact$DecidrIoUtils.leaveIfSome(route.tabRight));
                    })),
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles$1.container)),
                containerStyle
              ],
              orientation: "horizontal",
              testID: "nav-bar",
              accessibilityRole: "navigation"
            });
}

var Tab = {
  make: Ui__NavBar$Tab
};

var make = Ui__NavBar;

export {
  Tab ,
  make ,
}
/* match Not a pure module */
