// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";

var Types = {};

function unwrap_fragment_pricing_SellUsingAuction_auction_pricing(u) {
  var v = u.__typename;
  if (v === "WithBuyout") {
    return {
            NAME: "WithBuyout",
            VAL: u
          };
  } else {
    return {
            NAME: "UnselectedUnionMember",
            VAL: v
          };
  }
}

function wrap_fragment_pricing_SellUsingAuction_auction_pricing(v) {
  if (v.NAME === "WithBuyout") {
    return v.VAL;
  } else {
    return {
            __typename: v.VAL
          };
  }
}

function unwrap_fragment_pricing(u) {
  var v = u.__typename;
  if (v === "SellUsingAuction") {
    return {
            NAME: "SellUsingAuction",
            VAL: u
          };
  } else {
    return {
            NAME: "UnselectedUnionMember",
            VAL: v
          };
  }
}

function wrap_fragment_pricing(v) {
  if (v.NAME === "UnselectedUnionMember") {
    return {
            __typename: v.VAL
          };
  } else {
    return v.VAL;
  }
}

var fragmentConverter = {"__root":{"pricing_SellUsingAuction_auction_pricing_WithBuyout_buyNowPrice":{"f":""},"pricing_SellUsingAuction_auction_pricing":{"u":"fragment_pricing_SellUsingAuction_auction_pricing"},"pricing":{"u":"fragment_pricing"}}};

var fragmentConverterMap = {
  fragment_pricing_SellUsingAuction_auction_pricing: unwrap_fragment_pricing_SellUsingAuction_auction_pricing,
  fragment_pricing: unwrap_fragment_pricing
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppPrelovedItemInfoPriceTag_prelovedItemInfo",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": null,
      "kind": "LinkedField",
      "name": "pricing",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Auction",
              "kind": "LinkedField",
              "name": "auction",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "pricing",
                  "plural": false,
                  "selections": [
                    (v0/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Money",
                          "kind": "LinkedField",
                          "name": "buyNowPrice",
                          "plural": false,
                          "selections": [
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "AppMoney_money"
                            }
                          ],
                          "storageKey": null
                        }
                      ],
                      "type": "WithBuyout",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "type": "SellUsingAuction",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "PrelovedItemInfo",
  "abstractKey": null
};
})());

export {
  Types ,
  unwrap_fragment_pricing_SellUsingAuction_auction_pricing ,
  wrap_fragment_pricing_SellUsingAuction_auction_pricing ,
  unwrap_fragment_pricing ,
  wrap_fragment_pricing ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
