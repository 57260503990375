// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../../../../packages/ui/src/group/components/Ui__Group.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Reanimated from "rescript-reanimated/src/Reanimated.bs.js";
import * as Ui__NavBar from "../../../../../packages/ui/src/navBar/components/Ui__NavBar.bs.js";
import * as Ui__Portal from "../../../../../packages/ui/src/portal/Ui__Portal.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../../../../packages/ui/src/styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as App__Features from "../../../../../packages/app/src/features/App__Features.bs.js";
import * as Ui__IconButton from "../../../../../packages/ui/src/iconButton/components/Ui__IconButton.bs.js";
import * as Web__BagDrawer from "../../bagDrawer/components/Web__BagDrawer.bs.js";
import * as App__GlobalSearch from "../../../../../packages/app/src/search/App__GlobalSearch.bs.js";
import * as Style$ReactNative from "rescript-react-native/src/apis/Style.bs.js";
import * as Ui__MainHeaderBar from "../../../../../packages/ui/src/mainHeaderBar/components/Ui__MainHeaderBar.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as Ui__WebPromoBanner from "../../../../../packages/ui/src/banners/components/webPromoBanner/Ui__WebPromoBanner.bs.js";
import * as Web__AccountDrawer from "../../accountDrawer/components/Web__AccountDrawer.bs.js";
import * as Ui__WebDrawersContext from "../../../../../packages/ui/src/webDrawers/Ui__WebDrawersContext.bs.js";
import * as ReactNativeReanimated from "react-native-reanimated";
import * as Web__DisableScrollYStyle from "./Web__DisableScrollYStyle.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as App__BrowseTabDropdownContent from "../../../../../packages/app/src/browseTabDropdownContent/App__BrowseTabDropdownContent.bs.js";
import * as FeatureFlags__LocalFeatureFlags from "../../../../../packages/feature-flags/src/local/FeatureFlags__LocalFeatureFlags.bs.js";
import * as GroupFactory$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/group/components/GroupFactory.bs.js";

function Web__MainHeaderBar$AnimatedSpacer(Props) {
  var givenSize = Props.size;
  var style = Props.style;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var size = Belt_Option.getWithDefault(givenSize, match.theme.spacing._6);
  return React.createElement(Reanimated.View.make, {
              style: [
                {
                  width: size
                },
                style
              ]
            });
}

var match = Ui__Styling.describe({
      container: {
        TAG: /* Static */0,
        _0: {
          zIndex: 2
        }
      },
      topWrapper: {
        TAG: /* Dual */2,
        _0: {
          zIndex: 3
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.primary
                  };
          })
      },
      icons: {
        TAG: /* Dual */2,
        _0: {
          alignSelf: "flex-end"
        },
        _1: (function (theme) {
            return {
                    paddingRight: theme.spacing._14,
                    paddingVertical: theme.spacing._6
                  };
          })
      },
      bottom: {
        TAG: /* Dual */2,
        _0: {
          elevation: 14,
          shadowOffset: {
            height: 2,
            width: 0
          },
          shadowOpacity: 0.05,
          alignItems: "center",
          flex: 1,
          flexDirection: "row"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.surface,
                    paddingHorizontal: theme.spacing._12
                  };
          })
      },
      headerLeftContainer: {
        TAG: /* Static */0,
        _0: {
          flex: 1,
          flexDirection: "row"
        }
      },
      navBarContainer: {
        TAG: /* Static */0,
        _0: {
          alignItems: "center",
          flex: 3
        }
      },
      searchBoxContainer: {
        TAG: /* Static */0,
        _0: {
          flex: 1,
          flexDirection: "row-reverse"
        }
      },
      dropdown: {
        TAG: /* Static */0,
        _0: {
          height: Ui__Styling.pct(165),
          position: "absolute",
          width: Style$ReactNative.pct(100),
          zIndex: 1
        }
      },
      drawers: {
        TAG: /* Static */0,
        _0: {
          zIndex: 2
        }
      },
      drawersHeight: {
        TAG: /* Static */0,
        _0: {
          height: "100vh"
        }
      },
      backdrop: {
        TAG: /* Static */0,
        _0: {
          backgroundColor: "black",
          flex: 1
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Web__MainHeaderBar(Props) {
  var containerStyle = Props.containerStyle;
  var testIDOpt = Props.testID;
  var givenActiveRouteIndexOpt = Props.activeRouteIndex;
  var onSearchSubmit = Props.onSearchSubmit;
  var routerPush = Props.routerPush;
  var showPromoBannerOpt = Props.showPromoBanner;
  var testID = testIDOpt !== undefined ? testIDOpt : "web-main-header-bar";
  var givenActiveRouteIndex = givenActiveRouteIndexOpt !== undefined ? givenActiveRouteIndexOpt : 0;
  var showPromoBanner = showPromoBannerOpt !== undefined ? showPromoBannerOpt : "off";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = React.useState(function () {
        return 105;
      });
  var setHeaderHeight = match$1[1];
  var headerHeight = match$1[0];
  var maybeHeight = ReactNative.useWindowDimensions().height;
  var dropdownHeight = Belt_Option.getWithDefault(Belt_Option.map(maybeHeight, (function (height) {
              return height * 0.8;
            })), 772);
  var match$2 = React.useState(function () {
        return givenActiveRouteIndex;
      });
  var setActiveRouteIndex = match$2[1];
  var match$3 = Ui__WebDrawersContext.useWebDrawers(undefined);
  var updateVisibleDrawer = match$3.updateVisibleDrawer;
  var visibleDrawer = match$3.visibleDrawer;
  var opsPromoBanner = FeatureFlags__LocalFeatureFlags.use("promoBanner230227");
  var showPromoBanner$1 = showPromoBanner === "off" ? false : (
      showPromoBanner === "undetermined" ? opsPromoBanner === "on" : true
    );
  var dropdownPosition = ReactNativeReanimated.useSharedValue(0);
  var dropdownStyle = ReactNativeReanimated.useAnimatedStyle(function () {
        var translateY = ReactNativeReanimated.interpolate(dropdownPosition.value, [
              0,
              1
            ], [
              -dropdownHeight,
              0
            ], undefined);
        return {
                transform: [{
                    translateY: translateY
                  }]
              };
      });
  var match$4 = React.useState(function () {
        return false;
      });
  var setIsCategoryMenuOpen = match$4[1];
  var isCategoryMenuOpen = match$4[0];
  var backdropStyle = ReactNativeReanimated.useAnimatedStyle(function () {
        var opacity = ReactNativeReanimated.interpolate(dropdownPosition.value, [
              0,
              1
            ], [
              0,
              0.5
            ], undefined);
        return {
                opacity: opacity
              };
      });
  var closeDropdown = function (param) {
    Curry._1(setIsCategoryMenuOpen, (function (param) {
            return false;
          }));
    Curry._1(setActiveRouteIndex, (function (param) {
            return givenActiveRouteIndex;
          }));
    Curry._1(setIsCategoryMenuOpen, (function (param) {
            return false;
          }));
    dropdownPosition.value = Reanimated.withTiming(0, Reanimated.Timing.makeConfig(225, undefined, undefined), undefined, undefined);
  };
  var handleProductVariantPress = function (slug, productId, productVariantId) {
    Curry._1(routerPush, "/" + slug + "?id=" + productId + "&v=" + productVariantId + "");
  };
  var searchBoxFocused = ReactNativeReanimated.useSharedValue(0);
  var searchBoxContainerStyle = ReactNativeReanimated.useAnimatedStyle(function () {
        var flex = ReactNativeReanimated.interpolate(searchBoxFocused.value, [
              0,
              1
            ], [
              1,
              3
            ], undefined);
        return {
                flex: flex
              };
      });
  var navBarStyle = ReactNativeReanimated.useAnimatedStyle(function () {
        var flex = ReactNativeReanimated.interpolate(searchBoxFocused.value, [
              0,
              1
            ], [
              3,
              0
            ], undefined);
        var opacity = ReactNativeReanimated.interpolate(searchBoxFocused.value, [
              0,
              1
            ], [
              1,
              0
            ], undefined);
        return {
                opacity: opacity,
                flex: flex
              };
      });
  var navBarPointerEventsStyle = ReactNativeReanimated.useAnimatedStyle(function () {
        return {
                pointerEvents: searchBoxFocused.value === 1 ? "none" : undefined
              };
      });
  var preNavBarSpacerStyle = ReactNativeReanimated.useAnimatedStyle(function () {
        var width = ReactNativeReanimated.interpolate(searchBoxFocused.value, [
              0,
              1
            ], [
              theme.spacing._8,
              0
            ], undefined);
        return {
                width: width
              };
      });
  var preInvisibleElementSpacerStyle = ReactNativeReanimated.useAnimatedStyle(function () {
        var width = ReactNativeReanimated.interpolate(searchBoxFocused.value, [
              0,
              1
            ], [
              0,
              theme.spacing._8
            ], undefined);
        return {
                width: width
              };
      });
  var invisibleElementStyle = ReactNativeReanimated.useAnimatedStyle(function () {
        var flex = ReactNativeReanimated.interpolate(searchBoxFocused.value, [
              0,
              1
            ], [
              0,
              1
            ], undefined);
        return {
                flex: flex
              };
      });
  var expandSearchBox = function (param) {
    searchBoxFocused.value = Reanimated.withTiming(1, Reanimated.Timing.makeConfig(350, undefined, undefined), undefined, undefined);
  };
  var shrinkSearchBox = function (param) {
    searchBoxFocused.value = Reanimated.withTiming(0, Reanimated.Timing.makeConfig(350, undefined, undefined), undefined, undefined);
  };
  return React.createElement(React.Fragment, undefined, SimpleReact$DecidrIoUtils.whenTrue(isCategoryMenuOpen, React.createElement(Web__DisableScrollYStyle.make, {})), React.createElement(ReactNative.View, {
                  accessibilityRole: "banner",
                  onLayout: (function (param) {
                      var nativeEvent = param.nativeEvent;
                      Curry._1(setHeaderHeight, (function (param) {
                              return nativeEvent.layout.height - 2;
                            }));
                    }),
                  style: [
                    Caml_option.some(Curry._1(styled, styles.container)),
                    containerStyle
                  ],
                  testID: testID,
                  children: null
                }, React.createElement(ReactNative.View, {
                      style: Curry._1(styled, styles.topWrapper),
                      children: null
                    }, SimpleReact$DecidrIoUtils.whenTrue(showPromoBanner$1, React.createElement(Ui__WebPromoBanner.make, {
                              slidingPromoBanner: App__Features.slidingPromoBanner
                            })), React.createElement(Ui__Group.make, {
                          spacing: theme.spacing._8,
                          children: null,
                          containerStyle: Curry._1(styled, styles.icons),
                          orientation: "horizontal",
                          accessibilityRole: "navigation"
                        }, React.createElement(Ui__IconButton.Bag.make, {
                              active: Belt_Option.getWithDefault(Belt_Option.map(visibleDrawer, (function (visibleDrawer) {
                                          return visibleDrawer === "bag";
                                        })), false),
                              onPress: (function (param) {
                                  Curry._1(updateVisibleDrawer, visibleDrawer !== undefined && visibleDrawer !== "account" ? undefined : "bag");
                                }),
                              size: Ui__MainHeaderBar.iconSize,
                              color: theme.colors.onPrimary
                            }), React.createElement(Ui__IconButton.User.make, {
                              active: Belt_Option.getWithDefault(Belt_Option.map(visibleDrawer, (function (visibleDrawer) {
                                          return visibleDrawer === "account";
                                        })), false),
                              onPress: (function (param) {
                                  Curry._1(updateVisibleDrawer, visibleDrawer === "account" ? undefined : "account");
                                }),
                              size: Ui__MainHeaderBar.iconSize,
                              color: theme.colors.onPrimary
                            }))), React.createElement(Ui__Portal.Host.make, {
                      name: "NavBarDrawer"
                    }), React.createElement(ReactNative.View, {
                      style: Curry._1(styled, styles.bottom),
                      children: null
                    }, React.createElement(ReactNative.View, {
                          style: Curry._1(styled, styles.headerLeftContainer),
                          testID: "header-left-container",
                          children: React.createElement(Ui__MainHeaderBar.HeaderLeftButton.make, {
                                kind: "homePage"
                              })
                        }), React.createElement(Web__MainHeaderBar$AnimatedSpacer, {
                          size: theme.spacing._8,
                          style: preNavBarSpacerStyle
                        }), React.createElement(Reanimated.View.make, {
                          style: [
                            Curry._1(styled, styles.navBarContainer),
                            navBarStyle,
                            navBarPointerEventsStyle
                          ],
                          children: React.createElement(Ui__NavBar.make, {
                                routes: Belt_Array.keepMap([
                                      {
                                        tab: {
                                          title: "Discover"
                                        },
                                        config: {
                                          NAME: "Route",
                                          VAL: {
                                            route: /* Discover */6,
                                            a11yLabel: "Go to discover page"
                                          }
                                        },
                                        tabRight: undefined
                                      },
                                      {
                                        tab: {
                                          title: "Buy"
                                        },
                                        config: {
                                          NAME: "Action",
                                          VAL: (function (param) {
                                              if (dropdownPosition.value === 0) {
                                                Curry._1(setIsCategoryMenuOpen, (function (param) {
                                                        return true;
                                                      }));
                                                Curry._1(setActiveRouteIndex, (function (param) {
                                                        return 1;
                                                      }));
                                                Curry._1(setIsCategoryMenuOpen, (function (param) {
                                                        return true;
                                                      }));
                                                dropdownPosition.value = Reanimated.withTiming(1, Reanimated.Timing.makeConfig(350, undefined, undefined), undefined, undefined);
                                                return ;
                                              } else {
                                                return closeDropdown(undefined);
                                              }
                                            })
                                        },
                                        tabRight: undefined
                                      },
                                      {
                                        tab: {
                                          title: "Sell"
                                        },
                                        config: {
                                          NAME: "Route",
                                          VAL: {
                                            route: {
                                              TAG: /* ExternalRoute */12,
                                              url: "https://pages.markot.com/sell-your-items/",
                                              target: "_blank"
                                            },
                                            a11yLabel: "Go to sell page"
                                          }
                                        },
                                        tabRight: undefined
                                      },
                                      Bool$DecidrIoUtils.thenSome(false, {
                                            tab: {
                                              title: "Bundles"
                                            },
                                            config: {
                                              NAME: "Route",
                                              VAL: {
                                                route: /* Bundles */14,
                                                a11yLabel: "Go to bundles page"
                                              }
                                            },
                                            tabRight: undefined
                                          })
                                    ], (function (x) {
                                        return x;
                                      })),
                                activeRouteIndex: match$2[0]
                              })
                        }), React.createElement(GroupFactory$DecidrIoUniversalComponents.Spacer.make, {
                          size: theme.spacing._8,
                          orientation: "horizontal"
                        }), React.createElement(Reanimated.View.make, {
                          style: [
                            Curry._1(styled, styles.searchBoxContainer),
                            searchBoxContainerStyle
                          ],
                          children: React.createElement(App__GlobalSearch.make, {
                                headerHeight: headerHeight,
                                onSearchBoxFocus: expandSearchBox,
                                onSearchBoxBlurOrOverlayClose: shrinkSearchBox,
                                onSearchSubmit: onSearchSubmit,
                                onProductVariantPress: handleProductVariantPress,
                                disableScrollYStyleElement: React.createElement(Web__DisableScrollYStyle.make, {})
                              })
                        }), React.createElement(Web__MainHeaderBar$AnimatedSpacer, {
                          size: theme.spacing._8,
                          style: preInvisibleElementSpacerStyle
                        }), React.createElement(Reanimated.View.make, {
                          style: invisibleElementStyle
                        }))), React.createElement(Ui__Portal.make, {
                  children: React.createElement(Reanimated.View.make, {
                        style: [
                          Caml_option.some(Curry._1(styled, styles.dropdown)),
                          opsPromoBanner === "off" ? undefined : ({
                                height: Style$ReactNative.pct(160)
                              }),
                          {
                            top: headerHeight
                          },
                          Caml_option.some(dropdownStyle)
                        ],
                        children: null,
                        pointerEvents: (function () {
                              switch (isCategoryMenuOpen ? "auto" : "none") {
                                case "auto" :
                                    return "auto";
                                case "none" :
                                    return "none";
                                case "boxNone" :
                                    return "box-none";
                                case "boxOnly" :
                                    return "box-only";
                                
                              }
                            })()
                      }, React.createElement(App__BrowseTabDropdownContent.make, {
                            onSubcategoryPress: (function (id, slug, _name) {
                                Curry._1(routerPush, "/subcategory/" + slug + "?id=" + id + "");
                                if (Belt_Option.getWithDefault(Belt_Option.map(window.location.pathname, (function (__x) {
                                              return __x.includes("subcategory");
                                            })), false)) {
                                  return closeDropdown(undefined);
                                }
                                
                              })
                          }), React.createElement(ReactNative.TouchableWithoutFeedback, {
                            onPress: (function (param) {
                                closeDropdown(undefined);
                              }),
                            children: React.createElement(Reanimated.View.make, {
                                  style: [
                                    Curry._1(styled, styles.backdrop),
                                    backdropStyle
                                  ]
                                })
                          })),
                  hostName: "BrowseDropdownHost"
                }), React.createElement(Web__AccountDrawer.make, {
                  isOpened: Belt_Option.getWithDefault(Belt_Option.map(visibleDrawer, (function (visibleDrawer) {
                              return visibleDrawer === "account";
                            })), false),
                  onClose: (function (param) {
                      Curry._1(updateVisibleDrawer, undefined);
                    }),
                  contentStyle: [
                    Caml_option.some(Curry._1(styled, styles.drawers)),
                    Caml_option.some(Curry._1(styled, styles.drawersHeight)),
                    Bool$DecidrIoUtils.thenSome(showPromoBanner$1, {
                          paddingTop: theme.sectionSpacing._05
                        })
                  ],
                  containerStyle: [
                    Curry._1(styled, styles.drawers),
                    Curry._1(styled, styles.drawersHeight)
                  ]
                }), React.createElement(Web__BagDrawer.make, {
                  isOpened: Belt_Option.getWithDefault(Belt_Option.map(visibleDrawer, (function (visibleDrawer) {
                              return visibleDrawer === "bag";
                            })), false),
                  onClose: (function (param) {
                      Curry._1(updateVisibleDrawer, undefined);
                    }),
                  contentStyle: [
                    Caml_option.some(Curry._1(styled, styles.drawers)),
                    Caml_option.some(Curry._1(styled, styles.drawersHeight)),
                    Bool$DecidrIoUtils.thenSome(showPromoBanner$1, {
                          paddingTop: theme.sectionSpacing._1
                        })
                  ],
                  containerStyle: [
                    Curry._1(styled, styles.drawers),
                    Curry._1(styled, styles.drawersHeight)
                  ]
                }));
}

var make = Web__MainHeaderBar;

export {
  make ,
}
/* match Not a pure module */
