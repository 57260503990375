// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../group/components/Ui__Group.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "../routing/Ui__AppLink.bs.js";
import * as Ui__Styling from "../styling/Ui__Styling.bs.js";
import * as Ui__IconButton from "../iconButton/components/Ui__IconButton.bs.js";
import * as Ui__MarkotIcon from "../icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__MarkotLogoWithTitle from "../svg/logoWithTitle/Ui__MarkotLogoWithTitle.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          justifyContent: "space-between"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.primary,
                    paddingHorizontal: theme.spacing._6,
                    paddingVertical: theme.spacing._8
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__DiscoverHeaderBar(Props) {
  var containerStyle = Props.containerStyle;
  var onSearchPress = Props.onSearchPress;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var match$1 = Curry._1(Ui__AppLink.useRouting, undefined);
  var nativeNavigationHandler = match$1.nativeNavigationHandler;
  var bagRouteConfiguration = Curry._3(Ui__AppLink.makeConfiguration, /* Bag */13, undefined, undefined);
  var wishlistRouteConfiguration = Curry._3(Ui__AppLink.makeConfiguration, /* Wishlist */10, undefined, undefined);
  var searchIcon = React.createElement(Ui__MarkotIcon.make, {
        name: "magnifying-glass-outline",
        size: 20,
        color: theme.colors.onPrimary,
        testID: "search-box-button"
      });
  var tmp = {
    active: false,
    size: 20,
    color: theme.colors.onPrimary
  };
  var tmp$1 = Belt_Option.map(nativeNavigationHandler, (function (navHandler, param) {
          Curry._1(navHandler, wishlistRouteConfiguration);
        }));
  if (tmp$1 !== undefined) {
    tmp.onPress = Caml_option.valFromOption(tmp$1);
  }
  var tmp$2 = {
    active: false,
    size: 20,
    color: theme.colors.onPrimary
  };
  var tmp$3 = Belt_Option.map(nativeNavigationHandler, (function (navHandler, param) {
          Curry._1(navHandler, bagRouteConfiguration);
        }));
  if (tmp$3 !== undefined) {
    tmp$2.onPress = Caml_option.valFromOption(tmp$3);
  }
  return React.createElement(Ui__Group.make, {
              children: null,
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles.container)),
                containerStyle
              ],
              orientation: "horizontal",
              accessibilityRole: "banner"
            }, React.createElement(Ui__MarkotLogoWithTitle.make, {
                  size: 24,
                  color: theme.colors.onPrimary
                }), React.createElement(Ui__Group.make, {
                  spacing: theme.spacing._10,
                  children: null,
                  orientation: "horizontal"
                }, React.createElement(Ui__IconButton.make, {
                      active: false,
                      activeIcon: searchIcon,
                      inactiveIcon: searchIcon,
                      onPress: onSearchPress,
                      size: 20
                    }), React.createElement(Ui__AppLink.make, {
                      configuration: wishlistRouteConfiguration,
                      children: React.createElement(Ui__IconButton.Bookmark.make, tmp),
                      a11yLabel: "Go check your wishlist"
                    }), React.createElement(Ui__AppLink.make, {
                      configuration: bagRouteConfiguration,
                      children: React.createElement(Ui__IconButton.Bag.make, tmp$2),
                      a11yLabel: "Go check your bag"
                    })));
}

var make = Ui__DiscoverHeaderBar;

export {
  make ,
}
/* match Not a pure module */
