// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__MarkotIcon from "../../icons/components/Ui__MarkotIcon.bs.js";
import * as Box$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/box/components/Box.bs.js";
import * as RnUtils$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/utils/RnUtils.bs.js";

function Ui__IconButton(Props) {
  var disabledOpt = Props.disabled;
  var active = Props.active;
  var activeIcon = Props.activeIcon;
  var inactiveIcon = Props.inactiveIcon;
  var onPress = Props.onPress;
  var sizeOpt = Props.size;
  var testIDOpt = Props.testID;
  var containerStyle = Props.containerStyle;
  var disabled = disabledOpt !== undefined ? disabledOpt : false;
  var size = sizeOpt !== undefined ? sizeOpt : 24;
  var testID = testIDOpt !== undefined ? testIDOpt : "icon-button";
  var displayedIcon = active ? activeIcon : inactiveIcon;
  var hitSlop = size <= 32 ? Caml_option.some(RnUtils$DecidrIoUniversalComponents.makeSymmetricHitSlop(32 - size)) : undefined;
  var tmp = {
    children: displayedIcon,
    testID: testID,
    accessibilityRole: "button"
  };
  var tmp$1 = Belt_Option.map(onPress, (function (onPress) {
          return {
                  NAME: "Pressable",
                  VAL: Box$DecidrIoUniversalComponents.makePressableProps(disabled, undefined, undefined, (function (param) {
                          Curry._1(onPress, undefined);
                        }), undefined, undefined, undefined)
                };
        }));
  if (tmp$1 !== undefined) {
    tmp.kind = Caml_option.valFromOption(tmp$1);
  }
  if (containerStyle !== undefined) {
    tmp.style = Caml_option.valFromOption(containerStyle);
  }
  if (hitSlop !== undefined) {
    tmp.hitSlop = Caml_option.valFromOption(hitSlop);
  }
  return React.createElement(Box$DecidrIoUniversalComponents.make, tmp);
}

function Ui__IconButton$Bell(Props) {
  var disabled = Props.disabled;
  var active = Props.active;
  var onPress = Props.onPress;
  var size = Props.size;
  var containerStyle = Props.containerStyle;
  var color = Props.color;
  var tmp = {
    name: "bell-fill",
    testID: "active-bell-icon"
  };
  if (size !== undefined) {
    tmp.size = size;
  }
  if (color !== undefined) {
    tmp.color = color;
  }
  var tmp$1 = {
    name: "bell-outline",
    testID: "inactive-bell-icon"
  };
  if (size !== undefined) {
    tmp$1.size = size;
  }
  if (color !== undefined) {
    tmp$1.color = color;
  }
  return Ui__IconButton({
              disabled: disabled,
              active: active,
              onPress: onPress,
              activeIcon: React.createElement(Ui__MarkotIcon.make, tmp),
              inactiveIcon: React.createElement(Ui__MarkotIcon.make, tmp$1),
              size: size,
              testID: "bell-icon-button",
              containerStyle: containerStyle
            });
}

var Bell = {
  make: Ui__IconButton$Bell
};

function Ui__IconButton$Bag(Props) {
  var disabled = Props.disabled;
  var active = Props.active;
  var onPress = Props.onPress;
  var size = Props.size;
  var containerStyle = Props.containerStyle;
  var color = Props.color;
  var tmp = {
    name: "bag-fill",
    testID: "active-bag-icon"
  };
  if (size !== undefined) {
    tmp.size = size;
  }
  if (color !== undefined) {
    tmp.color = color;
  }
  var tmp$1 = {
    name: "bag-outline",
    testID: "inactive-bag-icon"
  };
  if (size !== undefined) {
    tmp$1.size = size;
  }
  if (color !== undefined) {
    tmp$1.color = color;
  }
  return Ui__IconButton({
              disabled: disabled,
              active: active,
              onPress: onPress,
              activeIcon: React.createElement(Ui__MarkotIcon.make, tmp),
              inactiveIcon: React.createElement(Ui__MarkotIcon.make, tmp$1),
              size: size,
              testID: "bag-icon-button",
              containerStyle: containerStyle
            });
}

var Bag = {
  make: Ui__IconButton$Bag
};

function Ui__IconButton$User(Props) {
  var disabled = Props.disabled;
  var active = Props.active;
  var onPress = Props.onPress;
  var size = Props.size;
  var containerStyle = Props.containerStyle;
  var color = Props.color;
  var tmp = {
    name: "user-fill",
    testID: "active-user-icon"
  };
  if (size !== undefined) {
    tmp.size = size;
  }
  if (color !== undefined) {
    tmp.color = color;
  }
  var tmp$1 = {
    name: "user-outline",
    testID: "inactive-user-icon"
  };
  if (size !== undefined) {
    tmp$1.size = size;
  }
  if (color !== undefined) {
    tmp$1.color = color;
  }
  return Ui__IconButton({
              disabled: disabled,
              active: active,
              onPress: onPress,
              activeIcon: React.createElement(Ui__MarkotIcon.make, tmp),
              inactiveIcon: React.createElement(Ui__MarkotIcon.make, tmp$1),
              size: size,
              testID: "user-icon-button",
              containerStyle: containerStyle
            });
}

var User = {
  make: Ui__IconButton$User
};

function Ui__IconButton$Bookmark(Props) {
  var disabled = Props.disabled;
  var active = Props.active;
  var onPress = Props.onPress;
  var size = Props.size;
  var containerStyle = Props.containerStyle;
  var testIDOpt = Props.testID;
  var color = Props.color;
  var testID = testIDOpt !== undefined ? testIDOpt : "bookmark-icon-button";
  var tmp = {
    name: "bookmark-fill",
    testID: "active-bookmark-icon"
  };
  if (size !== undefined) {
    tmp.size = size;
  }
  if (color !== undefined) {
    tmp.color = color;
  }
  var tmp$1 = {
    name: "bookmark-outline",
    testID: "inactive-bookmark-icon"
  };
  if (size !== undefined) {
    tmp$1.size = size;
  }
  if (color !== undefined) {
    tmp$1.color = color;
  }
  return Ui__IconButton({
              disabled: disabled,
              active: active,
              onPress: onPress,
              activeIcon: React.createElement(Ui__MarkotIcon.make, tmp),
              inactiveIcon: React.createElement(Ui__MarkotIcon.make, tmp$1),
              size: size,
              testID: testID,
              containerStyle: containerStyle
            });
}

var Bookmark = {
  make: Ui__IconButton$Bookmark
};

function Ui__IconButton$Share(Props) {
  var disabled = Props.disabled;
  var onPress = Props.onPress;
  var size = Props.size;
  var containerStyle = Props.containerStyle;
  var tmp = {
    name: "share",
    testID: "active-share-icon"
  };
  if (size !== undefined) {
    tmp.size = size;
  }
  var tmp$1 = {
    name: "share",
    testID: "inactive-share-icon"
  };
  if (size !== undefined) {
    tmp$1.size = size;
  }
  return Ui__IconButton({
              disabled: disabled,
              active: true,
              onPress: onPress,
              activeIcon: React.createElement(Ui__MarkotIcon.make, tmp),
              inactiveIcon: React.createElement(Ui__MarkotIcon.make, tmp$1),
              size: size,
              testID: "share-icon-button",
              containerStyle: containerStyle
            });
}

var Share = {
  make: Ui__IconButton$Share
};

function Ui__IconButton$Eye(Props) {
  var disabled = Props.disabled;
  var active = Props.active;
  var onPress = Props.onPress;
  var size = Props.size;
  var containerStyle = Props.containerStyle;
  var testIDOpt = Props.testID;
  var testID = testIDOpt !== undefined ? testIDOpt : "eye-icon-button";
  var tmp = {
    name: "eye-slashed-outline",
    testID: "eye-slashed-icon"
  };
  if (size !== undefined) {
    tmp.size = size;
  }
  var tmp$1 = {
    name: "eye-outline",
    testID: "eye-icon"
  };
  if (size !== undefined) {
    tmp$1.size = size;
  }
  return Ui__IconButton({
              disabled: disabled,
              active: active,
              onPress: onPress,
              activeIcon: React.createElement(Ui__MarkotIcon.make, tmp),
              inactiveIcon: React.createElement(Ui__MarkotIcon.make, tmp$1),
              size: size,
              testID: testID,
              containerStyle: containerStyle
            });
}

var Eye = {
  make: Ui__IconButton$Eye
};

var make = Ui__IconButton;

export {
  make ,
  Bell ,
  Bag ,
  User ,
  Bookmark ,
  Share ,
  Eye ,
}
/* react Not a pure module */
