// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";

var Types = {};

function unwrap_fragment(u) {
  var v = u.__typename;
  switch (v) {
    case "PrelovedItemSearchResult" :
        return {
                NAME: "PrelovedItemSearchResult",
                VAL: u
              };
    case "ProductVariantSearchResult" :
        return {
                NAME: "ProductVariantSearchResult",
                VAL: u
              };
    default:
      return {
              NAME: "UnselectedUnionMember",
              VAL: v
            };
  }
}

function wrap_fragment(v) {
  if (v.NAME === "UnselectedUnionMember") {
    return {
            __typename: v.VAL
          };
  } else {
    return v.VAL;
  }
}

var fragmentConverter = {"__root":{"ProductVariantSearchResult":{"f":""},"PrelovedItemSearchResult":{"f":""},"":{"u":"fragment"}}};

var fragmentConverterMap = {
  fragment: unwrap_fragment
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "AppSearchResultCard_searchResult",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "__typename",
      "storageKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AppSearchProductVariantResultCard_productVariantSearchResult"
        }
      ],
      "type": "ProductVariantSearchResult",
      "abstractKey": null
    },
    {
      "kind": "InlineFragment",
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "AppSearchPrelovedItemResultCard_prelovedItemSearchResult"
        }
      ],
      "type": "PrelovedItemSearchResult",
      "abstractKey": null
    }
  ],
  "type": "SearchResult",
  "abstractKey": "__isSearchResult"
};

export {
  Types ,
  unwrap_fragment ,
  wrap_fragment ,
  Internal ,
  Utils ,
  node ,
}
/* RescriptRelay Not a pure module */
