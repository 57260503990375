// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Theme from "../../theme/components/Ui__Theme.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as Ui__BaseText from "../../text/components/Ui__BaseText.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotIcon from "../../icons/components/Ui__MarkotIcon.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as RnUtils$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/utils/RnUtils.bs.js";
import * as GroupFactory$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/group/components/GroupFactory.bs.js";

function Ui__SearchBox$ClearButton(Props) {
  var onClear = Props.onClear;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  return React.createElement(ReactNative.TouchableOpacity, {
              hitSlop: RnUtils$DecidrIoUniversalComponents.makeSymmetricHitSlop(theme.spacing._8),
              onPress: (function (param) {
                  Curry._1(onClear, undefined);
                }),
              children: React.createElement(Ui__MarkotIcon.make, {
                    name: "x-mark",
                    size: 10.5,
                    color: theme.colors.primaryShade1,
                    testID: "clear-icon"
                  })
            });
}

var ClearButton = {
  make: Ui__SearchBox$ClearButton
};

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center",
          flexDirection: "row",
          justifyContent: "center"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.background,
                    paddingHorizontal: theme.spacing._6,
                    paddingVertical: theme.spacing._4
                  };
          })
      },
      inputStyle: {
        TAG: /* Dual */2,
        _0: {
          flex: 1
        },
        _1: (function (theme) {
            var font = Ui__BaseText.typographyVariantToFont("b2", theme);
            return {
                    color: theme.colors.primary,
                    fontSize: Curry._1(Ui__Theme.Typography.Font.getSize, font),
                    lineHeight: Curry._1(Ui__Theme.Typography.Font.getLineHeight, font)
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

var make = React.forwardRef(function (Props, ref) {
      var onChange = Props.onChange;
      var onClear = Props.onClear;
      var value = Props.value;
      var containerStyle = Props.containerStyle;
      var testIDOpt = Props.testID;
      var right = Props.right;
      var left = Props.left;
      var disabledOpt = Props.disabled;
      var onBlur = Props.onBlur;
      var onFocus = Props.onFocus;
      var onSubmitEditing = Props.onSubmitEditing;
      var autoFocusOpt = Props.autoFocus;
      var testID = testIDOpt !== undefined ? testIDOpt : "search-box";
      var disabled = disabledOpt !== undefined ? disabledOpt : false;
      var autoFocus = autoFocusOpt !== undefined ? autoFocusOpt : false;
      var match = Curry._1(Ui__Styling.useTheme, undefined);
      var theme = match.theme;
      var styled = Curry._1(stylesheet, theme);
      var os = ReactNative.Platform.OS;
      var tmp = {
        autoFocus: autoFocus,
        editable: !disabled,
        onChangeText: onChange,
        placeholder: "Search",
        placeholderTextColor: theme.colors.primaryShade2,
        style: [
          Caml_option.some(Curry._1(styled, styles.inputStyle)),
          os === "web" ? ({
                outline: "none"
              }) : undefined
        ],
        testID: "text-input"
      };
      var tmp$1 = Belt_Option.map((ref == null) ? undefined : Caml_option.some(ref), (function (prim) {
              return prim;
            }));
      if (tmp$1 !== undefined) {
        tmp.ref = Caml_option.valFromOption(tmp$1);
      }
      if (onBlur !== undefined) {
        tmp.onBlur = Caml_option.valFromOption(onBlur);
      }
      if (onFocus !== undefined) {
        tmp.onFocus = Caml_option.valFromOption(onFocus);
      }
      if (onSubmitEditing !== undefined) {
        tmp.onSubmitEditing = Caml_option.valFromOption(onSubmitEditing);
      }
      if (value !== undefined) {
        tmp.value = value;
      }
      return React.createElement(ReactNative.View, {
                  style: [
                    Caml_option.some(Curry._1(styled, styles.container)),
                    containerStyle
                  ],
                  testID: testID,
                  children: null
                }, Belt_Option.getWithDefault(left, SimpleReact$DecidrIoUtils.whenTrue(Belt_Option.getWithDefault(value, "") === "", React.createElement(React.Fragment, undefined, React.createElement(Ui__MarkotIcon.make, {
                                  name: "magnifying-glass-outline",
                                  size: 12,
                                  color: theme.colors.onPrimaryShade4,
                                  testID: "search-icon"
                                }), React.createElement(GroupFactory$DecidrIoUniversalComponents.Spacer.make, {
                                  size: theme.spacing._6,
                                  orientation: "horizontal"
                                })))), React.createElement(ReactNative.TextInput, tmp), Belt_Option.getWithDefault(right, SimpleReact$DecidrIoUtils.whenTrue(Belt_Option.getWithDefault(value, "") !== "", React.createElement(React.Fragment, undefined, React.createElement(GroupFactory$DecidrIoUniversalComponents.Spacer.make, {
                                  size: theme.spacing._6,
                                  orientation: "horizontal"
                                }), React.createElement(Ui__SearchBox$ClearButton, {
                                  onClear: onClear
                                })))));
    });

export {
  ClearButton ,
  styles ,
  stylesheet ,
  make ,
}
/* match Not a pure module */
