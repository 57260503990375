// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Group from "../../group/components/Ui__Group.bs.js";
import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as Reanimated from "rescript-reanimated/src/Reanimated.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "../../routing/Ui__AppLink.bs.js";
import * as Ui__Styling from "../../styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__MarkotIcon from "../../icons/components/Ui__MarkotIcon.bs.js";
import * as Ui__MarkotText from "../../text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../responsive/Ui__Responsive.bs.js";
import * as Style$ReactNative from "rescript-react-native/src/apis/Style.bs.js";
import * as Bool$DecidrIoUtils from "@decidr-io/utils/src/bool/Bool.bs.js";
import * as ReactNativeReanimated from "react-native-reanimated";
import * as Box$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/box/components/Box.bs.js";
import * as Columns$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/columns/components/Columns.bs.js";
import * as RnUtils$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/utils/RnUtils.bs.js";

var match = Ui__Styling.describe({
      container: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center"
        },
        _1: (function (theme) {
            return {
                    paddingBottom: theme.spacing._1,
                    paddingTop: theme.spacing._6
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function Ui__Tabs$MarkotTab(Props) {
  var name = Props.name;
  var markotIconName = Props.markotIconName;
  var onPress = Props.onPress;
  var isActive = Props.isActive;
  var activeColor = Props.activeColor;
  var inactiveColor = Props.inactiveColor;
  var containerStyle = Props.containerStyle;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet, theme);
  var defaultActiveColor = theme.colors.secondary;
  var defaultInactiveColor = theme.colors.onPrimary;
  var tmp = {
    spacing: theme.spacing._2,
    children: null,
    containerStyle: [
      Caml_option.some(Curry._1(styled, styles.container)),
      containerStyle
    ],
    testID: name + "-tab"
  };
  var tmp$1 = Belt_Option.map(onPress, (function (onPress) {
          return {
                  NAME: "Pressable",
                  VAL: Box$DecidrIoUniversalComponents.makePressableProps(undefined, undefined, undefined, (function (param) {
                          Curry._1(onPress, undefined);
                        }), undefined, undefined, undefined)
                };
        }));
  if (tmp$1 !== undefined) {
    tmp.kind = Caml_option.valFromOption(tmp$1);
  }
  return React.createElement(Ui__Group.make, tmp, isActive ? React.createElement(Ui__MarkotIcon.make, {
                    name: "" + markotIconName + "-fill",
                    color: Belt_Option.getWithDefault(activeColor, defaultActiveColor),
                    testID: "tab-active-icon"
                  }) : React.createElement(Ui__MarkotIcon.make, {
                    name: "" + markotIconName + "-outline",
                    color: Belt_Option.getWithDefault(inactiveColor, defaultInactiveColor),
                    testID: "tab-inactive-icon"
                  }), React.createElement(Ui__MarkotText.make, {
                  size: "b2",
                  children: name,
                  style: [{
                      color: isActive ? Belt_Option.getWithDefault(activeColor, defaultActiveColor) : Belt_Option.getWithDefault(inactiveColor, defaultInactiveColor)
                    }]
                }));
}

var match$1 = Ui__Styling.describe({
      container: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.primary,
                    paddingBottom: theme.spacing._12
                  };
          })
      }
    });

var stylesheet$1 = match$1[1];

var styles$1 = match$1[0];

function Ui__Tabs$BottomTabBar(Props) {
  var routes = Props.routes;
  var activeRouteIndex = Props.activeRouteIndex;
  var containerStyle = Props.containerStyle;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$1, theme);
  return React.createElement(Columns$DecidrIoUniversalComponents.make, {
              children: Belt_Array.mapWithIndex(routes, (function (index, param) {
                      var behavior = param.behavior;
                      var markotIconName = param.markotIconName;
                      var name = param.name;
                      var isActive = activeRouteIndex === index;
                      if (behavior.NAME === "Link") {
                        var match = behavior.VAL;
                        return React.createElement(Ui__AppLink.make, {
                                    configuration: Curry._3(Ui__AppLink.makeConfiguration, match.route, undefined, undefined),
                                    children: React.createElement(Ui__Tabs$MarkotTab, {
                                          name: name,
                                          markotIconName: markotIconName,
                                          isActive: isActive
                                        }),
                                    a11yLabel: match.a11yLabel,
                                    key: name + String(index)
                                  });
                      }
                      var onPress = behavior.VAL;
                      return React.createElement(Ui__Tabs$MarkotTab, {
                                  name: name,
                                  markotIconName: markotIconName,
                                  onPress: (function (param) {
                                      Curry._1(onPress, isActive);
                                    }),
                                  isActive: isActive,
                                  key: name + String(index)
                                });
                    })),
              spacing: theme.spacing._2,
              containerStyle: [
                Caml_option.some(Curry._1(styled, styles$1.container)),
                containerStyle
              ]
            });
}

var match$2 = Ui__Styling.describe({
      title: {
        TAG: /* Static */0,
        _0: {
          textTransform: "uppercase"
        }
      },
      activeTitle: {
        TAG: /* Static */0,
        _0: {
          fontWeight: "bold"
        }
      }
    });

var stylesheet$2 = match$2[1];

var styles$2 = match$2[0];

function Ui__Tabs$Tab(Props) {
  var title = Props.title;
  var isActive = Props.isActive;
  var sizeOpt = Props.size;
  var size = sizeOpt !== undefined ? sizeOpt : "small";
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$2, theme);
  return React.createElement(Ui__MarkotText.make, {
              size: size === "small" ? "button2" : "button1",
              color: isActive ? theme.colors.primary : theme.colors.info,
              children: title,
              style: [
                Caml_option.some(Curry._1(styled, styles$2.title)),
                Bool$DecidrIoUtils.thenSome(isActive, Curry._1(styled, styles$2.activeTitle))
              ]
            });
}

var match$3 = Ui__Styling.describe({
      tabContainer: {
        TAG: /* Dual */2,
        _0: {
          alignItems: "center"
        },
        _1: (function (theme) {
            return {
                    paddingTop: theme.spacing._1
                  };
          })
      },
      absoluteFillObject: {
        TAG: /* Static */0,
        _0: ReactNative.StyleSheet.absoluteFillObject
      },
      indicator: {
        TAG: /* Dual */2,
        _0: {
          position: "relative"
        },
        _1: (function (theme) {
            return {
                    backgroundColor: theme.colors.primary,
                    height: theme.lineThickness.s
                  };
          })
      }
    });

var stylesheet$3 = match$3[1];

var styles$3 = match$3[0];

function Ui__Tabs(Props) {
  var tabs = Props.tabs;
  var givenActiveTabIndex = Props.activeTabIndex;
  var renderItem = Props.renderItem;
  var onTabPress = Props.onTabPress;
  var tabStyle = Props.tabStyle;
  var style = Props.style;
  var wrapperStyle = Props.wrapperStyle;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var styled = Curry._1(stylesheet$3, theme);
  var tabBottomPadding = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        theme.spacing._6,
        undefined,
        undefined,
        theme.spacing._8,
        undefined,
        undefined,
        theme.spacing._6,
        undefined
      ]);
  var numberOfTabs = tabs.length;
  var activeTabIndex = givenActiveTabIndex < 0 || givenActiveTabIndex > (numberOfTabs - 1 | 0) ? 0 : givenActiveTabIndex;
  var activeTabIndexShared = ReactNativeReanimated.useSharedValue(activeTabIndex);
  var indicatorStyle = ReactNativeReanimated.useAnimatedStyle(function () {
        return {
                left: "" + (activeTabIndexShared.value * (100 / numberOfTabs)).toString() + "%"
              };
      });
  var renderedItems = Belt_Array.mapWithIndex(tabs, (function (index, tab) {
          var isActive = activeTabIndex === index;
          return React.createElement(ReactNative.Pressable, {
                      children: (function (_interactionState) {
                          return Curry._2(renderItem, tab, isActive);
                        }),
                      hitSlop: RnUtils$DecidrIoUniversalComponents.makeSymmetricHitSlop(theme.spacing._8),
                      onPress: (function (param) {
                          Curry._1(onTabPress, index);
                          activeTabIndexShared.value = Reanimated.withTiming(index, Reanimated.Timing.makeConfig(200, undefined, undefined), undefined, undefined);
                        }),
                      style: (function (_interactionState) {
                          return [
                                  {
                                    paddingBottom: tabBottomPadding
                                  },
                                  Caml_option.some(Curry._1(styled, styles$3.tabContainer)),
                                  tabStyle
                                ];
                        }),
                      key: String(index)
                    });
        }));
  var tmp = {
    children: null
  };
  if (wrapperStyle !== undefined) {
    tmp.style = Caml_option.valFromOption(wrapperStyle);
  }
  var tmp$1 = {
    children: renderedItems,
    spacing: 0
  };
  if (style !== undefined) {
    tmp$1.containerStyle = Caml_option.valFromOption(style);
  }
  return React.createElement(ReactNative.View, tmp, React.createElement(Columns$DecidrIoUniversalComponents.make, tmp$1), React.createElement(Reanimated.View.make, {
                  style: [
                    Curry._1(styled, styles$3.absoluteFillObject),
                    Curry._1(styled, styles$3.indicator),
                    {
                      width: Style$ReactNative.pct(100 / numberOfTabs)
                    },
                    indicatorStyle
                  ]
                }));
}

var MarkotTab = {
  make: Ui__Tabs$MarkotTab
};

var BottomTabBar = {
  make: Ui__Tabs$BottomTabBar
};

var Tab = {
  make: Ui__Tabs$Tab
};

var make = Ui__Tabs;

export {
  MarkotTab ,
  BottomTabBar ,
  Tab ,
  make ,
}
/* match Not a pure module */
