// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Styling from "../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactNative from "react-native";
import * as Ui__LoadingIndicator from "../../../ui/src/loading/components/Ui__LoadingIndicator.bs.js";
import * as App__Category__CategoriesDisplay from "../category/categoriesDisplay/App__Category__CategoriesDisplay.bs.js";

var match = Ui__Styling.describe({
      scrollView: {
        TAG: /* Static */0,
        _0: {
          flexGrow: 0,
          maxHeight: "80vh"
        }
      },
      container: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.secondaryVariant3,
                    padding: theme.spacing._20
                  };
          })
      },
      content: {
        TAG: /* Static */0,
        _0: {
          alignSelf: "center"
        }
      }
    });

var stylesheet = match[1];

var styles = match[0];

function App__BrowseTabDropdownContent(Props) {
  var onSubcategoryPress = Props.onSubcategoryPress;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var styled = Curry._1(stylesheet, match.theme);
  return React.createElement(ReactNative.ScrollView, {
              contentContainerStyle: Curry._1(styled, styles.container),
              style: Curry._1(styled, styles.scrollView),
              testID: "browse-tab-dropdown-container",
              children: React.createElement(ReactNative.View, {
                    style: Curry._1(styled, styles.content),
                    children: React.createElement(React.Suspense, {
                          children: React.createElement(App__Category__CategoriesDisplay.make, {
                                onSubcategoryPress: onSubcategoryPress
                              }),
                          fallback: React.createElement(Ui__LoadingIndicator.make, {})
                        })
                  })
            });
}

var make = App__BrowseTabDropdownContent;

export {
  make ,
}
/* match Not a pure module */
