/* TypeScript file generated from Web__PageShell.resi by genType. */
/* eslint-disable import/first */


import * as React from 'react';

// @ts-ignore: Implicit any on import
import * as Web__PageShellBS__Es6Import from './Web__PageShell.bs';
const Web__PageShellBS: any = Web__PageShellBS__Es6Import;

import type {Style_t as ReactNative_Style_t} from '../../../../../rescript-shims/ReactNative.shim';

// tslint:disable-next-line:interface-over-type-literal
export type Props = {
  readonly style?: ReactNative_Style_t; 
  readonly isDiscoverPage?: boolean; 
  readonly showPromoBanner?: 
    "undetermined"
  | "on"
  | "off"; 
  readonly activeRouteIndex?: number; 
  readonly children: React.ReactNode
};

export const make: React.ComponentType<{
  readonly style?: ReactNative_Style_t; 
  readonly isDiscoverPage?: boolean; 
  readonly showPromoBanner?: 
    "undetermined"
  | "on"
  | "off"; 
  readonly activeRouteIndex?: number; 
  readonly children: React.ReactNode
}> = Web__PageShellBS.make;
