// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Index from "./index";

var make = Index.Checkbox;

export {
  make ,
}
/* make Not a pure module */
