// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as App__Report from "../utils/App__Report.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactRelay from "react-relay";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as Ui__Toast__Error from "../../../ui/src/toast/components/error/Ui__Toast__Error.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as Ui__MarkotErrorBoundary from "../../../ui/src/error/markotErrorBoundary/Ui__MarkotErrorBoundary.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as App__Search__OverlayResults from "./components/overlayResults/App__Search__OverlayResults.bs.js";
import * as AppGlobalSearchQuery_graphql from "../../../relay-codegen/generated/AppGlobalSearchQuery_graphql.bs.js";

function use(variables, fetchPolicy, fetchKey, networkCacheConfig, param) {
  var data = ReactRelay.useLazyLoadQuery(AppGlobalSearchQuery_graphql.node, RescriptRelay_Internal.internal_cleanObjectFromUndefinedRaw(AppGlobalSearchQuery_graphql.Internal.convertVariables(variables)), {
        fetchKey: fetchKey,
        fetchPolicy: RescriptRelay.mapFetchPolicy(fetchPolicy),
        networkCacheConfig: networkCacheConfig
      });
  return RescriptRelay_Internal.internal_useConvertedValue(AppGlobalSearchQuery_graphql.Internal.convertResponse, data);
}

function App__GlobalSearch(Props) {
  var headerHeight = Props.headerHeight;
  var onSearchBoxFocus = Props.onSearchBoxFocus;
  var searchBoxStyle = Props.searchBoxStyle;
  var onSearchBoxBlurOrOverlayClose = Props.onSearchBoxBlurOrOverlayClose;
  var onSearchSubmit = Props.onSearchSubmit;
  var onProductVariantPress = Props.onProductVariantPress;
  var disableScrollYStyleElement = Props.disableScrollYStyleElement;
  var result = use({
        includeQuery: false,
        searchTerm: ""
      }, undefined, undefined, undefined, undefined);
  var match = React.useState(function () {
        return false;
      });
  var setSearching = match[1];
  var searching = match[0];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var match$2 = React.useState(function () {
        return Date.now();
      });
  var setLatestSearchAt = match$2[1];
  var latestSearchAt = match$2[0];
  var environment = RescriptRelay.useEnvironmentFromContext(undefined);
  var search = React.useCallback((function (param) {
          if (searching) {
            return ;
          }
          var timestamp = param.timestamp;
          var onSuccess = param.onSuccess;
          var searchTerm = param.searchTerm;
          Curry._1(setSearching, (function (param) {
                  return true;
                }));
          Curry._1(setLatestSearchAt, (function (param) {
                  return timestamp;
                }));
          var variables = {
            includeQuery: searchTerm !== "",
            searchTerm: searchTerm
          };
          var onResult = function (res) {
            if (res.TAG === /* Ok */0) {
              if (timestamp > latestSearchAt) {
                Curry._1(setLatestSearchAt, (function (prev) {
                        if (prev === timestamp) {
                          Curry._1(onSuccess, undefined);
                        }
                        return prev;
                      }));
              }
              
            } else {
              App__Report.unexpected(Belt_Option.getWithDefault(res._0.message, "Unknown error occurred while searching!"), "App__GlobalSearch search");
              Curry._1(setError, (function (param) {
                      return "Error searching!";
                    }));
            }
            Curry._1(setSearching, (function (param) {
                    return false;
                  }));
          };
          var networkCacheConfig;
          var fetchPolicy = /* StoreOrNetwork */1;
          ReactRelay.fetchQuery(environment, AppGlobalSearchQuery_graphql.node, AppGlobalSearchQuery_graphql.Internal.convertVariables(variables), {
                  networkCacheConfig: networkCacheConfig,
                  fetchPolicy: RescriptRelay.mapFetchQueryFetchPolicy(fetchPolicy)
                }).subscribe({
                next: (function (res) {
                    Curry._1(onResult, {
                          TAG: /* Ok */0,
                          _0: AppGlobalSearchQuery_graphql.Internal.convertResponse(res)
                        });
                  }),
                error: (function (err) {
                    Curry._1(onResult, {
                          TAG: /* Error */1,
                          _0: err
                        });
                  })
              });
        }), []);
  var tmp = {
    queryRef: result.fragmentRefs,
    search: search,
    searching: searching,
    onSearchBoxFocus: onSearchBoxFocus,
    onSearchBoxBlurOrOverlayClose: onSearchBoxBlurOrOverlayClose,
    onProductVariantPress: onProductVariantPress,
    disableScrollYStyleElement: disableScrollYStyleElement,
    onSearching: (function (isSearching) {
        Curry._1(setSearching, (function (param) {
                return isSearching;
              }));
      })
  };
  if (headerHeight !== undefined) {
    tmp.headerHeight = headerHeight;
  }
  if (searchBoxStyle !== undefined) {
    tmp.searchBoxStyle = Caml_option.valFromOption(searchBoxStyle);
  }
  if (onSearchSubmit !== undefined) {
    tmp.onSearchSubmit = Caml_option.valFromOption(onSearchSubmit);
  }
  return React.createElement(Ui__MarkotErrorBoundary.make, {
              debugContext: "App__GlobalSearch App__OverlayResults",
              children: null,
              FallbackComponent: (function (param) {
                  App__Report.unexpected(param.errorMessage, "App__GlobalSearch");
                  return null;
                })
            }, React.createElement(App__Search__OverlayResults.make, tmp), SimpleReact$DecidrIoUtils.renderIfSome(match$1[0], (function (errorMessage) {
                    return React.createElement(Ui__Toast__Error.make, {
                                title: "An error has occurred!",
                                description: errorMessage,
                                onDismiss: (function (param) {
                                    Curry._1(setError, (function (param) {
                                            
                                          }));
                                  })
                              });
                  })));
}

var make = App__GlobalSearch;

export {
  make ,
}
/* react Not a pure module */
