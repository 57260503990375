// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Curry from "rescript/lib/es6/curry.js";
import * as React from "react";
import * as Ui__Card from "../../../../../ui/src/card/Ui__Card.bs.js";
import * as App__Money from "../../../money/components/App__Money.bs.js";
import * as App__Report from "../../../utils/App__Report.bs.js";
import * as Belt_Option from "rescript/lib/es6/belt_Option.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Ui__AppLink from "../../../../../ui/src/routing/Ui__AppLink.bs.js";
import * as Ui__Styling from "../../../../../ui/src/styling/Ui__Styling.bs.js";
import * as ReactRelay from "react-relay";
import * as ReactNative from "react-native";
import * as Ui__MarkotText from "../../../../../ui/src/text/components/Ui__MarkotText.bs.js";
import * as Ui__Responsive from "../../../../../ui/src/responsive/Ui__Responsive.bs.js";
import * as Ui__LabeledText from "../../../../../ui/src/labeledText/components/Ui__LabeledText.bs.js";
import * as Ui__ImageFallback from "../../../../../ui/src/image/components/imageFallback/Ui__ImageFallback.bs.js";
import * as Ui__ProductCard__Base from "../../../../../ui/src/productCard/Ui__ProductCard__Base.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as SimpleReact$DecidrIoUtils from "@decidr-io/utils/src/react/SimpleReact.bs.js";
import * as App__Product__CommonStyling from "../../../product/components/commons/App__Product__CommonStyling.bs.js";
import * as App__PrelovedItemInfo__PriceTag from "../../../prelovedItemInfo/components/priceTag/App__PrelovedItemInfo__PriceTag.bs.js";
import * as FeatureFlags__LocalFeatureFlags from "../../../../../feature-flags/src/local/FeatureFlags__LocalFeatureFlags.bs.js";
import * as App__PrelovedItemSearchResult__Card from "./App__PrelovedItemSearchResult__Card.bs.js";
import * as GroupFactory$DecidrIoUniversalComponents from "@decidr-io/universal-components/src/layout/group/components/GroupFactory.bs.js";
import * as AppSearchPrelovedItemResultCard_prelovedItemSearchResult_graphql from "../../../../../relay-codegen/generated/AppSearchPrelovedItemResultCard_prelovedItemSearchResult_graphql.bs.js";

function use(fRef) {
  var data = ReactRelay.useFragment(AppSearchPrelovedItemResultCard_prelovedItemSearchResult_graphql.node, fRef);
  return RescriptRelay_Internal.internal_useConvertedValue(AppSearchPrelovedItemResultCard_prelovedItemSearchResult_graphql.Internal.convertFragment, data);
}

var match = Ui__Styling.describe({
      linkWrapper: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      card: {
        TAG: /* Static */0,
        _0: {
          flex: 1
        }
      },
      image: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.surface
                  };
          })
      },
      bottomSection: {
        TAG: /* Static */0,
        _0: {
          flex: 1,
          justifyContent: "space-between"
        }
      },
      soldForLabel: {
        TAG: /* Dynamic */1,
        _0: (function (theme) {
            return {
                    backgroundColor: theme.colors.errorShade3
                  };
          })
      }
    });

var stylesheet = match[1];

var styles = match[0];

function App__Search__PrelovedItemResultCard(Props) {
  var prelovedItemSearchResult = Props.prelovedItemSearchResult;
  var imageSizes = Props.imageSizes;
  var style = Props.style;
  var imageResizeModeOpt = Props.imageResizeMode;
  var withStatusIndicatorOpt = Props.withStatusIndicator;
  var withProductNameOpt = Props.withProductName;
  var testID = Props.testID;
  var imageResizeMode = imageResizeModeOpt !== undefined ? imageResizeModeOpt : "contain";
  var withStatusIndicator = withStatusIndicatorOpt !== undefined ? withStatusIndicatorOpt : true;
  var withProductName = withProductNameOpt !== undefined ? withProductNameOpt : false;
  var match = Curry._1(Ui__Styling.useTheme, undefined);
  var theme = match.theme;
  var match$1 = App__Product__CommonStyling.useProductCommonStyling(undefined);
  var cardTextSectionSpacing = match$1.cardTextSectionSpacing;
  var cardPadding = match$1.cardPadding;
  var styled = Curry._1(stylesheet, theme);
  var cardImageHeight = Curry.app(Ui__Responsive.useMedia(undefined)(undefined).mediaSelect, [
        undefined,
        86,
        100,
        136,
        undefined,
        172,
        undefined,
        72,
        undefined
      ]);
  var match$2 = use(prelovedItemSearchResult);
  var status = match$2.status;
  var productName = match$2.productName;
  var prelovedItemInfoId = match$2.prelovedItemInfoId;
  var minimumBid = match$2.minimumBid;
  var mainImage = match$2.mainImage;
  var highestBid = match$2.highestBid;
  var condition = match$2.condition;
  var match$3 = Curry._1(Ui__AppLink.useRouting, undefined);
  var routeConfiguration = Curry._3(Ui__AppLink.makeConfiguration, {
        TAG: /* PrelovedItem */4,
        slug: match$2.productSlug,
        prelovedId: prelovedItemInfoId
      }, undefined, undefined);
  var a11yLabel = "See more information about preloved " + productName + "";
  var onPress = Belt_Option.map(match$3.nativeNavigationHandler, (function (navHandler, param) {
          Curry._1(navHandler, routeConfiguration);
        }));
  var testID$1 = Belt_Option.getWithDefault(testID, "preloved-item-search-result-card-id-" + prelovedItemInfoId + "");
  var newPrelovedCards = FeatureFlags__LocalFeatureFlags.use("newPrelovedCards");
  if (newPrelovedCards === "off") {
    var tmp = {
      children: null,
      kind: "sharp",
      spacing: theme.spacing._5,
      paddingHorizontal: cardPadding,
      paddingVertical: cardPadding,
      backgroundColor: theme.colors.surface,
      style: [
        Caml_option.some(Curry._1(styled, styles.card)),
        style
      ],
      testID: testID$1,
      testMode: match$2.usedForTesting
    };
    if (onPress !== undefined) {
      tmp.onPress = Caml_option.valFromOption(onPress);
    }
    var tmp$1;
    if (mainImage !== undefined) {
      var size = mainImage.size;
      tmp$1 = React.createElement(Ui__Card.$$Image.make, {
            image: {
              src: mainImage.url,
              width: size.width,
              height: size.height
            },
            size: {
              NAME: "fixedHeightAutoWidth",
              VAL: {
                height: cardImageHeight,
                sizes: imageSizes
              }
            },
            resizeMode: imageResizeMode,
            alt: Belt_Option.getWithDefault(mainImage.alt, productName),
            style: Curry._1(styled, styles.image),
            testID: "preloved-item-search-result-card-image"
          });
    } else {
      tmp$1 = React.createElement(Ui__ImageFallback.make, {
            height: cardImageHeight
          });
    }
    var tmp$2;
    var exit = 0;
    if (status === "Live" || status !== "Sold") {
      exit = 1;
    } else {
      tmp$2 = SimpleReact$DecidrIoUtils.renderIfSome(match$2.purchaseAmount, (function (purchaseAmount) {
              return React.createElement(App__PrelovedItemInfo__PriceTag.UiOnly.make, {
                          children: React.createElement(App__Money.make, {
                                money: purchaseAmount.fragmentRefs
                              }),
                          style: Curry._1(styled, styles.soldForLabel),
                          label: "Sold for"
                        });
            }));
    }
    if (exit === 1) {
      tmp$2 = React.createElement(Ui__Card.Section.make, {
            children: null,
            spacing: cardTextSectionSpacing
          }, highestBid !== undefined ? React.createElement(Ui__LabeledText.make, {
                  label: "Highest bid",
                  text: {
                    NAME: "Element",
                    VAL: React.createElement(Ui__MarkotText.make, {
                          size: "price3",
                          children: React.createElement(App__Money.make, {
                                money: highestBid.fragmentRefs
                              }),
                          testID: "highest-bid-amount"
                        })
                  }
                }) : (
              minimumBid !== undefined ? React.createElement(Ui__LabeledText.make, {
                      label: "Bid from",
                      text: {
                        NAME: "Element",
                        VAL: React.createElement(Ui__MarkotText.make, {
                              size: "price3",
                              children: React.createElement(App__Money.make, {
                                    money: minimumBid.fragmentRefs
                                  }),
                              testID: "bid-from-amount"
                            })
                      }
                    }) : null
            ), SimpleReact$DecidrIoUtils.renderIfSome(match$2.buyNowPrice, (function (buyNowPrice) {
                  return React.createElement(App__PrelovedItemInfo__PriceTag.UiOnly.make, {
                              children: React.createElement(App__Money.make, {
                                    money: buyNowPrice.fragmentRefs
                                  })
                            });
                })));
    }
    return React.createElement(Ui__AppLink.make, {
                configuration: routeConfiguration,
                children: React.createElement(Ui__Card.make, tmp, React.createElement(Ui__Card.Section.make, {
                          children: null,
                          spacing: match$1.cardImageSectionSpacing
                        }, React.createElement(Ui__Card.Apart.make, {
                              children: null,
                              spacing: theme.spacing._2
                            }, React.createElement(Ui__MarkotText.make, {
                                  size: "caption1",
                                  color: theme.colors.info,
                                  children: condition === "WellLoved" ? "Well loved" : (
                                      condition === "AsGoodAsNew" ? "As good as new" : (
                                          condition === "Good" ? "Good" : (
                                              condition === "NeverUsed" ? "Original condition" : (App__Report.unknownEnumMember(condition), "-")
                                            )
                                        )
                                    ),
                                  testID: "preloved-item-search-result-card-condition"
                                }), SimpleReact$DecidrIoUtils.whenTrue(withStatusIndicator, React.createElement(Ui__MarkotText.make, {
                                      size: "caption1",
                                      color: status === "Live" ? theme.colors.success : (
                                          status === "Sold" ? theme.colors.error : (App__Report.unknownEnumMember(status), theme.colors.success)
                                        ),
                                      children: status === "Live" ? "Live" : (
                                          status === "Sold" ? "Sold" : (App__Report.unknownEnumMember(status), "Live")
                                        ),
                                      testID: "preloved-listing-status"
                                    }))), tmp$1), React.createElement(ReactNative.View, {
                          style: Curry._1(styled, styles.bottomSection),
                          children: null
                        }, SimpleReact$DecidrIoUtils.whenTrue(withProductName, React.createElement(Ui__Card.Description.make, {
                                  text: productName,
                                  size: "subtitle",
                                  fontWeight: "_500",
                                  numberOfLines: 2
                                })), React.createElement(GroupFactory$DecidrIoUniversalComponents.Spacer.make, {
                              size: cardTextSectionSpacing
                            }), React.createElement(Ui__Card.Section.make, {
                              children: null,
                              spacing: cardTextSectionSpacing
                            }, React.createElement(Ui__LabeledText.make, {
                                  label: "Bids",
                                  text: {
                                    NAME: "Text",
                                    VAL: String(match$2.numberOfBids)
                                  }
                                }), tmp$2))),
                a11yLabel: a11yLabel,
                style: Curry._1(styled, styles.linkWrapper)
              });
  }
  var tmp$3 = {
    prelovedItemSearchResultFragment: match$2.fragmentRefs,
    testID: testID$1
  };
  if (onPress !== undefined) {
    tmp$3.onPress = Caml_option.valFromOption(onPress);
  }
  return React.createElement(Ui__ProductCard__Base.LinkWrapper.make, {
              configuration: routeConfiguration,
              a11yLabel: a11yLabel,
              children: React.createElement(App__PrelovedItemSearchResult__Card.make, tmp$3)
            });
}

var make = App__Search__PrelovedItemResultCard;

export {
  make ,
}
/* match Not a pure module */
